import React from "react";
import { dispatchCustomEvent } from "../helpers/Utilities";
const rotationDelay = 1500;
/**
 * Pattern class
 * Renders a visual display of a bingo pattern
 * Properties includes:
 * pattern - Object, has info about a pattern
 * presets - Array, includes all preset pattern objects
 * showTitle - Boolean, indicates if the pattern title should show
 * titlePosition - String, if bottom, shows the title below the pattern, defaults to top
 * editable - Boolean, indicates if the pattern is editable
 */
class Pattern extends React.Component {
  constructor(props) {
    super(props);
    this.rotationInterval = null;
    this.state = {
      pattern: props.pattern === null ? props.presets[0] : props.pattern,
      presets: props.presets,
      patternIndex: 0,
      rotatingPattern: false,
    };
    if (props.rotate === true && Object.prototype.hasOwnProperty.call(props.pattern, "winningPatterns")) {
      this.state.rotatingPattern = props.pattern.winningPatterns[0];
      setTimeout(() => {
        this.setRotatingPattern();
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.pattern) !== JSON.stringify(prevProps.pattern)) {
      if (this.rotationInterval) {
        clearInterval(this.rotationInterval);
      }
      this.setState({
        pattern: this.props.pattern,
        patternIndex: 0,
        rotatingPattern: this.props.rotate === true && Object.prototype.hasOwnProperty.call(this.props.pattern, "winningPatterns") ? this.props.pattern.winningPatterns[0] : false,
      });
      if (this.props.rotate === true && Object.prototype.hasOwnProperty.call(this.props.pattern, "winningPatterns")) {
        setTimeout(() => {
          this.setRotatingPattern();
        }, 100);
      }
    }
  }

  setRotatingPattern() {
    if (this.rotationInterval) {
      clearInterval(this.rotationInterval);
    }
    this.rotationInterval = setInterval(() => {
      const totalPatterns = this.state.pattern.winningPatterns.length;
      const nextIndex = this.state.patternIndex + 1;
      const patternIndex = nextIndex >= totalPatterns ? 0 : nextIndex;
      this.setState({
        patternIndex: patternIndex,
        rotatingPattern: this.state.pattern.winningPatterns[patternIndex],
      });
    }, rotationDelay);
  }

  handleUpdatePattern = (pattern, letter, index, slot) => {
    pattern[letter][index] = !slot;
    let unusedLetters = [];
    Object.keys(pattern).forEach((letter) => {
      // if this letter is not used at all, push it to unused letters
      if (pattern[letter].every((a) => a === false)) {
        unusedLetters.push(letter);
      } else {
        if (letter === "N" && pattern[letter].filter((a) => a === true).length === 1 && pattern[letter].indexOf(true) === 2) {
          unusedLetters.push("N");
        }
      }
    });
    const customPattern = {
      value: "Custom",
      label: "Custom",
      unusedLetters: [...unusedLetters],
      pattern: { ...pattern },
    };
    if (Object.prototype.hasOwnProperty.call(this.props, "index")) {
      customPattern.value = "Alternate";
      customPattern.label = "Alternate";
      customPattern.index = this.props.index;
      customPattern.unusedLetters = [];
    }
    if (this.props.eventName) {
      const customEventName = this.props.eventName;
      dispatchCustomEvent(customEventName, customPattern);
    } else {
      dispatchCustomEvent("custompattern", customPattern);
    }
    this.setState({ pattern: customPattern, unusedLetters: unusedLetters });
  };

  handlePatternSelect = () => {
    if (this.props.selectionHandler) {
      this.props.selectionHandler(this.state.pattern);
    }
  };

  render() {
    // set the pattern to be displayed in the render based on if it rotates or not
    let pattern = Object.prototype.hasOwnProperty.call(this.state.pattern, "pattern") ? this.state.pattern.pattern : this.state.pattern;
    if (this.props.rotate && this.state.rotatingPattern !== false) {
      pattern = this.state.rotatingPattern;
    }

    return (
      <div className={`pattern-block title-${this.props.titlePosition ? this.props.titlePosition : "none"} ${this.props.selected ? "selected" : ""}`} onClick={this.handlePatternSelect}>
        {/* --- Pattern Name --- */}
        {this.props.showTitle === true && this.state.pattern.label !== "Custom" && this.props.titlePosition !== "bottom" ? <div className="pattern-name">{this.state.pattern.label}</div> : null}

        <div className={this.props.editable === true ? "pattern-display editable" : "pattern-display"}>
          {Object.keys(pattern).map((letter, index) => {
            return (
              <div key={letter + index} className="row vertical text-center">
                <div className="col pattern-letter notranslate">
                  <span>{letter}</span>
                </div>
                {Object.keys(pattern[letter]).map((number, index) => {
                  if (this.props.editable === true) {
                    return (
                      <div key={letter + number} className={pattern[letter][number] ? "selected col" : "col"} onClick={(e) => this.handleUpdatePattern(pattern, letter, index, pattern[letter][number])}>
                        {letter === "N" && index === 2 ? <span className="free-space">Free Space</span> : <span>&nbsp;</span>}
                      </div>
                    );
                  } else {
                    return (
                      <div key={letter + number} className={pattern[letter][number] ? "selected col" : "col"}>
                        {letter === "N" && index === 2 ? <span className="free-space">Free Space</span> : <span>&nbsp;</span>}
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
        <div className="pattern-details">
          {/* --- Pattern Name --- */}
          {this.props.showTitle === true && this.state.pattern.label !== "Custom" && this.props.titlePosition === "bottom" ? <div className="pattern-name">{this.state.pattern.label}</div> : null}

          {/* --- Unused Letters --- */}
          {this.props.showUnusedLetters === true && this.state.pattern.unusedLetters.length > 0 ? <div className="unused-letters no-print">Unused Letters: {this.state.pattern.unusedLetters.join(", ")}</div> : null}

          {/* --- Pattern Credit --- */}
          {this.state.pattern.credit && this.props.showCredit ? <div className="pattern-credit no-print">{`Thanks ${this.state.pattern.credit}!`}</div> : null}
        </div>
      </div>
    );
  }
}

export default Pattern;
