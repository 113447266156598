export const ePatterns = [
  /* --------------------- E -------------------- */
  {
    value: "Edges",
    label: "Edges",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Empty Champagne Glass",
    label: "Empty Champagne Glass",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, true],
      N: [false, false, true, true, true],
      G: [false, true, false, false, true],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Equal Sign",
    label: "Equal Sign",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, true, false],
      N: [false, true, false, true, false],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Jordon",
  },
];
