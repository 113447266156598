import React from "react";
import Toggle from "./Toggle";
import Select from "./Select";
import Slider from "./Slider";
import CurrentPattern from "./CurrentPattern";
import MiniUpdates from "./MiniUpdates";
import HelperText from "./HelperText";

// Utilites
import { dispatchCustomEvent, getAudibleCallers, getChimeOptions, getDefaultSettings, getThemeOptions, getLayoutOptions, getRandomNumberInRange } from "../helpers/Utilities";
import { getPatternInfo } from "../helpers/PresetPatterns";

const chimes = getChimeOptions();
const themes = getThemeOptions();
const layouts = getLayoutOptions();

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: [],
      settingsPanelOpen: false,
      settings: { ...this.props.settings },
    };
    this.callers = [];
    this.mp3Callers = getAudibleCallers();
  }

  componentDidMount() {
    document.addEventListener("loadcallers", this.handleCallersLoad);
    document.addEventListener("menu-open", () => {
      this.setState({ settingsPanelOpen: false });
    });
    document.addEventListener("patternupdate", this.handlePatternChange);
    document.addEventListener("settingupdate", this.handleSettingChange);
    document.addEventListener("togglesettingspanel", this.toggleSettingsPanel);
    const dismissedPanels = localStorage.getItem("lpb-dismissed");
    if (dismissedPanels) {
      this.setState({ dismissed: JSON.parse(dismissedPanels) });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll"); // remove no-scroll if active
    document.removeEventListener("loadcallers", this.handleCallersLoad);
    document.removeEventListener("menu-open", () => {
      this.setState({ settingsPanelOpen: false });
    });
    document.removeEventListener("patternupdate", this.handlePatternChange);
    document.removeEventListener("settingupdate", this.handleSettingChange);
    document.removeEventListener("togglesettingspanel", this.toggleSettingsPanel);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState({ settings: this.props.settings });
    }
  }

  handleResetSettings = () => {
    document.body.classList.remove("no-scroll");
    const defaultSettings = getDefaultSettings();
    this.setState({
      settings: { ...defaultSettings },
      settingsPanelOpen: false,
    });
    dispatchCustomEvent("settingssaved", { ...defaultSettings });
  };

  handleCallersLoad = (event) => {
    this.callers = event.detail;
  };

  handleDismiss(event) {
    const block = event.target.dataset.block;
    let dismissedPanels = [...this.state.dismissed];
    dismissedPanels.push(block);
    localStorage.setItem("lpb-dismissed", JSON.stringify(dismissedPanels));
    this.setState({ dismissed: dismissedPanels });
  }

  handlePatternChange = (event) => {
    let settings = { ...this.state.settings };
    if (typeof event.detail === "string") {
      settings.pattern = getPatternInfo(event.detail);
    } else {
      settings.pattern = event.detail;
    }
    this.setState({ settings: settings });
    window.setTimeout(() => {
      this.handleSave(false);
    }, 100);
  };

  handleSettingChange = (setting) => {
    let settings = { ...this.state.settings };
    const property = setting.detail.property;
    const value = setting.detail.value;
    settings[property] = value;

    switch (property) {
      case "popOutControls":
        if (value === true) {
          // if popOutControls get enabled, we must hide controls too
          settings.popOutControls = true;
          settings.hideControls = true;
        }
        break;
      case "wildBingo":
        if (value === false) {
          // if we turn wild bingo off, turn off any sub settings too.
          settings.wildBingoDouble = false;
          settings.wildBingoCustom = false;
          settings.wildBingoEvensAndOdds = false;
          settings.wildBingoEvens = false;
          settings.wildBingoOdds = false;
        } else {
          // ensure manual is set to false
          settings.manual = false;
        }
        break;
      case "automaticCalling":
        if (value === true) {
          // set manual to false
          settings.manual = false;
        }
        break;
      case "manual":
        if (value === true) {
          // turn off all wild bingo settings
          settings.wildBingo = false;
          settings.wildBingoDouble = false;
          settings.wildBingoCustom = false;
          settings.wildBingoEvensAndOdds = false;
          settings.wildBingoEvens = false;
          settings.wildBingoOdds = false;
          // set automatic calling to false
          settings.automaticCalling = false;
        }
        break;
      case "caller":
        // if caller, run the test speech
        dispatchCustomEvent("test-speech", {
          message: "Let's Play Bingo!",
          voice: value,
        });
        break;
      case "callermp3":
        const callerList = this.mp3Callers.filter((a) => a.value === value);
        if (callerList.length > 0) {
          const caller = callerList[0];
          let saying = new Audio(caller.audio.letsplaybingo);
          saying.play();
        }
        break;
      case "chime":
        // if the chime play test audio
        let chimeTest = new Audio(value);
        chimeTest.play();
        break;
      default:
        break;
    }

    this.setState({ settings: settings });
  };

  toggleSettingsPanel = () => {
    document.body.classList.toggle("no-scroll");
    let newState = !this.state.settingsPanelOpen;
    if (newState === false) {
      this.handleSave(true); // save settings
    } else {
      dispatchCustomEvent("pause-game", "pause-game");
    }
    dispatchCustomEvent("settingspanel-open", newState);
    this.setState({ settingsPanelOpen: newState });
  };

  handleSave = (togglePanel) => {
    document.body.classList.remove("no-scroll");
    let stateObj = { ...this.state.settings };
    // remove unnecessary elements
    delete stateObj.settingsPanelOpen;
    // send out settingssaved event
    dispatchCustomEvent("settingssaved", stateObj);
    if (togglePanel) {
      stateObj.settingsPanelOpen = false; // ensure we close the panel
    }
    this.setState(stateObj);
  };

  getRandomHotBall = () => {
    const settings = { ...this.state.settings };
    settings.hotBallNumber = getRandomNumberInRange(1, 75);
    this.setState({ settings: settings });
  };

  get settingsPanelClasses() {
    return this.state.settingsPanelOpen === true ? "settings-panel open" : "settings-panel closed";
  }

  /**
   * Returns an array of settings that will be displayed in the settings panel
   * Section properties: {section}<String> (title text), {settings}<Array> (array of individual settings)
   * Settings properties:
   *  {name} the setting name
   *  {label} the label displayed
   *  {type} the type of setting (toggle, slider, pattern, )
   *  {disabled} boolean indicating if this setting is disabled
   *  {value} the value of this setting
   *  {helptext} info popup displayed next to the label
   *  {options} an object containing any additional options specific to this setting
   */
  get settingsList() {
    return [
      {
        title: "Board/Caller Settings",
        show: true,
        settings: [
          // Manual Mode --------------------------------------------------------------
          {
            name: "manual",
            label: "Manual mode",
            type: "toggle",
            disabled: this.props.totalCalls > 0,
            value: this.state.settings.manual,
            helptext: this.props.totalCalls > 0 ? "You must reset the game before changing this setting." : "Manual mode allows you to use the board as a display board only. Great for if you're using a physical ball cage or machine.",
            options: null,
          },
          // Automatic Calling --------------------------------------------------------
          {
            name: "automaticCalling",
            label: "Automatic calling",
            type: "toggle",
            disabled: this.props.totalCalls > 0,
            value: this.state.settings.automaticCalling,
            helptext: this.state.settings.manual ? "Not available when manual mode is on" : null,
            options: null,
          },
          // Delay --------------------------------------------------------------------
          {
            name: "delay",
            label: this.state.settings.automaticCalling ? "Time between calls" : "Countdown to next call",
            type: "slider",
            value: this.state.settings.delay,
            show: this.state.settings.automaticCalling || (this.state.settings.manual && this.state.settings.showCountdown),
            helptext: null,
            options: { measurement: "second", measurementPlural: "seconds", min: "1", max: "60", step: "1" },
          },
        ],
      },
      {
        title: "Game Settings",
        show: true,
        settings: [
          // Pattern ----------------------------------------------------------------
          {
            name: "pattern",
            label: "Pattern",
            type: "pattern",
            value: this.state.settings.pattern,
            helptext: null,
            dismissableText: "You can type in the dropdown to find the pattern you are looking for!",
            options: { location: "settings", showSelect: true, showTitle: false },
          },
          // Disable main screen pattern --------------------------------------------
          {
            name: "disableMainScreenPattern",
            label: "Disable main screen pattern selection",
            type: "toggle",
            value: this.state.settings.disableMainScreenPattern,
            helptext: null,
            options: { infoPosition: "right" },
          },
          // Disable main screen pattern while in play ------------------------------
          {
            name: "disableMainScreenPatternWhileInPlay",
            label: "Disable main screen pattern selection while game is in play",
            type: "toggle",
            value: this.state.settings.disableMainScreenPatternWhileInPlay,
            helptext: "When selected, the pattern display on the main game screen is disabled only when there's an active game in play. This prevents accidental changes to the pattern while playing. You can still change the game pattern in the settings panel at any time.",
            options: { infoPosition: "right" },
          },
          // Show main screen pattern select -----------------------------------------
          {
            name: "showMainScreenPatternSelect",
            label: "Show main screen pattern menu",
            type: "toggle",
            value: this.state.settings.showMainScreenPatternSelect,
            helptext: null,
            options: { infoPosition: "right" },
          },
          // Show main screen rotating patterns ---------------------------------------
          {
            name: "showMainScreenRotatingPatterns",
            label: "Show main screen rotating patterns",
            type: "toggle",
            value: this.state.settings.showMainScreenRotatingPatterns,
            helptext: "Rotates through some potential winning patterns for games that can be won multiple ways!",
            options: { infoPosition: "right" },
          },
          // Skip Unused Numbers -----------------------------------------------------
          {
            name: "skipUnusedNumbers",
            label: "Skip unused numbers",
            type: "toggle",
            value: this.state.settings.skipUnusedNumbers,
            helptext: this.state.settings.manual ? "Not available when manual mode is on" : "When selected, the caller will not call unused numbers in the selected pattern. The numbers will still be marked as called in the board.",
          },
          // Skip Unused Numbers Indicator -------------------------------------------
          {
            name: "skipUnusedIndicator",
            label: "Show skip unused numbers indicator",
            type: "toggle",
            value: this.state.settings.skipUnusedIndicator,
            helptext: "When selected, an indicator will display on the top left corner of the pattern on screen to inform the users that unused numbers are being skipped.",
            options: { infoPosition: "left" },
          },
          // Hide Unused Numbers -----------------------------------------------------
          {
            name: "hideUnusedNumbers",
            label: "Hide unused numbers on the board",
            type: "toggle",
            value: this.state.settings.hideUnusedNumbers,
            helptext: "When selected, any unused numbers will not be shown as called on the board.",
            options: { infoPosition: "left" },
          },
        ],
      },
      {
        title: "Wild Bingo",
        show: true,
        settings: [
          // Standard Wild Bingo -----------------------------------------------------
          {
            name: "wildBingo",
            label: "Wild bingo",
            type: "toggle",
            disabled: this.props.totalCalls > 0,
            value: this.state.settings.wildBingo,
            helptext: this.props.totalCalls > 0 ? "You must reset the game before changing this setting." : "Wild Bingo is when the first number called is wild: you'll daub every number ending in the same number the wild ball ends with. Example: N32 is the wild ball, daub every number ending in 2.",
            options: {},
          },
        ],
      },
      // Additional Wild Bingo Settings ----------------------------------------------
      {
        title: "Additional Wild Bingo Types",
        show: this.state.settings.wildBingo ? true : false,
        settings: [
          {
            name: "wildBingoDouble",
            label: "Double wilds",
            type: "toggle",
            disabled: this.props.totalCalls > 0 || this.state.settings.wildBingoEvensAndOdds || this.state.settings.wildBingoEvens || this.state.settings.wildBingoOdds || this.state.settings.wildBingoCustom,
            value: this.state.settings.wildBingoDouble,
            helptext: "Two wild balls will be called at the start of the game.",
            options: {},
          },
          {
            name: "wildBingoEvensAndOdds",
            label: "Evens and odds",
            type: "toggle",
            disabled: this.props.totalCalls > 0 || this.state.settings.wildBingoDouble || this.state.settings.wildBingoEvens || this.state.settings.wildBingoOdds || this.state.settings.wildBingoCustom,
            value: this.state.settings.wildBingoEvensAndOdds,
            helptext: "A random number will be selected at the start of the game - if even, all evens will be wild - if odd, all odds will be wild.",
            options: {},
          },
          {
            name: "wildBingoEvens",
            label: "Evens",
            type: "toggle",
            disabled: this.props.totalCalls > 0 || this.state.settings.wildBingoDouble || this.state.settings.wildBingoEvensAndOdds || this.state.settings.wildBingoOdds || this.state.settings.wildBingoCustom,
            value: this.state.settings.wildBingoEvens,
            helptext: "All even numbers will be called at the start of the game.",
            options: {},
          },
          {
            name: "wildBingoOdds",
            label: "Odds",
            type: "toggle",
            disabled: this.props.totalCalls > 0 || this.state.settings.wildBingoEvensAndOdds || this.state.settings.wildBingoEvens || this.state.settings.wildBingoDouble || this.state.settings.wildBingoCustom,
            value: this.state.settings.wildBingoOdds,
            helptext: "All odd numbers will be called at the start of the game.",
            options: {},
          },
          {
            name: "wildBingoCustom",
            label: "Custom",
            type: "toggle",
            value: this.state.settings.wildBingoCustom,
            helptext: "Choose which number you want to be while. 10 will be all numbers ending in 0.",
            options: {},
          },
          {
            name: "wildBingoCustomValue",
            label: "Wild bingo: custom",
            type: "slider",
            value: this.state.settings.wildBingoCustomValue,
            helptext: null,
            show: this.state.settings.wildBingoCustom,
            options: { measurement: "", measurementPlural: "", min: "1", max: "10", step: "1" },
          },
        ],
      },
      // Hot Ball Settings -----------------------------------------------------------
      {
        title: "Hot Ball",
        show: true,
        settings: [
          {
            name: "hotBall",
            label: "Hot ball",
            type: "toggle",
            value: this.state.settings.hotBall,
            helptext: "When a player gets a Bingo with the Hot Ball as the current call, they win a progressive jackpot.",
            options: {},
          },
          {
            name: "hotBallNumber",
            label: "Hot ball selection",
            type: "slider",
            show: this.state.settings.hotBall,
            value: this.state.settings.hotBallNumber,
            helptext: null,
            options: { measurement: "", measurementPlural: "", min: "1", max: "75", step: "1" },
          },
        ],
      },
      // Audio Settings --------------------------------------------------------------
      {
        title: "Audio Settings",
        show: true,
        settings: [
          {
            name: "audibleCaller",
            label: "Audible caller (speech synthesis)",
            type: "toggle",
            value: this.state.settings.audibleCaller,
            disabled: this.state.settings.mp3Caller === true,
            dismissableText: "There are some known issues that may occur with the speech synthesis audible calling feature on certain devices. If you run into any issues please check the help page for more information or disable the audible caller and use the mp3 version.",
            helptext: "This feature uses the web speech synthesis API which is experiemental but allows for a wider variety of voices and languages depending on your device and browser combination. If you run into issues with this feature, please use the mp3 based caller below.",
            options: {},
          },
          {
            name: "caller",
            label: "Caller selection (speech synthesis)",
            type: "select",
            disabled: this.state.settings.audibleCaller === false,
            value: this.state.settings.caller,
            helptext: null,
            show: this.state.settings.audibleCaller === true && this.callers.length > 0,
            options: this.callers,
          },
          {
            name: "mp3Caller",
            label: "Audible caller (mp3)",
            type: "toggle",
            value: this.state.settings.mp3Caller,
            disabled: this.state.settings.audibleCaller === true,
            dismissableText: "Use this if you're having trouble with the original speech synthesis audible caller. This one has limited voice options and is currently only available in English, Spanish and French. May not work with automatic calling in certain mobile browsers due to a device limitation.",
            helptext: "Can only be enabled if audible caller (speech synthesis) is turned off.",
            options: {},
          },
          {
            name: "callermp3",
            label: "Caller selection (mp3)",
            type: "select",
            disabled: this.state.settings.mp3Caller === false,
            value: this.state.settings.callermp3,
            helptext: null,
            show: this.state.settings.mp3Caller === true && this.mp3Callers.length > 0,
            options: this.mp3Callers,
          },
          {
            name: "chattyCaller",
            label: "Chatty caller",
            type: "toggle",
            value: this.state.settings.chattyCaller,
            helptext: "Adds additional vocalization such as 'Let's Play Bingo!' at the start of a new game.",
            show: this.state.settings.audibleCaller === true,
            options: {},
          },
          {
            name: "doubleCall",
            label: "Call numbers twice",
            type: "toggle",
            value: this.state.settings.doubleCall,
            helptext: "Calls the numbers again individually after the initial call. Example: B12, B: 1, 2.",
            show: this.state.settings.audibleCaller === true,
            options: {},
          },
          {
            name: "audibleChime",
            label: "Play audible chime",
            type: "toggle",
            value: this.state.settings.audibleChime,
            helptext: null,
            options: {},
          },
          {
            name: "chime",
            label: "Chime selection",
            type: "select",
            value: this.state.settings.chime,
            helptext: null,
            show: this.state.settings.audibleChime === true,
            options: chimes,
          },
        ],
      },
      // Game Controls --------------------------------------------------------------
      {
        title: "Game Controls",
        show: true,
        settings: [
          {
            name: "hideControls",
            label: "Hide on screen controls",
            type: "toggle",
            value: this.state.settings.hideControls,
            helptext: "Ensure you have keyboard controls or the pop out control panel enabled so you will be able to control the game. If the pop out control panel is enabled, this is enabled automatically.",
            options: { infoPosition: "left" },
          },
          {
            name: "popOutControls",
            label: "Show pop out control panel",
            type: "toggle",
            value: this.state.settings.popOutControls,
            helptext: "Opens a small window that contains the game controls. If pop out controls are enabled, on screen controls are hidden automatically.",
            options: {},
          },
          {
            name: "keyboardControls",
            label: "Enable keyboard controls",
            type: "toggle",
            value: this.state.settings.keyboardControls,
            helptext: "This setting enables controling game play with the keyboard. Great to use in combination with Hide On Screen Controls.",
            options: {
              detail: (
                <div className={this.state.settings.keyboardControls ? "show detail-box" : "hide"}>
                  <code>Spacebar</code> pause/resume game
                  <br />
                  <code>Right Arrow</code> next ball
                  <br />
                  <code>R</code> reset game (press <code>Enter</code> to confirm)
                  <br />
                </div>
              ),
            },
          },
          {
            name: "stoplight",
            label: "Use stoplight control colors",
            type: "toggle",
            disabled: this.state.settings.manual,
            value: this.state.settings.stoplight,
            helptext: "Changes the play/pause buttons to use stoplight coloring: green for play, red for pause.",
            options: {},
          },
          {
            name: "hideShuffle",
            label: "Hide shuffle button",
            type: "toggle",
            value: this.state.settings.hideShuffle,
            helptext: null,
            options: {},
          },
        ],
      },
      // Call Trackers --------------------------------------------------------------
      {
        title: "Call Tracking Options",
        show: true,
        settings: [
          {
            name: "showCountdown",
            label: "Show next call countdown",
            type: "toggle",
            value: this.state.settings.showCountdown,
            options: {},
          },
          {
            name: "showCurrentCall",
            label: "Show current call",
            type: "toggle",
            value: this.state.settings.showCurrentCall,
            helptext: null,
            options: {},
          },
          {
            name: "showPreviousCalls",
            label: "Show previous calls",
            type: "toggle",
            value: this.state.settings.showPreviousCalls,
            helptext: null,
            options: {},
          },
          {
            name: "previousCallsToShow",
            label: "Number of previous calls to show",
            type: "slider",
            value: this.state.settings.previousCallsToShow,
            helptext: null,
            options: { measurement: "call", measurementPlural: "calls", min: "1", max: "5", step: "1" },
          },
        ],
      },
      {
        title: "Layout Options",
        show: true,
        settings: [
          {
            name: "fullscreen",
            label: "Display in fullscreen",
            type: "toggle",
            value: this.state.settings.fullscreen,
            helptext: "Fullscreen will take effect when this panel is closed.",
            options: {},
          },
          {
            name: "hideFooter",
            label: "Hide footer",
            type: "toggle",
            value: this.state.settings.hideFooter,
            helptext: null,
            options: {},
          },
          {
            name: "layout",
            label: "Layout",
            type: "select",
            value: this.state.settings.layout,
            helptext: null,
            show: true,
            options: layouts,
          },
        ],
      },
      {
        title: "Theme Options",
        show: true,
        settings: [
          {
            name: "theme",
            label: "Select theme",
            type: "select",
            defaultValue: "lpb",
            value: this.state.settings.theme,
            show: true,
            options: themes,
          },
        ],
      },
    ];
  }

  render() {
    return (
      <div tabIndex="-1">
        <div tabIndex="-1" className={this.settingsPanelClasses}>
          <div tabIndex="-1" className="backdrop" onClick={this.toggleSettingsPanel}></div>
          <div className="inner-panel">
            {/* ---------------------- Header ------------------- */}
            <h2 className="margin-bottom-none">
              Settings
              <button aria-label="Close Settings Panel" className="icon-button close-button" onClick={this.toggleSettingsPanel}>
                <i className="fa fa-solid fa-xmark" />
              </button>
            </h2>
            <div className="row padding-horizontal-xlg">
              <div className="col grow x-small-text">Settings will be auto-saved when you close this panel.</div>
              <div className="col shrink">
                <button className="small-button no-text-wrap" onClick={this.handleResetSettings}>
                  reset settings
                </button>
              </div>
            </div>
            <div className="padding-horizontal-xlg">
              <p className={this.state.dismissed.includes("settings-intro") ? "hide" : "settings-intro dismissable margin-top-none small-text"}>
                Some settings are disabled when a game is running and/or certain settings are enabled. Hover over the tooltip
                <HelperText text="The tool tip will display more info about the setting." /> next to the setting for details.
                <a href="#" data-block="settings-intro" className="text-button margin-sm" onClick={this.handleDismiss.bind(this)}>
                  Dismiss
                </a>
              </p>
              <p className="small-text show-support">
                <strong>Love the app and want to show your support?</strong>
                <br />
                Get your gear in the{" "}
                <a href="http://letsplaybingo.store/" target="_blank" rel="noopener noreferrer">
                  Let's Play Bingo Store
                </a>
                <br />
                or say thanks{" "}
                <a href="https://www.buymeacoffee.com/letsplaybingo" target="_blank" rel="noopener noreferrer">
                  with a coffee
                </a>
                !
              </p>
            </div>

            {/* ------------------ Settings --------------------- */}
            {this.settingsList.map((settingGroup, index) => (
              <div key={index} className={settingGroup.show ? "show setting-group" : "hide setting-group"}>
                <h3>{settingGroup.title}</h3>
                {settingGroup.settings.map((setting, i) => {
                  switch (setting.type) {
                    // Toggle --------------------------------------------
                    case "toggle":
                      return (
                        <div>
                          <Toggle key={i} disabled={setting.disabled} eventName="settingupdate" info={setting.helptext} label={setting.label} name={setting.name} value={setting.value} detail={setting.options?.detail} />
                          <p className={!setting.dismissableText || this.state.dismissed.includes(setting.name) ? "hide" : "dismissable margin-top-none small-text"}>
                            {" "}
                            {setting.dismissableText}
                            <a href="#" data-block={setting.name} className="text-button margin-sm" onClick={this.handleDismiss.bind(this)}>
                              Dismiss
                            </a>
                          </p>
                        </div>
                      );
                    // Select --------------------------------------------
                    case "select":
                      return (
                        <div>
                          <Select key={i} show={setting.show} eventName="settingupdate" id={setting.name} disabled={setting.disabled} label={setting.label} name={setting.name} info={setting.helptext} options={setting.options} value={setting.value} />
                          <p className={!setting.dismissableText || this.state.dismissed.includes(setting.name) ? "hide" : "dismissable margin-top-none small-text"}>
                            {" "}
                            {setting.dismissableText}
                            <a href="#" data-block={setting.name} className="text-button margin-sm" onClick={this.handleDismiss.bind(this)}>
                              Dismiss
                            </a>
                          </p>
                        </div>
                      );
                    // Slider --------------------------------------------
                    case "slider":
                      return (
                        <div>
                          <Slider key={i} show={setting.show} eventName="settingupdate" label={setting.label} name={setting.name} info={setting.helptext} measurement={setting.options.measurement} measurementplural={setting.options.measurementPlural} min={setting.options.min} max={setting.options.max} step={setting.options.step} value={setting.value} />
                          <p className={!setting.dismissableText || this.state.dismissed.includes(setting.name) ? "hide" : "dismissable margin-top-none small-text"}>
                            {" "}
                            {setting.dismissableText}
                            <a href="#" data-block={setting.name} className="text-button margin-sm" onClick={this.handleDismiss.bind(this)}>
                              Dismiss
                            </a>
                          </p>
                        </div>
                      );
                    // Pattern --------------------------------------------
                    case "pattern":
                      return (
                        <div>
                          <CurrentPattern rotatePatterns={true} pattern={setting.value} location={setting.options.location} showHelpText={false} showSelect={setting.options.show} showTitle={false} />
                          <p className={!setting.dismissableText || this.state.dismissed.includes(setting.name) ? "hide" : "dismissable margin-top-none small-text"}>
                            {" "}
                            {setting.dismissableText}
                            <a href="#" data-block={setting.name} className="text-button margin-sm" onClick={this.handleDismiss.bind(this)}>
                              Dismiss
                            </a>
                          </p>
                        </div>
                      );
                    default:
                      return;
                  }
                })}
              </div>
            ))}

            {/* ----------------- Foot Notes ----------------- */}
            <div className="updates-panel padding-vertical-xxlg padding-horizontal-xlg">
              <MiniUpdates gamedata={this.state} />
              <p className="x-small-text">Thanks for playing!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Settings;
