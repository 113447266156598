export const vPatterns = [
  /* --------------------- V -------------------- */
  {
    value: "V",
    label: "V",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
  },
];
