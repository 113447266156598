export const gPatterns = [
  /* --------------------- G -------------------- */
  {
    value: "G Straight ",
    label: "G Straight ",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "I", "N", "O"],
    credit: null,
  },
  {
    value: "Gift",
    label: "Gift",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, true, true, true],
      N: [false, true, true, true, true],
      G: [true, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Denise S",
  },
  {
    value: "Ghost",
    label: "Ghost",
    otherNames: [],
    pattern: {
      B: [false, true, true, false, false],
      I: [true, false, false, true, true],
      N: [true, false, false, false, false],
      G: [true, false, false, true, true],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: "Patricia Morrison",
  },
  {
    value: "GO",
    label: "GO",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B", "I", "N"],
    credit: null,
  },
  {
    value: "Goal Post",
    label: "Goal Post",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, false],
      N: [false, false, true, true, true],
      G: [false, false, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Greater",
    label: "Greater",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "G", "O"],
    credit: null,
  },
  {
    value: "Growing That Pot Leaf",
    label: "Growing That Pot Leaf",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, false, false, true, true],
      N: [true, true, true, true, true],
      G: [false, false, false, true, true],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
