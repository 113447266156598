export const hPatterns = [
  /* --------------------- H -------------------- */
  {
    value: "Hammer Head T",
    label: "Hammer Head T",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Handlebar Moustache ",
    label: "Handlebar Moustache ",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, true, false],
    },
    unusedLetters: ["N"],
    credit: "Tony Spin",
  },
  {
    value: "Happy Face",
    label: "Happy Face",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [true, false, false, false, true],
      N: [false, false, true, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hardway",
    label: "Hardway",
    otherNames: ["Any Bingo - No Free Space", "Regular Bingo - No Free Space", "Any 5 Straight Line Horizontal Or Vertical Except Free Space"],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [false, true, false, false, false],
      G: [false, true, false, false, false],
      O: [false, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hardway Stamp",
    label: "Hardway Stamp",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, true, true],
      N: [false, false, false, false, false],
      G: [false, true, true, false, false],
      O: [false, true, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Hard Way Triple Dot No Diagonals",
    label: "Hard Way Triple Dot No Diagonals",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [false, true, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
    ],
  },
  {
    value: "Headphones",
    label: "Headphones",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, true, true],
      N: [true, false, false, false, false],
      G: [true, false, false, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Lindsey J",
  },
  {
    value: "Heart",
    label: "Heart",
    otherNames: ["Heart2heart"],
    pattern: {
      B: [false, true, true, false, false],
      I: [true, true, true, true, false],
      N: [false, true, true, true, true],
      G: [true, true, true, true, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: "Michele Davis",
  },
  {
    value: "Heart (Alternate)",
    label: "Heart (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, true, false],
      N: [false, true, false, false, true],
      G: [true, false, false, true, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hearts Of Love",
    label: "Hearts Of Love",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, true, false],
      N: [false, true, true, false, true],
      G: [true, false, false, true, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hi",
    label: "Hi",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["G"],
    credit: null,
  },
  {
    value: "Highway",
    label: "Highway",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Hippo Head",
    label: "Hippo Head",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [true, true, true, true, true],
      N: [false, true, true, true, true],
      G: [true, true, true, true, true],
      O: [false, false, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hole in the wall",
    label: "Hole in the wall",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, false, true, true],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Home",
    label: "Home",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, false, false, false],
      N: [true, false, false, false, false],
      G: [false, true, false, false, false],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Horse Head",
    label: "Horse Head",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, true, true, true],
      N: [false, true, true, true, false],
      G: [true, true, true, true, false],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Horseshoe",
    label: "Horseshoe",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, false],
      N: [true, false, false, false, false],
      G: [true, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Rebecca",
  },
  {
    value: "Hot Dog In A Bun",
    label: "Hot Dog In A Bun",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Hourglass",
    label: "Hourglass",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, true, true],
      N: [true, false, true, false, true],
      G: [true, true, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hourglass Top",
    label: "Hourglass Top",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, true, true],
      N: [true, true, true, false, true],
      G: [true, true, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Hourglass Bottom",
    label: "Hourglass Bottom",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, true, true],
      N: [true, false, true, true, true],
      G: [true, true, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "House",
    label: "House",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, true],
      N: [true, true, true, false, false],
      G: [false, true, true, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "House (Alternate)",
    label: "House (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, true, true, true],
      N: [true, true, true, false, false],
      G: [false, true, true, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
