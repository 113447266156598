import React from "react";
import { getBlankPattern, getPresetPatterns } from "../helpers/PresetPatterns";
import { dispatchCustomEvent } from "../helpers/Utilities";
import Select from "./Select";
import Pattern from "./Pattern";
import HelperText from "./HelperText";

const presets = getPresetPatterns();
const blankPattern = getBlankPattern();
/**
 * CurrentPattern
 * Renders a visual display of the bingo pattern that has been selected
 * Returns a blank pattern if none has been selected
 * Optionally lets the user create their own custom pattern
 * Properties include:
 * disabled - Boolean, indicates if the pattern should be disabled
 * showSelect - Boolean, indicates if the pattern selection dropdown should show
 * pattern - Object, contains information about the pattern
 * showTitle - Boolean, indicates if the title of the pattern should display below
 */
class CurrentPattern extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.presets = presets;
  }

  componentDidMount() {
    document.addEventListener("custompattern", this.handleCustomPattern);
    document.addEventListener("patterndropdown", this.handleSelect);
  }

  componentWillUnmount() {
    document.removeEventListener("custompattern", this.handleCustomPattern);
    document.removeEventListener("patterndropdown", this.handleSelect);
  }

  getPattern(patternName) {
    let pattern = blankPattern;
    this.presets.forEach((preset) => {
      if (preset.value === patternName) {
        pattern = preset;
      }
    });
    return pattern;
  }

  handleSelect = (value) => {
    let selectedPattern = {};
    if (value === "blank") {
      /* It's a getter that returns the pattern that is currently selected. */
      selectedPattern = blankPattern;
      this.handleCustomPattern(blankPattern);
    } else {
      selectedPattern = this.getPattern(value);
      value = selectedPattern.label;
    }
    dispatchCustomEvent("patternupdate", value);

    this.setState({
      pattern: selectedPattern,
      value: value,
    });
  };

  handleCustomPattern = (event) => {
    const pattern = event.detail ? event.detail : event;
    let selectedPattern = pattern;
    this.presets = this.presets.map((preset) => {
      if (preset.label === "Custom") {
        preset.pattern = pattern.pattern;
        selectedPattern = preset;
      }
      return preset;
    });
    selectedPattern.unusedLetters = pattern.unusedLetters;

    this.setState({ pattern: selectedPattern, value: "Custom" });
    // Alert the settings if we changed to a custom pattern in the display board
    if (this.props.showSelect === false) {
      dispatchCustomEvent("patternupdate", selectedPattern);
      dispatchCustomEvent("settingupdate", {
        property: "pattern",
        value: selectedPattern,
      });
    }
  };

  get patternOptions() {
    let patternOptions = [];
    const searchTerm = this.state.searchTerm.toLowerCase();
    if (searchTerm.length > 0) {
      let patternsToReturn = [];
      presets.forEach((preset) => {
        let values = preset.value + "; " + preset.label;
        if (Object.prototype.hasOwnProperty.call(preset, "otherNames")) {
          values += preset.otherNames.join(";");
        }
        if (values.toLowerCase().includes(searchTerm)) {
          patternsToReturn.push(preset);
        }
      });
      return patternsToReturn;
    } else {
      return presets;
    }
  }

  get selectedPattern() {
    return this.props.pattern;
  }

  render() {
    if (this.props.location === "settings") {
      return (
        <div className="row pattern-select align-center gutters-sm">
          <div className="col full-width">
            <span className="h6 margin-bottom-none">Pattern Selection</span>
          </div>
          <div className="col full-width">
            <div className="pattern">
              <div className={this.props.showSkipIndicator ? "skip-on" : "hide"}>
                <i className="fa fa-solid fa-share"></i>
              </div>
              <Pattern rotate={this.props.rotatePatterns} editable={!this.props.disabled} presets={this.presets} pattern={this.selectedPattern} showTitle={this.props.showTitle} titlePosition="bottom" />
            </div>
          </div>
          <div className="col full-width pattern-select">
            <Select show={true} id="patternSelect" label="Choose a Pattern" defaultValue="blank" name="pattern" options={this.presets} value={this.selectedPattern.label} handler={this.handleSelect} />
            <p className={this.props.showHelpText ? "margin-horizontal-sm margin-vertical-none x-small-text" : "hide"}>You can type in the dropdown to find the pattern you want!</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pattern">
          <div className={this.props.showSkipIndicator ? "skip-on" : "hide"}>
            <i className="fa fa-solid fa-share"></i>
          </div>
          <Pattern rotate={this.props.rotatePatterns} editable={!this.props.disabled} presets={this.presets} pattern={this.selectedPattern} showTitle={this.props.showTitle} titlePosition="bottom" />
          <Select show={this.props.showSelect} disabled={this.props.disabled} id="mainScreenPatternSelect" label="Choose a Pattern" defaultValue="blank" eventName={this.props.eventName} name="pattern" options={this.presets} value={this.selectedPattern.label} handler={this.handleSelect} />
        </div>
      );
    }
  }
}

export default CurrentPattern;
