import React from "react";
import BingoCard from "../components/BingoCard";
import Select from "../components/Select";
import Input from "../components/Input";
import { getRandomNumberInRange } from "../helpers/Utilities";
import { NavLink } from "react-router-dom";

const localStorageId = "letsplaybingo-playalong";

const cardColors = [
  { value: "amber", label: "Amber" },
  { value: "amber-screen", label: "Amber Screen" },
  { value: "blue", label: "Blue" },
  { value: "blue-screen", label: "Blue Screen" },
  { value: "brown", label: "Brown" },
  { value: "brown-screen", label: "Brown Screen" },
  { value: "chartreuse", label: "Chartreuse" },
  { value: "chartreuse-screen", label: "Chartreuse Screen" },
  { value: "green", label: "Green" },
  { value: "green-screen", label: "Green Screen" },
  { value: "magenta", label: "Magenta" },
  { value: "magenta-screen", label: "Magenta Screen" },
  { value: "orange", label: "Orange" },
  { value: "orange-screen", label: "Orange Screen" },
  { value: "pink", label: "Pink" },
  { value: "pink-screen", label: "Pink Screen" },
  { value: "purple", label: "Purple" },
  { value: "purple-screen", label: "Purple Screen" },
  { value: "rainbow", label: "Rainbow" },
  { value: "rainbow-screen", label: "Rainbow Screen" },
  { value: "red", label: "Red" },
  { value: "red-screen", label: "Red Screen" },
  { value: "teal", label: "Teal" },
  { value: "teal-screen", label: "Teal Screen" },
  { value: "vermillion", label: "Vermillion" },
  { value: "vermillion-screen", label: "Vermillion Screen" },
  { value: "violet", label: "Violet" },
  { value: "violet-screen", label: "Violet Screen" },
  { value: "yellow", label: "Yellow" },
  { value: "yellow-screen", label: "Yellow Screen" },
];

const dauberColors = [
  { value: "amber", label: "Amber" },
  { value: "blue", label: "Blue" },
  { value: "brown", label: "Brown" },
  { value: "chartreuse", label: "Chartreuse" },
  { value: "green", label: "Green" },
  { value: "magenta", label: "Magenta" },
  { value: "orange", label: "Orange" },
  { value: "pink", label: "Pink" },
  { value: "purple", label: "Purple" },
  { value: "rainbow", label: "Rainbow" },
  { value: "red", label: "Red" },
  { value: "teal", label: "Teal" },
  { value: "vermillion", label: "Vermillion" },
  { value: "violet", label: "Violet" },
  { value: "yellow", label: "Yellow" },
];

const cardSizeOptions = [
  { value: "small", label: "small" },
  { value: "medium", label: "medium" },
  { value: "large", label: "large" },
  { value: "xlarge", label: "xlarge" },
];

class Play extends React.Component {
  constructor(props) {
    super(props);
    let cachedSettings = localStorage.getItem(localStorageId);
    if (cachedSettings) {
      this.state = JSON.parse(cachedSettings);
      this.state.generatedCards = [];
    } else {
      this.state = {
        generatedCards: [],
        numberOfCards: "6",
        cardSize: "small",
        color: "blue",
        dauber: "yellow",
      };
    }
  }

  componentDidMount() {
    document.title = "Let's Play Bingo! | Play Along";
    document.addEventListener("playalong", this.handleCardOptionUpdates);
  }
  componentWillUnmount() {
    document.removeEventListener("playalong", this.handleCardOptionUpdates);
  }

  clearCards = () => {
    document.querySelectorAll(".daubed").forEach((element) => {
      element.classList.remove("daubed");
    });
  };

  handleCardOptionUpdates = (event) => {
    const stateObj = {};
    if (event.detail.property === "numberOfCards") {
      stateObj[event.detail.property] = parseInt(event.detail.value);
    } else {
      stateObj[event.detail.property] = event.detail.value;
    }
    this.setState(stateObj);

    // Prep state for going into localStorage
    let currentState = { ...this.state };
    Object.keys(stateObj).forEach((key) => {
      currentState[key] = stateObj[key];
    });
    localStorage.setItem(localStorageId, JSON.stringify(currentState));
  };

  handleButton = () => {
    this.clearCards();
    let cards = [];
    for (let i = 1; i <= this.state.numberOfCards; i++) {
      cards.push(this.generateCard());
    }
    this.setState({ generatedCards: cards });
  };

  generateCard = () => {
    const columns = ["B", "I", "N", "G", "O"];
    let card = {};
    columns.map((letter, index) => {
      let chosenNumbers = [];
      let min = index === 0 ? 1 : index * 5 * 3 + 1;
      let max = (index + 1) * 3 * 5;
      for (let i = 0; i < 5; i++) {
        chosenNumbers.push(getRandomNumberInRange(min, max, chosenNumbers));
      }
      card[letter] = chosenNumbers;
      return letter;
    });
    return card;
  };

  get generateButtonDisabled() {
    return this.state.numberOfCards === null || this.state.color === null;
  }

  get sectionClasses() {
    return `play-along ${this.state.cardSize} ${this.state.dauber}-dauber`;
  }

  render() {
    return (
      <section className={this.sectionClasses}>
        <div className="row container">
          <div className="col full-width">
            <h1>
              Play Along!
              <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                <button aria-label="Back to Game" className="primary-button">
                  Back to Game
                </button>
              </NavLink>
            </h1>
            <p>Now you can play along by marking digital cards right on the screen!</p>
          </div>
          <div className="col full-width">
            <div className="row align-center justify-start">
              <div className="col shrink">
                <Input eventName="playalong" label="Number of Cards" name="numberOfCards" value={this.state.numberOfCards} />
              </div>
              <div className="col shrink">
                <Select show={true} eventName="playalong" id="color" label="Card Colors" name="color" options={cardColors} value={this.state.color} />
              </div>
              <div className="col shrink">
                <Select show={true} eventName="playalong" id="cardSize" label="Card Size" name="cardSize" options={cardSizeOptions} value={this.state.cardSize} />
              </div>
              <div className="col shrink">
                <Select show={true} eventName="playalong" id="dauber" label="Dauber Colors" name="dauber" options={dauberColors} value={this.state.dauber} />
              </div>
              <div className="col shrink no-text-wrap padding-horizontal-xxlg padding-top-xlg">
                <button aria-label="Generate Cards" className="primary-button" onClick={this.handleButton.bind(this)} disabled={this.generateButtonDisabled}>
                  Generate Cards
                </button>
              </div>
              <div className="col shrink no-text-wrap padding-top-xlg">
                <button aria-label="Clear Cards" className="secondary-button" onClick={this.clearCards.bind(this)}>
                  Clear Cards
                </button>
              </div>
            </div>
          </div>
          <div className="col full-width">
            <div className="row card-block justify-center margin-vertical-lg">
              <div className="col text-center">
                {this.state.generatedCards.map((card, index) => {
                  return (
                    <div data-color={this.state.color} className="card" key={"a" + index}>
                      <BingoCard card={card} daubable={true} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Play;
