import React from "react";
import ReactDOM from "react-dom";
import { dispatchCustomEvent } from "../helpers/Utilities";
import CallInfo from "./CallInfo";

/**
 * Controls class
 * Renders a visual control panel for controlling the game
 * Properties include:
 * popout - Boolean representing whether popup controls should be enabled or not
 * settings - an object with all of the game settings
 * totalCalls - integer - number of calls already made
 * previousCall - Object - bingo ball object to pass to <CallInfo/>
 * automatic - boolean - indicates if automatic calling is on
 * running - boolean - indicates if the game is currently running
 */
class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetModal: false,
      popupOpen: false,
    };
    this.containerEl = document.createElement("div");
    this.externalWindow = null;
  }

  componentDidMount() {
    document.addEventListener("handlereset", this.handleToggleResetModal);
    document.addEventListener("keydown", this.handleKeydown);
    this.openPopup();
  }

  componentWillUnmount() {
    document.removeEventListener("handlereset", this.handleToggleResetModal);
    document.removeEventListener("keydown", this.handleKeydown);
    this.closePopup();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      // Props have changed.
      if (this.props.popout === true && this.state.popupOpen === false) {
        this.openPopup();
      }
      if (this.props.popout === false && this.state.popupOpen) {
        this.closePopup();
      }
    }
  }

  openPopup = () => {
    // If the popout is enabled...
    if (this.props.popout) {
      this.externalWindow = window.open("", "", `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=420,height=330,left=0,top=0`);
      this.setState({ popupOpen: true });
      this.externalWindow.document.body.appendChild(this.containerEl);
      this.externalWindow.document.addEventListener("handlereset", this.handleToggleResetModal);
      this.externalWindow.document.addEventListener("keydown", this.handleKeydown);

      function copyStyles(src, dest) {
        Array.from(src.styleSheets).forEach((styleSheet) => {
          dest.head.appendChild(styleSheet.ownerNode.cloneNode(true));
        });
        Array.from(src.fonts).forEach((font) => dest.fonts.add(font));
      }
      copyStyles(window.document, this.externalWindow.document);
      window.addEventListener("pagehide", this.closePopup);
      this.isInit = true;
    }
  };

  closePopup = () => {
    if (this.state.popupOpen === true) {
      this.externalWindow.close();
      this.setState({ popupOpen: false });
    }
  };

  handleClick = (event) => {
    event.currentTarget.blur();
    if (event.currentTarget.value === "reset-game") {
      this.handleToggleResetModal();
    } else {
      dispatchCustomEvent("game-control", event.currentTarget.value);
    }
  };

  handleKeydown = (event) => {
    const key = event.code;
    // if no scroll is on, we are in the settings panel
    if (this.props.settings.keyboardControls && !document.body.classList.contains("typing") && !document.body.classList.contains("no-scroll")) {
      let params = this.props.totalCalls > 0 ? "call-number" : "new-game";
      switch (key) {
        case "Space":
          if (this.props.totalCalls > 0 && this.props.automatic && !this.props.manual) {
            params = this.props.running ? "pause-game" : "resume-game";
          }
          dispatchCustomEvent("game-control", params);
          break;
        case "ArrowRight":
          if (!this.props.running) {
            dispatchCustomEvent("game-control", params);
          }
          break;
        case "KeyR":
          this.handleToggleResetModal();
          break;
        case "Enter":
          if (this.state.showResetModal) {
            this.handleResetConfirmation();
          }
          break;
        case "Escape":
          if (this.state.showResetModal) {
            this.setState({ showResetModal: false });
          }
          break;
      }
    } else {
      if (this.state.showResetModal) {
        if (key === "Enter") {
          this.handleResetConfirmation();
        } else if (key === "Escape") {
          this.setState({ showResetModal: false });
        }
      }
    }
  };

  handleToggleResetModal = () => {
    // if the settings menu is open, no-scroll is on and we should not show the modal
    if (document.body.classList.contains("no-scroll")) {
      return false;
    }
    const newState = !this.state.showResetModal;
    this.setState({ showResetModal: newState });
  };

  handleResetConfirmation = () => {
    dispatchCustomEvent("game-control", "reset-game");
    this.handleToggleResetModal();
  };

  resetModal = () => {
    return this.state.showResetModal === true ? (
      <div>
        <div className="modal">
          <h2>Reset Game</h2>
          <p>
            Are you sure you want to reset the game?
            <br />
            <strong>This action cannot be undone.</strong>
            <br />
            Click confirm or press the Enter key.
          </p>
          <p className="text-center no-text-wrap">
            <button aria-label="Cancel" className="cancel-button" onClick={this.handleToggleResetModal}>
              Cancel
            </button>
            <button aria-label="Confirm" className="primary-button" onClick={this.handleResetConfirmation}>
              Confirm
            </button>
          </p>
        </div>
        <div className="modal-backdrop"></div>
      </div>
    ) : null;
  };

  get popoutControls() {
    return (
      <div className={`popout-controls controls ${this.props.settings.stoplight ? "stoplight" : ""} ${this.props.settings.theme}`}>
        {this.resetModal()}
        <div>
          <h2 className="margin-bottom-xlg">Let's Play Bingo Controls</h2>
          <CallInfo totalCalls={this.props.totalCalls} previousCall={this.props.previousCall} />
          <div className="row align-center justify-center control-buttons">
            <div className="col colspan4 button-col">
              {/* ----------- Start/Pause/Resume Button  ---------- */}
              {this.props.automatic && !this.props.settings.manual && this.props.totalCalls > 0 ? (
                <button aria-label={this.props.running ? "Pause" : "Resume"} className={this.props.totalCalls > 0 ? "primary-button" : "secondary-button"} value={this.props.running ? "pause-game" : "resume-game"} onClick={this.handleClick}>
                  {this.props.running ? <div>pause</div> : <div>resume</div>}
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="col colspan4 button-col">
              {/* ----------- Call Button  ---------- */}
              {this.props.totalCalls > 0 && !this.props.settings.manual ? (
                <button aria-label="Next Number" className="secondary-button" value="call-number" disabled={this.props.automatic && this.props.running} onClick={this.handleClick}>
                  <div>next number</div>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={this.props.settings.manual ? "hide" : "row align-center justify-center"}>
            <div className="col colspan8 single-button">
              {/* ----------- Start/Reset Button  ---------- */}
              <button aria-label={this.props.totalCalls > 0 ? "Reset Board" : "Start Game"} className={this.props.totalCalls > 0 ? "cancel-button" : "primary-button"} value={this.props.totalCalls > 0 ? "reset-game" : "new-game"} onClick={this.handleClick}>
                {this.props.totalCalls > 0 ? <div>reset board</div> : <div>start game</div>}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  get onScreenControls() {
    return (
      <div className={this.props.settings.stoplight ? "stoplight controls" : "controls"}>
        {this.resetModal()}

        {/* ----- Normal Modes ----- */}
        <div className={this.props.settings.manual ? "hide" : "show"}>
          {/* ----------- Start/Pause/Resume Button  ---------- */}
          {this.props.automatic && this.props.totalCalls > 0 ? (
            <button aria-label={this.props.running ? "Pause" : "Resume"} className={this.props.totalCalls > 0 ? "primary-button" : "secondary-button"} value={this.props.running ? "pause-game" : "resume-game"} onClick={this.handleClick}>
              {this.props.running ? <div>pause</div> : <div>resume</div>}
            </button>
          ) : (
            ""
          )}
          {/* ----------- Call Button  ---------- */}
          {this.props.totalCalls > 0 ? (
            <button aria-label="Next Number" className="secondary-button" value="call-number" disabled={this.props.automatic && this.props.running} onClick={this.handleClick}>
              <div>next number</div>
            </button>
          ) : (
            ""
          )}

          {/* ----------- Start/Reset Button  ---------- */}
          <button aria-label={this.props.totalCalls > 0 ? "Reset Board" : "Start Game"} className={this.props.totalCalls > 0 ? "cancel-button" : "primary-button"} value={this.props.totalCalls > 0 ? "reset-game" : "new-game"} onClick={this.handleClick}>
            {this.props.totalCalls > 0 ? <div>reset board</div> : <div>start game</div>}
          </button>

          {/* ----------- Shuffle Button ----------- */}
          <button aria-label="Shuffle" className={this.props.settings.hideShuffle || this.props.totalCalls > 0 ? "hide" : "primary-button"} value="shuffle" onClick={this.handleClick}>
            Shuffle
          </button>
        </div>
        {/* ----- Manual Mode Only ----- */}
        <div className={this.props.settings.manual ? "show" : "hide"}>
          <button aria-label="Reset Board" className="primary-button" value="reset-game" onClick={this.handleClick}>
            <div>reset board</div>
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (this.isInit) {
      return this.props.popout ? ReactDOM.createPortal(this.popoutControls, this.containerEl) : this.props.settings.hideControls ? null : this.onScreenControls;
    } else {
      return this.props.settings.hideControls ? this.resetModal() : this.onScreenControls;
    }
  }
}

export default Controls;
