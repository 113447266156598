import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getCache } from "../helpers/CacheManagement";

export default function Contact() {
  const cacheData = JSON.stringify(getCache());
  const userAgent = JSON.stringify(navigator.userAgentData);

  useEffect(() => {
    document.title = "Let's Play Bingo! | Contact";
  });
  return (
    <section>
      <div className="row container justify-start">
        <div className="col full-width">
          <h1>
            Contact
            <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
              <button aria-label="Back to Game" className="primary-button">
                Back to Game
              </button>
            </NavLink>
          </h1>
        </div>
      </div>
      <div className="row container justify-start">
        <div className="col colspan3 wrap-text padding-xlg">
          <p>Please use this form to get in touch with Karol, the developer of Let's Play Bingo. You can reach out for anything be it to report a bug, make suggestions on how to improve the app, or just to comment on the site.</p>
          <p>If you're having issues, please be sure to describe what you're experiencing and what the expected behavior was.</p>
          <p>Keep in mind that I am the sole developer on this project and I'm also a wife and mother, so please forgive me for any delays! I promise to do my best to get back to you ASAP. I usually am able to respond to emails within 24-48 hours.</p>
          <p>
            Thanks for playing! <br />- Karol
          </p>
          <p className="padding-vertical-xlg x-small-text">
            If you have issues submitting the form, feel free to just send an email to <a href="mailto:hello@letsplaybingo.io">hello@letsplaybingo.io</a>.
          </p>
        </div>
        <div className="col colspan3 padding-xlg">
          <form className="contact-form" name="contactletsplaybingo" method="post" data-netlify="true" netlify-honeypot="contactBotField" data-netlify-recaptcha="true">
            <input type="hidden" name="contactBotField" />
            <input type="hidden" name="form-name" value="contactletsplaybingo" />
            <input type="hidden" name="gameData" value={cacheData} />
            <input type="hidden" name="userAgent" value={userAgent} />
            <label className="margin-bottom-xlg">
              Name:
              <br />
              <input type="text" name="name" />
            </label>
            <label className="margin-bottom-xlg">
              Email:
              <br />
              <input type="email" name="email" />
            </label>
            <label className="margin-bottom-xlg">
              Message:
              <br />
              <textarea name="message"></textarea>
            </label>
            <div data-netlify-recaptcha="true"></div>
            <button aria-label="Submit" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
