import React from "react";
import BallDisplay from "./BallDisplay";
import { getColorTextLogo } from "../helpers/Utilities";

/**
 * PreviousCalls class
 * Renders a visual display of the previous calls
 * Props include:
 * balls - Array, contains ball objects
 * numToShow - Integer, how many previous calls to show, 1-5
 */
class PreviousCalls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  get ballsToShow() {
    if (this.props.settings.skipUnusedNumbers && this.props.settings.hideUnusedNumbers) {
      // traverse through the board and only return balls that should be shown.
      const balls = [];
      const unusedLetters = this.props.settings.pattern.unusedLetters;
      this.props.balls.forEach((ball) => {
        if (!unusedLetters.includes(ball.letter)) {
          balls.push(ball);
        }
      });

      return balls;
    } else {
      return this.props.balls;
    }
  }

  get modal() {
    const balls = [...this.ballsToShow].reverse();
    return this.state.showModal ? (
      <div>
        <div className="modal large-modal">
          <div className="modal-content">
            <h2>Call History</h2>
            <p>Check out the previous calls from most recent to oldest, left to right.</p>
            <button aria-label="Close" className="primary-button close-button" onClick={this.toggleModal}>
              X
            </button>
            <div className="row maintain-gutters ball-history justify-center">
              {balls.map((ball) => {
                return (
                  <div className="col shrink padding-md" key={ball.number}>
                    <BallDisplay ball={ball} currentCall={false} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="modal-backdrop" onClick={this.toggleModal}></div>
      </div>
    ) : null;
  }

  get previousCallsLabel() {
    let prevCallNum = this.ballsToShow.length > this.props.numToShow + 1 ? this.props.numToShow : this.ballsToShow.length - 1;
    let prevCallString = `Previous ${this.ballsToShow.length === 2 ? "Call" : prevCallNum + " Calls"}`;

    if (this.ballsToShow.length > 1) {
      return (
        <div className="row">
          <div className="col grow">
            <h2 className="uppercase-text-small">
              {prevCallString}{" "}
              <button aria-label="Show History" className="text-button" onClick={this.toggleModal}>
                show history
              </button>
            </h2>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    const balls = [...this.ballsToShow].reverse();
    let displayBalls = balls.slice(1, this.props.numToShow + 1);
    if (displayBalls.length > 0) {
      return (
        <div className="previous-call-block transparent-background">
          {this.modal}
          <div className={`row maintain-gutters align-end justify-center show-${this.props.numToShow}`}>
            {displayBalls.map((ball, index) => {
              return (
                <div className="col shrink padding-sm" key={ball.number}>
                  <BallDisplay ball={ball} currentCall={false} />
                </div>
              );
            })}
          </div>
          <div className="row align-end justify-center">{this.previousCallsLabel}</div>
        </div>
      );
    } else {
      return (
        <div className="previous-call-block transparent-background no-calls">
          <div className="row justify-center align-center">
            <div className="col grow padding-sm">
              <div className={balls.length === 1 ? "show" : "hide"}>{getColorTextLogo()}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PreviousCalls;
