import React from "react";
import { getLogo } from "../helpers/Utilities";
import { Link } from "react-router-dom";
// Components -----------------------------------------------
import Countdown from "../components/Countdown";
import BingoBoard from "../components/BingoBoard";
import CallInfo from "../components/CallInfo";
import Controls from "../components/Controls";
import CurrentCall from "../components/CurrentCall";
import CurrentPattern from "../components/CurrentPattern";
import PreviousCalls from "../components/PreviousCalls";

const Vertical = (props) => {
  return props.screenSize === "x-small" ? (
    <main className={props.screenSize + " " + props.layout}>
      <div className="row justify-start no-wrap gutters-md">
        <div className="col grow">
          <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
        </div>
        <div className="col shrink">
          <div className="home-link text-center margin-bottom-xlg">
            <Link to="/">{getLogo()}</Link>
          </div>
          <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
          <CurrentPattern
            disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
            eventName="patterndropdown"
            pattern={props.settings.pattern}
            rotatePatterns={props.rotatePatterns}
            showSelect={props.settings.showMainScreenPatternSelect}
            showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
            showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
          />
          <div class="padding-sm"></div>
          <CurrentCall ball={props.currentCall} showTitle={true} />
        </div>
      </div>
      <div className="row justify-start align-center no-wrap margin-vertical-md">
        <div className={props.settings.showCountdown ? "col shrink cmp-countdown" : "hide"}>
          <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
        </div>
        <div className="col grow cmp-controls">
          <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
        </div>
      </div>
      <div className="row">
        <div className="col full-width">
          <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="vertical" settings={props.settings} />
        </div>
      </div>
    </main>
  ) : (
    <main className={props.screenSize + " " + props.layout}>
      <div className="row justify-start gutters-lg">
        <div className="col">
          <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
        </div>
        <div className="col shrink">
          <div className="home-link text-center margin-bottom-xlg">
            <Link to="/">{getLogo()}</Link>
          </div>
          <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
          <CurrentPattern
            disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
            eventName="patterndropdown"
            pattern={props.settings.pattern}
            rotatePatterns={props.rotatePatterns}
            showSelect={props.settings.showMainScreenPatternSelect}
            showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
            showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
          />
          <div className={props.settings.showCountdown ? "" : "hide"}>
            <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
          </div>
          <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
        </div>
        <div className="col shrink">
          <CurrentCall ball={props.currentCall} showTitle={true} />
          <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="vertical" settings={props.settings} />
        </div>
      </div>
    </main>
  );
};
export default Vertical;
