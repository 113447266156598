export const numericalPatterns = [
  /* --------------------- # -------------------- */
  {
    value: "1 Corner Postage Stamp",
    label: "1 Corner Postage Stamp",
    otherNames: ["One Stamp", "One Corner Postage Stamp", "One Postage Stamp", "1 Stamp", "1 Postage Stamp"],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: "Bret Rice",
  },
  {
    value: "1K",
    label: "1K",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "10/2/4",
    label: "10/2/4",
    otherNames: ["Ten Two Four"],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, true, false, true],
      G: [true, false, false, true, true],
      O: [true, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Norma Davis",
  },
  {
    value: "2 Corner Postage Stamps",
    label: "2 Corner Postage Stamps",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, true, true],
        O: [true, true, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, false, true, true],
        I: [true, true, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: "JoAnn Elizabeth Reinhardt",
  },
  {
    value: "2 Lines - One Vertical, One Horizontal",
    label: "2 Lines - One Vertical, One Horizontal",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, true, true, true, true],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "2 Straight Lines",
    label: "2 Straight Lines",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, true, false, false, false],
      G: [false, true, false, false, false],
      O: [false, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [true, true, true, true, true],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [true, true, true, true, true],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [true, true, true, true, true],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: "Convivial Kendrea",
  },
  {
    value: "2K",
    label: "2K",
    otherNames: [],
    pattern: {
      B: [true, false, true, true, true],
      I: [true, true, false, false, true],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "3 Corner Postage Stamps",
    label: "3 Corner Postage Stamps",
    otherNames: ["Three Stamps, Three Postage Stamps", "Three Corner Postage Stamps", "3 Stamps", "3 Postage Stamps"],
    pattern: {
      B: [true, true, false, true, true],
      I: [true, true, false, true, true],
      N: [false, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, true, true],
        I: [true, true, false, true, true],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, true, true],
        O: [true, true, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [true, true, false, true, true],
        O: [true, true, false, true, true],
      },
    ],
    unusedLetters: ["N"],
    credit: "Bret Rice",
  },
  {
    value: "4 Corner Postage Stamps",
    label: "4 Corner Postage Stamps",
    otherNames: ["Four Stamps", "4 Stamps", "4 Postage Stamps", "Four Postage Stamps"],
    pattern: {
      B: [true, true, false, true, true],
      I: [true, true, false, true, true],
      N: [false, false, false, false, false],
      G: [true, true, false, true, true],
      O: [true, true, false, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "4 Corners + Free Space",
    label: "4 Corners + Free Space",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: "Princess B",
  },
  {
    value: "4 x 4 As Shown",
    label: "4 x 4 As Shown",
    otherNames: ["Four by Four As Shown"],
    pattern: {
      B: [true, true, true, true, false],
      I: [true, true, true, true, false],
      N: [true, true, true, true, false],
      G: [true, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "4 x 4 Anywhere",
    label: "4 x 4 Anywhere",
    otherNames: ["Four by Four Anywhere"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, true],
      N: [false, true, true, true, true],
      G: [false, true, true, true, true],
      O: [false, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, false],
        I: [true, true, true, true, false],
        N: [true, true, true, true, false],
        G: [true, true, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, false],
        N: [true, true, true, true, false],
        G: [true, true, true, true, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, true],
        N: [false, true, true, true, true],
        G: [false, true, true, true, true],
        O: [false, true, true, true, true],
      },
      {
        B: [false, true, true, true, true],
        I: [false, true, true, true, true],
        N: [false, true, true, true, true],
        G: [false, true, true, true, true],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "5 Around The Corner",
    label: "5 Around The Corner",
    otherNames: ["Five Around The Corner"],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "5k",
    label: "5k",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, true, true],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "6 Around The Corner",
    label: "6 Around The Corner",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "6 Pack As Shown",
    label: "6 Pack As Shown",
    otherNames: ["Six Pack As Shown"],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
  },
  {
    value: "6 Pack ",
    label: "6 Pack ",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "6 Pack Anywhere",
    label: "6 Pack Anywhere",
    otherNames: ["Six Pack Anywhere"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, true, true, true, false],
      O: [false, true, true, true, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, true, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "6 Pack Anywhere (Alternate)",
    label: "6 Pack Anywhere (Alternate)",
    otherNames: ["Six Pack Anywhere", "6 Pack Anywhere"],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [false, false, false, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "6 Pack Anywhere (No Free Space)",
    label: "6 Pack Anywhere (No Free Space)",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, true, true, true, false],
        O: [false, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, false],
        I: [false, true, true, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "7 - 11",
    label: "7 - 11",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "7 - 11 (Alternate)",
    label: "7 - 11 (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [true, true, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: "Alissa Wagoner",
  },
  {
    value: "7 On Top and Bottom",
    label: "7 On Top and Bottom",
    otherNames: ["Seven on Top and Bottom"],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, false, false],
      N: [false, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "8 Anywhere - No Free Space ",
    label: "8 Anywhere - No Free Space ",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, false, true, false, false],
      N: [false, true, false, true, false],
      G: [true, false, false, false, true],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, true],
        I: [true, false, true, false, false],
        N: [false, true, false, true, false],
        G: [true, false, false, false, true],
        O: [false, true, false, false, false],
      },
      {
        B: [true, false, false, true, false],
        I: [false, true, false, false, true],
        N: [false, false, false, true, false],
        G: [false, false, false, false, true],
        O: [true, false, true, false, false],
      },
      {
        B: [true, false, true, false, false],
        I: [false, false, false, true, false],
        N: [false, true, false, false, true],
        G: [false, false, true, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, true],
        O: [false, false, true, false, false],
      },
    ],
  },
  {
    value: "8 Pack As Shown",
    label: "8 Pack As Shown",
    otherNames: ["Eight Pack As Shown"],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, true, true],
      N: [false, false, false, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "8 Pack Anywhere",
    label: "8 Pack Anywhere",
    otherNames: ["Eight Pack Anywhere"],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, false, false],
      G: [true, true, false, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, true, true, true, true],
        I: [false, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, false],
        I: [true, true, true, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "9 Pack As Shown",
    label: "9 Pack As Shown",
    otherNames: ["Nine Pack As Shown"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "9 Pack Anywhere",
    label: "9 Pack Anywhere",
    otherNames: ["Nine Pack Anywhere"],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, true, true, false],
        G: [false, true, true, true, false],
        O: [false, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, true, true, true, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "9 Pack Corners",
    label: "9 Pack Corners",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
    ],
  },
  {
    value: "9 Pack Upper Right",
    label: "9 Pack Upper Right",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: ["B", "I"],
    credit: "Craig D Miller",
  },
  {
    value: "12 Pack As Shown",
    label: "12 Pack As Shown",
    otherNames: ["Twelve Pack As Shown"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, false],
      G: [true, true, true, true, false],
      O: [true, true, true, true, false],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "12 Pack Anywhere",
    label: "12 Pack Anywhere",
    otherNames: ["Twelve Pack Anywhere"],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, true, true, true, false],
      N: [false, true, true, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, false],
        G: [true, true, true, true, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, true, true, true],
        G: [false, true, true, true, true],
        O: [false, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, true],
        I: [false, true, true, true, true],
        N: [false, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, false],
        I: [true, true, true, true, false],
        N: [true, true, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, true, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, true, true, true, false],
        O: [false, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, true],
        N: [false, true, true, true, true],
        G: [false, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, false],
        N: [true, true, true, true, false],
        G: [true, true, true, true, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
];
