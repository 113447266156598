import React from "react";
import { NavLink } from "react-router-dom";
import Pattern from "../components/Pattern";
import Toggle from "../components/Toggle";
import { isEqual, toProperCase } from "../helpers/Utilities";
import { getBlankPattern, getPresetPatterns } from "../helpers/PresetPatterns";

class SubmitAPattern extends React.Component {
  constructor(props) {
    super(props);
    this.presets = getPresetPatterns();
    this.state = {
      loading: false,
      buttonDisabled: true,
      name: null,
      email: null,
      patternData: { ...this.presets[0] },
      patternString: "",
      patternName: null,
      creditMe: false,
      error: "",
      hasRotation: false,
    };
    this.customPatternString = JSON.stringify(this.presets[0]);
  }

  componentDidMount() {
    document.title = "Let's Play Bingo! | Submit a Pattern";
    document.addEventListener("custompattern", this.handleCustomPattern);
    document.addEventListener("creditme", this.handleCreditMe);
    document.addEventListener("patternhasrotation", this.handlePatternRotation);
    document.addEventListener("alternativepattern", this.handleAlternativePattern);
  }

  componentWillUnmount() {
    document.removeEventListener("customPattern", this.handleCustomPattern);
    document.removeEventListener("creditme", this.handleCreditMe);
    document.removeEventListener("patternhasrotation", this.handlePatternRotation);
    document.removeEventListener("alternativepattern", this.handleAlternativePattern);
  }

  addPattern = (e) => {
    let newState = { ...this.state };
    if (Object.prototype.hasOwnProperty.call(this.state.patternData, "winningPatterns")) {
      newState.patternData.winningPatterns.push(getBlankPattern().pattern);
    } else {
      newState.patternData.winningPatterns = [getBlankPattern().pattern];
    }
    this.setState(newState);
  };

  handleCustomPattern = (e) => {
    let newState = { ...this.state };
    newState.buttonDisabled = false;
    newState.patternData = { ...e.detail };
    if (Object.prototype.hasOwnProperty.call(this.state.patternData, "winningPatterns")) {
      newState.patternData.winningPatterns = [...this.state.patternData.winningPatterns];
    }
    newState.error = "";
    this.setState(newState);
  };

  handleCreditMe = (e) => {
    e.preventDefault();
    let newState = { ...this.state };
    newState.buttonDisabled = false;
    newState.error = "";
    newState.creditMe = e.detail.value;
    this.setState(newState);
  };

  handlePatternRotation = (e) => {
    e.preventDefault();
    let newState = { ...this.state };
    newState.buttonDisabled = false;
    newState.error = "";
    newState.hasRotation = e.detail.value;
    if (e.detail.value === true) {
      newState.patternData.winningPatterns = [getBlankPattern().pattern];
    } else {
      delete newState.patternData.winningPatterns;
    }
    this.setState(newState);
  };

  handleAlternativePattern = (e) => {
    let newState = { ...this.state };
    newState.buttonDisabled = false;
    newState.error = "";
    const patternData = { ...e.detail };
    newState.patternData.winningPatterns[e.detail.index] = patternData.pattern;
    this.setState(newState);
  };

  handleInput = (e) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    const field = e.currentTarget.name;
    let newState = { ...this.state };
    newState.buttonDisabled = false;
    newState.error = "";
    newState[field] = field === "patternName" ? toProperCase(value) : value;
    this.setState(newState);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let errors = [];
    const blankPattern = getBlankPattern();

    let patternData = {
      value: (" " + this.state.patternName).slice(1),
      label: (" " + this.state.patternName).slice(1),
      otherNames: [],
      pattern: { ...this.state.patternData.pattern },
      unusedLetters: [...this.state.patternData.unusedLetters],
      credit: this.state.creditMe === true ? this.state.name : null,
    };

    if (Object.prototype.hasOwnProperty.call(this.state.patternData, "winningPatterns")) {
      patternData.winningPatterns = [...this.state.patternData.winningPatterns];
    }

    // Evaluate for errors --------------------------------
    if (isEqual(blankPattern.pattern, patternData.pattern)) {
      errors.push("You must draw a pattern first.");
    } else {
      this.presets.forEach((preset) => {
        if (isEqual(preset.pattern, patternData.pattern) && preset.value !== "Custom") {
          errors.push(`This pattern exists, you can find it in the patterns page as "${preset.label}".`);
          patternData.otherNames.push(preset.label);
        }
      });
    }

    if (!this.state.patternName) {
      errors.push("You must give your pattern a name.");
    }

    if (this.state.creditMe === true && !this.state.name) {
      errors.push("If you'd like credit you must provide your name.");
    }

    // If there are errors, display them - otherwise submit the form. -------
    if (errors.length > 0) {
      this.setState({ error: errors.join("\r\n"), buttonDisabled: true });
    } else {
      this.setState({ error: "", buttonDisabled: true, patternData: patternData, patternString: JSON.stringify(patternData), loading: true });
      setTimeout(() => {
        document.querySelector("form").submit();
      }, 500);
    }
  };

  render() {
    return (
      <section>
        <div className="row container justify-start">
          <div className="col full-width wrap-text">
            <h1>
              Submit a Pattern
              <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                <button aria-label="Back to Game" className="primary-button">
                  Back to Game
                </button>
              </NavLink>
            </h1>
            <p>
              Have ideas for new patterns? You can now submit pattern ideas to have them added to the <NavLink to="/patterns">Patterns page</NavLink>!
            </p>
          </div>
        </div>
        <div className="row container">
          <div className="col colspan8 padding-xlg">
            <div className="pattern-form">
              <div className="row gutters-lg">
                <div className="col shrink">
                  <Pattern rotate={false} editable={true} pattern={this.state.patternData} />
                  <Toggle eventName="patternhasrotation" label="Pattern has more than 1 way to win" name="rotation" value={this.state.hasRotation} />
                </div>
                <div className="col grow word-wrap">
                  <form className="pattern-form-inner margin-top-lg" name="newbingopattern" method="post" data-netlify="true" netlify-honeypot="patternBotField" data-netlify-recaptcha="true">
                    <input id="patternBotField" type="hidden" name="patternBotField" />
                    <input id="submitapattern" type="hidden" name="form-name" value="newbingopattern" />
                    <input id="creditmefield" type="hidden" name="creditMe" value={this.state.creditMe} />
                    <input id="patterndatafield" type="hidden" name="patternData" value={this.state.patternString} />
                    <label className="margin-bottom-xlg">
                      Pattern Name
                      <br />
                      <input id="patternnamefield" type="text" name="patternName" value={this.state.patternName} onChange={this.handleInput} />
                    </label>
                    <label className="margin-bottom-xlg">
                      Your Name (Optional)
                      <br />
                      <input id="namefield" type="text" name="name" value={this.state.name} onChange={this.handleInput} />
                    </label>
                    <label className="margin-bottom-xlg">
                      Your Email (Optional)
                      <br />
                      <input id="emailfield" type="email" name="email" value={this.state.email} onChange={this.handleInput} />
                    </label>
                    <Toggle eventName="creditme" label="Please credit me on the site" name="creditMe" value={this.state.creditMe} />
                    <div data-netlify-recaptcha="true"></div>
                  </form>
                </div>
              </div>
              {this.state.hasRotation === true ? (
                <div className="row gutters-md align-end justify-start padding-vertical-lg additional-patterns">
                  <div className="col full-width">
                    <h3>
                      Enter additional winning patterns below.
                      <button aria-label="Add an additional winning pattern" disabled={false} onClick={this.addPattern}>
                        <i className="fa fa-solid fa-plus"></i>
                      </button>
                    </h3>
                  </div>
                  {Object.prototype.hasOwnProperty.call(this.state.patternData, "winningPatterns") && this.state.patternData.winningPatterns.length > 0
                    ? this.state.patternData.winningPatterns.map((winningPattern, index) => {
                        return (
                          <div className="col">
                            <p>Winning Pattern {index + 2}</p>
                            <Pattern rotate={false} editable={true} eventName="alternativepattern" index={index} pattern={winningPattern} />
                          </div>
                        );
                      })
                    : null}
                </div>
              ) : null}
              <div className="row gutters-md no-wrap align-end justify-end padding-top-lg">
                {this.state.error ? (
                  <div className="col grow">
                    <div className="error">{this.state.error}</div>
                  </div>
                ) : this.state.loading ? (
                  <div className="spinner">
                    <i className="fa fa-spinner"></i>
                  </div>
                ) : null}
                <div className="col shrink">
                  <button aria-label="Submit" disabled={this.state.buttonDisabled} onClick={this.handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col colspan4 wrap-text padding-xlg">
            <p>Simply click on the card to mark spaces and create your pattern then give it a unique name! If the pattern exists already you'll see a message with the name you can find the pattern under.</p>
            <p>If you'd like you can receive credit for your submission with your name being displayed below the pattern you sent in!</p>
            <p className="x-small-text">
              Submitted patterns are manually reviewed and added in batches. If they are inappropriate they are discarded. If your submitted pattern does not display within 2 weeks of submission chances are it was rejected due to content, being a duplicate or there was a problem with your submission. If you are sure your pattern should be included you can submit it again or <NavLink to="/patterns">contact me</NavLink> to find out why it wasn't added.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default SubmitAPattern;
