import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { checkCacheForPropertyValue } from "../helpers/CacheManagement";
import Header from "../components/Header";
import Footer from "../components/Footer";

// ----- Pages
import About from "../pages/About";
import Caller from "../pages/Caller";
import Contact from "../pages/Contact";
import Generator from "../pages/Generator";
import Help from "../pages/Help";
import Patterns from "../pages/Patterns";
import Play from "../pages/Play";
import Privacy from "../pages/Privacy";
import ReleaseNotes from "../pages/ReleaseNotes";
import SubmitAPattern from "../pages/SubmitAPattern";
import Terms from "../pages/Terms";
import Thanks from "../pages/Thanks";

class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorScheme: "dark",
      theme: "lpb",
      hideFooter: false,
    };
    const cachedSettings = checkCacheForPropertyValue("settings");
    if (cachedSettings !== null) {
      if (Object.prototype.hasOwnProperty.call(cachedSettings, "theme")) {
        this.state.theme = cachedSettings.theme;
      }
      if (Object.prototype.hasOwnProperty.call(cachedSettings, "hideFooter")) {
        this.state.hideFooter = cachedSettings.hideFooter;
      }
    }

    const colorScheme = localStorage.getItem("lpb-colorscheme");
    if (colorScheme) {
      this.state.colorScheme = colorScheme;
    }
    document.addEventListener("settingssaved", this.handleSettingsSaved);
    document.addEventListener("togglecolorscheme", this.toggleColorScheme);
  }

  componentWillUnmount() {
    document.removeEventListener("settingssaved", this.handleSettingsSaved);
    document.removeEventListener("togglecolorscheme", this.toggleColorScheme);
  }

  toggleColorScheme = (event) => {
    localStorage.setItem("lpb-colorscheme", event.detail);
    this.setState({ colorScheme: event.detail });
  };

  handleSettingsSaved = (event) => {
    const newSettings = event.detail;
    this.setState({
      theme: newSettings.theme,
      hideFooter: newSettings.hideFooter,
    });
  };

  render() {
    return (
      <div className={`body-container ${this.state.theme} ${this.state.colorScheme}-scheme`}>
        <BrowserRouter>
          <Header colorScheme={this.state.colorScheme} />
          <Routes>
            <Route path="/about" element={<About />} />
            <Route exact path="/" element={<Caller />} />
            <Route path="/generator" element={<Generator />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/help" element={<Help />} />
            <Route path="/patterns" element={<Patterns />} />
            <Route path="/submitpattern" element={<SubmitAPattern />} />
            <Route path="/play" element={<Play />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/releases" element={<ReleaseNotes />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/thanks" element={<Thanks />} />
          </Routes>
          <Footer hideFooter={this.state.hideFooter} />
        </BrowserRouter>
      </div>
    );
  }
}

export default AppRoutes;
