export const dPatterns = [
  /* --------------------- D -------------------- */
  {
    value: "Daisy",
    label: "Daisy",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diagonal",
    label: "Diagonal",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N"],
    credit: "Nija",
  },
  {
    value: "Diagonal (Alternate)",
    label: "Diagonal (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: "Jake",
  },
  {
    value: "Diamond",
    label: "Diamond",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [true, false, false, false, true],
      G: [false, true, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond (Alternate)",
    label: "Diamond (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Diamond Burst",
    label: "Diamond Burst",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, false, false, true],
      G: [false, true, false, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond Filled",
    label: "Diamond Filled",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond Inside",
    label: "Diamond Inside",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, false, false],
      N: [false, true, true, true, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Dice 5",
    label: "Dice 5",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "Dog Bone",
    label: "Dog Bone",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Dollar Bill",
    label: "Dollar Bill",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, true, false, true, false],
      N: [false, true, true, true, false],
      G: [false, true, false, true, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dollar Sign",
    label: "Dollar Sign",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dollar Sign (Alternate)",
    label: "Dollar Sign (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Door",
    label: "Door",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, true, false, true, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Eliza",
  },
  {
    value: "Double C",
    label: "Double C",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [false, false, false, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: "Michelle Langer",
  },
  {
    value: "Double Frame",
    label: "Double Frame",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: "Michelle Langer",
  },
  {
    value: "Dos Bingo",
    label: "Dos Bingo",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double Bingo",
    label: "Double Bingo",
    otherNames: ["Two Bingos", "2 Bingos"],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, false, false, true, false],
        I: [false, true, false, true, false],
        N: [false, false, true, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, true],
        I: [false, false, true, true, false],
        N: [false, false, true, false, false],
        G: [false, true, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [true, true, true, true, true],
        O: [false, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double Chevron",
    label: "Double Chevron",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, true, false, false],
      G: [false, true, false, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double 6 Pack",
    label: "Double 6 Pack",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [false, false, false, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [true, true, false, true, true],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double L",
    label: "Double L",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [false, false, false, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Craig D Miller",
  },
  {
    value: "Double Triple Dot",
    label: "Double Triple Dot",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: "Alissa Wagoner",
  },
  {
    value: "Double X",
    label: "Double X",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, false, true, false, true],
      G: [false, false, false, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Down",
    label: "Down",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, true, false],
      G: [true, true, true, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Convivial Kendrea",
  },
  {
    value: "Dragonfly",
    label: "Dragonfly",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, true, true],
      G: [false, true, false, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: "Adrienne",
  },
  {
    value: "Dragonfly (Alternate)",
    label: "Dragonfly (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [true, true, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dragonfly (Alternate 2)",
    label: "Dragonfly (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [false, true, true, false, false],
      N: [true, true, true, true, true],
      G: [false, true, true, false, false],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
];
