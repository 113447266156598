import React from "react";
/**
 * Countdown class
 * Renders a visual countdown display to the next automatic call
 * Props include:
 * delay - Integer, the delay between calls
 * call - Object, the current ball object
 * running - Boolean, indicates if the game is currently running
 */
class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.timer;
    this.state = { tick: props.delay, call: props.call, manualTimer: false };
  }

  componentDidMount() {
    document.addEventListener("start-manual-countdown", this.startTimer);
    document.addEventListener("stop-manual-countdown", this.stopTimer);
  }

  componentDidUpdate() {
    clearInterval(this.timer);
    const props = this.props;
    if (this.state.tick === 0) {
      this.setState({ tick: props.delay, manualTimer: false });
    } else {
      if (props.running || this.state.manualTimer) {
        this.timer = setInterval(
          () =>
            this.setState({
              tick: this.state.tick === 0 ? 0 : this.state.tick - 1,
              call: props.call,
            }),
          1000,
        );
      }
      if (props.call !== this.state.call) {
        this.setState({
          call: props.call,
          tick: props.delay,
        });
      }
    }
  }

  stopTimer = () => {
    this.setState({ manualTimer: false, tick: this.props.delay });
    clearInterval(this.timer);
  };

  startTimer = () => {
    this.setState({ manualTimer: true });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    document.removeEventListener("start-manual-countdown", this.startTimer);
    document.removeEventListener("stop-manual-countdown", this.stopTimer);
  }

  render() {
    return (
      <div className={"countdown transparent-background" + (this.props.running || this.state.manualTimer ? " flashing" : "")}>
        <span className="uppercase-text-small">NEXT CALL IN</span>
        <br />
        <span className="tick">{this.props.running || this.state.manualTimer ? this.state.tick : "—"}</span>
        <br />
        <span className="uppercase-text-small">SECONDS</span>
      </div>
    );
  }
}

export default Countdown;
