export const nPatterns = [
  /* --------------------- N -------------------- */
  {
    value: "N",
    label: "N",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "I", "G", "O"],
    credit: null,
  },
  {
    value: "NO",
    label: "NO",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B", "I", "G"],
    credit: null,
  },
  {
    value: "No Sign",
    label: "No Sign",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, true, true],
      N: [true, false, true, false, true],
      G: [true, true, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Number 1",
    label: "Number 1",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Number 2",
    label: "Number 2",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [false, true, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Number 3",
    label: "Number 3",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Number 3 (Alternate)",
    label: "Number 3 (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
  },
  {
    value: "Number 4",
    label: "Number 4",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "O"],
    credit: null,
  },
  {
    value: "Number 5",
    label: "Number 5",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Number 6",
    label: "Number 6",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Number 7",
    label: "Number 7",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Number 8",
    label: "Number 8",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Number 9",
    label: "Number 9",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Number 9 (Alternate)",
    label: "Number 9 (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, false, false],
      N: [true, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Number 10",
    label: "Number 10",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 11",
    label: "Number 11",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["I", "G", "O"],
    credit: null,
  },
  {
    value: "Number 11 (Alternate)",
    label: "Number 11 (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Number 12",
    label: "Number 12",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, false, true, true, true],
      G: [true, false, true, false, true],
      O: [true, true, true, false, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 13",
    label: "Number 13",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 14",
    label: "Number 14",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, false, false],
      G: [false, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 15",
    label: "Number 15",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 16",
    label: "Number 16",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 17",
    label: "Number 17",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 18",
    label: "Number 18",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number 19",
    label: "Number 19",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, false, false],
      G: [true, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Number Sign",
    label: "Number Sign",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [true, true, true, true, true],
      N: [false, true, false, true, false],
      G: [true, true, true, true, true],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
];
