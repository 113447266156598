import { numericalPatterns } from "./patterns/numerical";
import { aPatterns } from "./patterns/a";
import { bPatterns } from "./patterns/b";
import { cPatterns } from "./patterns/c";
import { dPatterns } from "./patterns/d";
import { ePatterns } from "./patterns/e";
import { fPatterns } from "./patterns/f";
import { gPatterns } from "./patterns/g";
import { hPatterns } from "./patterns/h";
import { iPatterns } from "./patterns/i";
import { jPatterns } from "./patterns/j";
import { kPatterns } from "./patterns/k";
import { lPatterns } from "./patterns/l";
import { mPatterns } from "./patterns/m";
import { nPatterns } from "./patterns/n";
import { oPatterns } from "./patterns/o";
import { pPatterns } from "./patterns/p";
import { qPatterns } from "./patterns/q";
import { rPatterns } from "./patterns/r";
import { sPatterns } from "./patterns/s";
import { tPatterns } from "./patterns/t";
import { uPatterns } from "./patterns/u";
import { vPatterns } from "./patterns/v";
import { wPatterns } from "./patterns/w";
import { xPatterns } from "./patterns/x";
import { yPatterns } from "./patterns/y";
import { zPatterns } from "./patterns/z";
/**
 * Returns a list of preset patterns
 *
 * @var  {Object}
 */
export function getPresetPatterns() {
  let allPatterns = [
    {
      value: "Custom",
      label: "Custom",
      otherNames: [],
      pattern: {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      unusedLetters: [],
      credit: null,
    },
  ];
  allPatterns = allPatterns.concat(numericalPatterns, aPatterns, bPatterns, cPatterns, dPatterns, ePatterns, fPatterns, gPatterns, hPatterns, iPatterns, jPatterns, kPatterns, lPatterns, mPatterns, nPatterns, oPatterns, pPatterns, qPatterns, rPatterns, sPatterns, tPatterns, uPatterns, vPatterns, wPatterns, xPatterns, yPatterns, zPatterns);
  return allPatterns;
}

/**
 * Returns the pattern data for the given pattern name
 * If one does not exist, returns a blank pattern
 *
 * @param {String} patternName
 * @returns {Object} pattern
 */
export function getPatternInfo(patternName) {
  const presets = getPresetPatterns();
  let returnValue = null;
  presets.forEach((preset) => {
    if (preset.value === patternName) {
      returnValue = preset;
    }
  });
  if (returnValue === null) {
    returnValue = getBlankPattern();
  }
  return returnValue;
}

/**
 * Returns a default blank pattern
 * @returns {Object} pattern
 */
export function getBlankPattern() {
  return {
    value: "blank",
    label: "Select One",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  };
}
