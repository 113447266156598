export const kPatterns = [
  /* --------------------- K -------------------- */
  {
    value: "Key",
    label: "Key",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: "Katie Steulke",
  },
  {
    value: "Kite",
    label: "Kite",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Kite (Alternate)",
    label: "Kite (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
];
