import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Thanks() {
  useEffect(() => {
    document.title = "Let's Play Bingo! | Thanks!";
  });

  return (
    <section className="thanks-page">
      <div className="container row">
        <div className="col">
          <h1 className="no-margin">
            Thanks for your submission!
            <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
              <button aria-label="Back to Game" className="primary-button">
                Back to Game
              </button>
            </NavLink>
          </h1>
          <p>Your pattern submission has been received. If it is a valid pattern it will be added within the next 2 weeks. Please check back to see if it's been added!</p>
        </div>
      </div>
    </section>
  );
}
