export const cPatterns = [
  /* --------------------- C -------------------- */
  {
    value: "Cactus",
    label: "Cactus",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Cake Filling",
    label: "Cake Filling",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [false, true, false, true, false],
      N: [false, true, false, true, false],
      G: [false, true, false, true, false],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: 'Cancer and 2nd Pattern "Reverse Contrast Cancer"',
    label: 'Cancer and 2nd Pattern "Reverse Contrast Cancer"',
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, false, true],
      N: [true, false, false, true, false],
      G: [false, true, true, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: "Paul Van Berkel",
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, true, true, false, true],
        N: [true, false, false, true, false],
        G: [false, true, true, false, true],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, true, false],
        N: [false, true, true, false, true],
        G: [true, false, false, true, false],
        O: [true, true, true, true, true],
      },
    ],
  },
  {
    value: "Candlestick",
    label: "Candlestick",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, true],
      N: [true, true, true, true, true],
      G: [false, false, true, false, true],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Candy Cane",
    label: "Candy Cane",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, false, false, false],
      N: [true, false, false, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Denise S",
  },
  {
    value: "Carton Lleno",
    label: "Carton Lleno",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, false, true, true],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Andres",
  },
  {
    value: "Cent Sign",
    label: "Cent Sign",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [true, true, false, true, true],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Center",
    label: "Center",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [false, false, false, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Champagne Glass",
    label: "Champagne Glass",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, true, false, false, true],
      N: [true, true, true, true, true],
      G: [true, true, false, false, true],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Champagne Glass (Alternate)",
    label: "Champagne Glass (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, false, true],
      N: [false, false, true, true, true],
      G: [false, true, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Charity",
    label: "Charity",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Checkerboard",
    label: "Checkerboard",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, true, false, true],
      G: [false, true, false, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Checkmark",
    label: "Checkmark",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Chevron",
    label: "Chevron",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, false, false, false, false],
      G: [false, true, false, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Chevron - Reversed",
    label: "Chevron - Reversed",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
    ],
  },
  {
    value: "Chihuahua",
    label: "Chihuahua",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, true, true, false, false],
      N: [false, true, true, true, false],
      G: [false, true, true, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Jeremey Brooks",
  },
  {
    value: "Christmas Tree",
    label: "Christmas Tree",
    otherNames: ["Sapin de noel"],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, false, false],
      N: [true, true, true, true, true],
      G: [false, true, true, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Christmas Tree (Alternate)",
    label: "Christmas Tree (Alternate)",
    otherNames: ["Sapin de noel"],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, true, true, false],
    },
    unusedLetters: [],
    credit: "Bret Rice",
  },
  {
    value: "Clock I",
    label: "Clock I",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, true, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Clock II",
    label: "Clock II",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Clock III",
    label: "Clock III",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, true, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Clock IV",
    label: "Clock IV",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Clover",
    label: "Clover",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, false, true, false],
      N: [true, false, true, true, true],
      G: [true, true, false, true, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Clover Leaf",
    label: "Clover Leaf",
    otherNames: [],
    pattern: {
      B: [true, true, false, true, true],
      I: [true, true, false, true, true],
      N: [false, false, true, false, false],
      G: [true, true, false, true, true],
      O: [true, true, false, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Cocktail",
    label: "Cocktail",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, true, true, true],
      N: [false, true, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Coffee Mug",
    label: "Coffee Mug",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, true, false, true, false],
      N: [false, true, true, true, true],
      G: [false, true, true, true, true],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Corcho",
    label: "Corcho",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Corners",
    label: "Corners",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, true, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "Corners and Cross",
    label: "Corners and Cross",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Coverall",
    label: "Coverall",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy 7",
    label: "Crazy 7",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [true, false, false, true, false],
        N: [true, false, true, false, false],
        G: [true, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, true],
        G: [false, true, false, false, true],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [true, true, true, true, true],
      },
    ],
  },
  {
    value: "Crazy Arrow",
    label: "Crazy Arrow",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, true, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, false, false, false],
        N: [true, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [true, false, true, false, false],
        G: [true, true, false, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, true],
        G: [false, false, false, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, true],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy Arrowhead",
    label: "Crazy Arrowhead",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, false, false, false],
        N: [true, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, true],
        G: [false, false, false, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy Bowtie",
    label: "Crazy Bowtie",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, true, true],
      N: [false, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: "Alissa Wagoner",
  },
  {
    value: "Crazy Chevron",
    label: "Crazy Chevron",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, true, false, false],
        I: [false, true, false, true, false],
        N: [true, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, true, false, false, false],
        N: [true, false, false, false, false],
        G: [false, true, false, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, false, false, true],
        G: [false, true, false, true, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, false, true, false],
        N: [false, false, false, false, true],
        G: [false, false, false, true, false],
        O: [false, false, true, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy Chevron (Alternate)",
    label: "Crazy Chevron (Alternate)",
    otherNames: [],
    pattern: { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, true, false, false], G: [false, true, false, true, false], O: [true, false, false, false, true] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, false, true], I: [false, true, false, true, false], N: [false, false, true, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
    ],
  },
  {
    value: "Crazy Kite",
    label: "Crazy Kite",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, true, true],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Crazy Kite (Alternate)",
    label: "Crazy Kite (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, true],
      O: [true, false, false, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, true],
        I: [true, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, true],
        O: [true, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Crazy L",
    label: "Crazy L",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy Large Kite ",
    label: "Crazy Large Kite ",
    otherNames: ["Large Wild Kite"],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, false, true, true, true],
      N: [false, false, true, true, true],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, true, true, true],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, true, true],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [true, true, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy T",
    label: "Crazy T",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, true, true, true, true],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crazy U",
    label: "Crazy U",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, false, false, false, true],
      },
    ],
  },
  {
    value: "Crazy V",
    label: "Crazy V",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: "Michael Mixell ",
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, false, false],
        N: [false, true, false, true, false],
        G: [true, false, false, false, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, true, false, true, false],
        N: [true, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Croquet",
    label: "Croquet",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, true, true, true, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["G"],
    credit: null,
  },
  {
    value: "Cross",
    label: "Cross",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, true, true],
      G: [false, true, false, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Crossflags",
    label: "Crossflags",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, false, true, false],
      N: [false, false, true, false, false],
      G: [true, true, false, true, false],
      O: [true, true, false, false, true],
    },
    unusedLetters: ["N"],
    credit: "Alissa Wagoner",
  },
  {
    value: "Cross and Frame",
    label: "Cross and Frame",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Cross Saber",
    label: "Cross Saber",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, true],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Cross Stitch",
    label: "Cross Stitch",
    otherNames: ["Petite Croix"],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, true, false, false],
      N: [false, true, true, true, false],
      G: [false, false, true, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Cupids Arrow",
    label: "Cupids Arrow",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, false, true],
      N: [false, false, true, true, true],
      G: [false, false, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
