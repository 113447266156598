import React from "react";
import { Link } from "react-router-dom";
import { dispatchCustomEvent } from "../helpers/Utilities";

/**
 * MiniUpdates
 *
 * @param   {Object}  props  Includes property for gamedata used to generate bug reports
 *
 * @return  {JSX}         Returns a div that includes update information shown in the settings panel
 */
export default function MiniUpdates(props) {
  return (
    <div>
      <h4>Updates</h4>
      <div className="x-small-text">
        <p>
          <strong>Version: </strong>4.5.2
          <span className="padding-horizontal-sm">|</span>
          <strong>Released: </strong>6.1.2023
          <span className="padding-horizontal-sm">|</span>
          <strong>Updated: </strong>5.8.2024
          <br />
        </p>
        <p>Hey Bingo Players! I fell behind on updates for the last few months, but I am back and responding to emails and getting patterns added! Please be patient!</p>
        <ul>
          <li>Fixed an issue with the Torch pattern where Bs and Os were being called when they should be skipped.</li>
          <li>New patterns submitted up to 5/8/2024 have been added.</li>
          <li>
            Added a link to the{" "}
            <a href="http://letsplaybingo.store/" target="_blank" rel="noopener noreferrer">
              Let's Play Bingo Store
            </a>
            !
          </li>
          <li>Brand new branding in honor of the upcoming 7th anniversary of Let's Play Bingo!</li>
        </ul>
        <p>
          See more updates in the{" "}
          <Link
            to="/releases"
            onClick={() => {
              dispatchCustomEvent("menu-open", true);
            }}>
            Release Notes
          </Link>
        </p>
        <p>
          <strong>Need to report a bug?</strong> Please use the{" "}
          <Link
            to="/contact"
            onClick={() => {
              dispatchCustomEvent("menu-open", true);
            }}>
            contact page
          </Link>
          !
        </p>
      </div>
    </div>
  );
}
