export const uPatterns = [
  /* --------------------- U -------------------- */
  {
    value: "Ultimate Spider Web",
    label: "Ultimate Spider Web",
    otherNames: ["Spider Web"],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, true, true, false],
      N: [true, true, false, true, true],
      G: [false, true, true, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Umbrella",
    label: "Umbrella",
    otherNames: [],
    pattern: {
      B: [false, true, true, false, false],
      I: [true, true, false, false, true],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Unity",
    label: "Unity",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [true, true, true, true, false],
      N: [false, true, false, false, true],
      G: [true, true, true, true, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Up",
    label: "Up",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, true, true, true],
      N: [false, true, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Convivial Kendrea",
  },
  {
    value: "Upside Down T",
    label: "Upside Down T",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Upside Down U",
    label: "Upside Down U",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
