import React from "react";
import { dispatchCustomEvent } from "../helpers/Utilities";
import HelperText from "./HelperText";

/**
 * Input class
 * Renders a standard input element
 *
 * Properties include:
 * disabled - boolean, indicates if the select should be disabled
 * eventName - string, the custom event name that will be fired upon input
 * heading - string, the heading shown above the input
 * info - string, info text displayed in <HelperText /> if present
 * label - string, the label of the input
 * name - string, the name of the input
 * show - boolean, indicates if the input should display
 * value - string, the value that has been entered
 */
export default function Input(props) {
  function handleInput(event) {
    let settingInfo = {};
    settingInfo.property = props.name;
    settingInfo.value = event.target.value;
    dispatchCustomEvent(props.eventName, settingInfo);
  }

  function getHelperText() {
    return props.info ? <HelperText text={props.info} position={props.infoPosition} /> : null;
  }

  if (!Object.prototype.hasOwnProperty.call(props, "show") || props.show === true) {
    return (
      <div className="setting-inner">
        {props.heading ? <h5>{props.heading}</h5> : null}
        <label for={props.name}>
          <span className="label-text">{props.label}</span>
          {getHelperText()}
        </label>
        <input type="number" id={props.name} className="custom-input" max="500" name={props.name} value={props.value} onChange={handleInput} />
      </div>
    );
  } else {
    return null;
  }
}
