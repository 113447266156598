import React from "react";
import { Link, NavLink } from "react-router-dom";
/**
 * Renders the help page
 */
class Help extends React.Component {
  /**
   * When the component mounts, enable some nice smooth scrolling
   * for the anchors on the page
   */
  componentDidMount() {
    document.title = "Let's Play Bingo! | Help";
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }

  /**
   * Renders the JSX for the page
   *
   * @return  {JSX}
   */
  render() {
    return (
      <section>
        <div className="container row">
          <div id="top" className="col padding-bottom-xxlg">
            <div className="back-to-top">
              <a href="#top">&#10094;</a>
            </div>
            <h1 className="no-margin">
              Instructions for <span className="notranslate">Let's Play Bingo</span>
              <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                <button aria-label="Back to Game" className="primary-button">
                  Back to Game
                </button>
              </NavLink>
            </h1>
          </div>
        </div>
        <div className="row help-content container gutters-xlg maintain-gutters">
          <div className="col colspan6 padding-top-lg">
            {/* --------------- How do I use LPB? --------------- */}
            <div>
              <h2 id="what-is-lpb">
                What is <span className="notranslate">Let's Play Bingo</span>?
              </h2>
              <p>
                <span className="notranslate padding-right-xsm">Let's Play Bingo</span> is a tool intended to be used for playing Bingo in a group setting - this can be either in person or online through virtual meetings with screen sharing.
              </p>
              <p className="small-text">
                <strong>Note:</strong> This app does not make use of a database. This means that the generated cards, game data, and settings are <strong>not saved</strong>. The application makes use of what is called "local storage" on the user's computer (similar to a cookie) to temporarily save game settings and game data. <strong>Cards are never stored and you cannot access the same game across multiple computers/with other people.</strong> So in order to play with others, you have to have
                one designated person running the game for all players and sharing their screen so that the players can see the game board.
              </p>
              <p className="small-text warning-text">
                <em>
                  This app is <strong>not</strong> intended for gambling purposes and any perceived losses are solely the responsibility of the party/parties using this application for those purposes.
                </em>
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Game Play --------------- */}
            <div>
              <h3 id="gameplay">How do I play?</h3>
              <p>If you have ever played Bingo before, this application should be pretty self explanatory. I've done my best to mimic what you experience in the bingo halls as closely as possible. If you still need help, please reference the guide below for how to use this app for hosting your own bingo games!</p>

              <section className="margin-top-xxlg padding-top-md"></section>

              <h4 id="game-setup">Game Setup</h4>
              <ol>
                <li>
                  Choose someone to be the caller to control the game.
                  <ul className="small-text">
                    <li>This person will manage game settings, handle calling the numbers, starting/stopping autoplay, and reset the game as necessary when someone calls bingo.</li>
                  </ul>
                </li>
                <li>
                  Ensure all players have cards to play with.
                  <ul className="small-text">
                    <li>
                      If playing over screen share, players can play along on their computers using the <Link to="/play">Play</Link> page.
                    </li>
                    <li>
                      The caller or players can use the <Link to="/generator">Card Generator</Link> to print cards. You can also choose to print patterns on the cards and even hide unused numbers on printed cards for easier game play!
                    </li>
                  </ul>
                </li>
                <li>Choose what settings you wish to play with. All game settings are detailed below.</li>
                <li>
                  The caller should share their screen so all players can see the board.
                  <ul className="small-text">
                    <li>If playing in person consider mirroring your screen to a television or other display by using a device such as ChromeCast, AppleTV, Roku, etc.</li>
                    <li>If playing virtually, use the screen share option available in your meeting application of choice. Examples of apps to use include Zoom, Google Meet, Microsoft Teams, Skype, etc. Please refer to the application's documentation for how to share your screen in that particular app.</li>
                  </ul>
                </li>
              </ol>

              <section className="margin-top-xxlg padding-top-md"></section>

              <h4 id="pattern-selection">Game Pattern Selection</h4>
              <p>On the screen, you'll see a bingo card. This is meant to show players what game pattern they need to make to get a bingo. There are a few ways the caller can set the game pattern.</p>
              <ol>
                <li>Before starting a new game / after resetting the game board, the caller can click on spaces on the pattern on the screen to create any pattern they want. </li>
                <li>At any point, the caller can open the settings panel and choose a pattern from there. With this setting, the caller can type to search, select from the dropdown or make their own pattern by clicking on the spaces.</li>
                <li>
                  From the <Link to="/patterns">Patterns</Link> page, the caller can click on any pattern and it will set the pattern when they return to the <Link to="/">home/caller</Link> page. The currently selected pattern will show up with a white background. You can also print a listing of all patterns from this page!
                </li>
              </ol>
              <p>
                <strong>Note: "Crazy" Patterns</strong> - Any pattern that starts with the word "Crazy" means it can be played in any direction on the card.
              </p>

              <section className="margin-top-xxlg padding-top-md"></section>

              <h4 id="game-controls">Game Controls</h4>
              <p>
                <strong>Start Game</strong> - this button only appears when there is not an active game in play. Clicking this button will start a brand new game by calling a single random bingo number (or the wild number(s) if playing Wild Bingo).
              </p>
              <p>
                <strong>Call Next Number</strong> - this button only appears if a game is in play, and manual mode is not selected. It will be disabled if autoplay is running. Clicking this button will call the next number and display it as flashing on the board.
              </p>
              <p>
                <strong>Play/Pause</strong> - this button plays/pauses the automatic calling functinoality. Only displays if automatic calling is enabled.
              </p>
              <p>
                <strong>Reset Board</strong> - this button is disabled if the game is currently running in automatic calling mode, or if there is no game currently in play. Clicking this button will show a pop up asking you if you're sure you want to reset the board. This will wipe out the current game completely so please use with caution.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Offline Mode --------------- */}
            <div>
              <h3 id="offline-mode">Downloading The App / Offline Mode</h3>
              <p>
                This functionality was added on 9/12/2023. You can now download the app to your device and access it offline. If you run into any issues with this functionality please reach out via the <Link to="/contact">contact page</Link>.
              </p>
              <p>To prepare for downloading the app, please fully load the site in the browser and then refresh the page to ensure that the app has fully cached. Once that's done follow the instructions below to install the app on your device.</p>
              <p>From a computer: once the site has loaded, you should see a download icon on the right side of the address bar. Click on that to download the app. Once you have downloaded it it should automatically open.</p>
              <p>
                To download the app to your <strong>Android</strong> mobile device / tablet, from the browser click on the 3 dots on the top right of the screen and select "Add to Home Screen" this will install the app just like any other app. You can then find it on your home screen.
              </p>
              <p>
                To download the app to your <strong>Apple</strong> mobile device / tablet, from the browser click on the share indicator on the bottom center of the browser (looks like a box with an arrow pointing up) and select "Add to Home Screen" this will install the app. You can then find it on your home screen.
              </p>
              <p className="small-text warning-text">After you have the app downloaded, please test to ensure it's working before running any games by turning off your wifi and/or cellular data and then opening the app/refreshing the website. If it doesn't load everything you may have to turn your internet back on and then refresh it a couple more times.</p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Settings Panel --------------- */}
            <div>
              <h3 id="settings-panel">Settings Panel</h3>
              <p>
                On the top right of the <Link to="/">home screen</Link> you'll see an icon that looks like gears like this
                <i className="fa fa-solid fa-gears margin-horizontal-lg"></i>
                clicking on this icon will bring up the settings panel. There are a <strong>lot</strong> of options available to make the application suit your needs, so we will go over those settings here. You can click outside of the settings panel or click the X on the top right of the panel to close it. Settings are saved when the panel is closed.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Board/Caller Settings --------------- */}
            <div>
              <h4 id="board-caller">Board/Caller Settings</h4>
              <p>
                <strong>Manual Mode</strong> - manual mode disables most other game settings. This mode is meant to be used when you are using a blower, ball cage or some other means of calling numbers outside of the application. With this mode you can just click on the ball on the board you wish to mark. If you make a mistake, clicking on the same ball will unmark it and remove it from the history.
              </p>
              <p>
                <strong>Automatic Calling</strong> - automatic calling will call a random ball on a timer. When you have this selected, an additional setting will show up below it called time between calls.
              </p>
              <p>
                <strong>Time Between Calls</strong> - Use this slider / the buttons to choose how many seconds the caller should wait between calls. Current maximum is 60 seconds. Please note that the timer begins as soon as a ball is selected so you will want to accomodate for any audible calls if needed by setting this time to be longer.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Game Settings --------------- */}
            <div>
              <h4 id="game-settings">Game Settings</h4>
              <p>
                <strong>Skip Unused Numbers</strong> - When selected, if you have selected a pattern any balls that are not used in the pattern will not be called. Example: when playing the B and O pattern, the I, N, and G balls will not be called.
              </p>
              <p>
                <strong>Skip Unused Indicator</strong> - When selected, a little arrow will display on the pattern on the home screen indicating that unused numbers are being skipped.
              </p>
              <p>
                <strong>Hide Unused Numbers On The Board</strong> - When selected, unused numbers will not display as called on the game board.
              </p>
              <p>
                <strong>Choose a Pattern</strong> - Here you choose the pattern you wish to play. You can click on the spaces on the pattern to create your own, select one from the dropdown or type in the dropdown to search.
              </p>
              <p>
                <strong>Wild Bingo</strong> - Wild Bingo comes with several game modes. The nature of Wild Bingo is to have a certain number/numbers be "wild" and be a free daub. Regular Wild Bingo will pick a random ball, and then all numbers ending in the same number as the Wild Ball can be daubed and will show as called on the board. Example: I 26 is the wild ball, all numbers ending with 6 would be marked as called.{" "}
                <em>Note: this setting is disabled if a game is in play or manual mode is enabled.</em>
              </p>
              <p>
                <strong>Wild Bingo: Two Wilds</strong> - Two wild balls will be selected at the start and marked on the board.
              </p>
              <p>
                <strong>Wild Bingo: Evens</strong> - all even numbers will be marked as called on the board at the start of the game.
              </p>
              <p>
                <strong>Wild Bingo: Odds</strong> - all odd numbers will be marked as called on the board at the start of the game.
              </p>
              <p>
                <strong>Wild Bingo: Evens And Odds</strong> - Just like Wild Bingo: Evens and Wild Bingo: Odds, one of these modes will be chosen at random at the start of each game.
              </p>
              <p>
                <strong>Wild Bingo: Custom </strong> - The caller chooses a number 1-10 and that will be the wild number. All numbers ending in that number/0 will be marked as called at the start of the game.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Hot Ball Settings --------------- */}
            <div>
              <h4 id="hot-ball">Hot Ball</h4>
              <p>
                <strong>Hot Ball</strong> - Hot Ball is an additional game within the normal games. If a player calls bingo on the hot ball number, they win an additional prize/progressive jackpot.
              </p>
              <p>The caller can either set a hot ball number using the slider or click the random button to get a random hot ball. This will add a fire indicator on the board by the hot ball. When the hot ball is called and audible caller is enabled, the called ball will be followed up with "hot ball"</p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Audio Settings --------------- */}
            <div>
              <h4 id="audio-settings">Audio Settings</h4>
              <p>
                <strong>Audible Caller</strong> - When selected, balls will be called audibly. Turning this on will enable additional settings.
              </p>
              <p className="small-text">
                <strong>Note:</strong> there are some known issues with the audible caller. Please check the <a href="#known-issues">Known Issues</a> section for more information.
              </p>

              <p>
                <strong>Caller Selection</strong> - A list of callers will be made available based upon which browser/device you're using. All devices/browsers have their own voices available. When you choose a caller, it will play a preview of the selection so you can hear what the voice sounds like.
              </p>
              <p className="small-text">
                <strong>Note:</strong> The available callers is not something controlled by the
                <span className="notranslate">Let's Play Bingo</span> application.
              </p>
              <p>
                <strong>Chatty Caller</strong> - When enabled, the caller will use additional vocalizations such as saying "Let's Play Bingo" at the start of the game, "under the I" for double calls, and for single digit calls like B9, it will say "9, by itself: 9".
              </p>

              <p>
                <strong>Call Numbers Twice</strong> - The caller will call the number twice - first together, then each digit separately. Example: N 34, N 3 4.
              </p>

              <p>
                <strong>Play Audible Chime</strong> - Plays a chime before a ball is called. Can be used with or without audible caller.
              </p>

              <p>
                <strong>Chime Selection</strong> - Upon selecting a chime, it will play a preview of it.
              </p>

              <p className="margin-top-lg">
                <span className="notranslate">Let's Play Bingo</span> utilizes an experimental technology called the Web Speech Synthesis API. This is primarily used for screen readers to read text back to vision impaired people. Support for this feature depends on your device and browser combination. If speech synthesis is not supported you will receive a message indiciating that the "Bingo Caller" option is not available for you. Please try another browser or different device if you'd like to
                use this feature.
              </p>
              <p className="small-text">
                <strong>Note:</strong> If you have having trouble with the audible caller on an iOS/Apple device, ensure that you have Siri enabled on your device. The Web Speech Synthesis API requires Siri to be enabled to work. Also, the latest Safari is known to have some issues with the API, including not loading any voice options, so it is recommended to use Chrome.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Game Control Settings --------------- */}
            <div>
              <h4 id="game-control-settings">Game Control Settings</h4>
              <p>
                <strong>Hide On Screen Controls</strong> - This will hide the on screen controls. If you select this, be sure to enable keyboard controls or the pop out controls or you won't be able to control the game.
              </p>
              <p>
                <strong>Pop Out Control Panel</strong> - Opens a pop up window with game controls available in it. <em>Note: this is a little buggy. If the panel shows up blank, close it, refresh the page and it should re-open it. If this is enabled, on screen controls are hidden automatically.</em>
              </p>
              <p>
                <strong>Keyboard Controls</strong> - When enabled, this allows the caller to control the game via keyboard. Keyboard controls are as shown:
              </p>
              <div className="detail-box">
                <code>Spacebar</code> pause/resume game
                <br />
                <code>Right Arrow</code> next ball
                <br />
                <code>R</code> reset game (press <code>Enter</code> to confirm)
                <br />
              </div>
              <p>
                <strong>Stoplight Control Colors</strong> - optionally shows the play/pause button in green/red.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Call Tracking Settings --------------- */}
            <div>
              <h4 id="call-tracking">Call Tracking Settings</h4>

              <p>
                <strong>Show Next Call Countdown</strong> - displays a countdown to the next bingo number. Only available if automatic calling is enabled.
              </p>

              <p>
                <strong>Show Current Call</strong> - displays a visual of the current ball in play.
              </p>

              <p>
                <strong>Show Previous Calls</strong> - displays a visual of up to the last 5 balls played. When enabled, shows a selection below it to indicate how many previous balls you want to display.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Layout Options --------------- */}
            <div>
              <h4 id="layout-options">Layout Options</h4>

              <p>
                <strong>Display in Full Screen</strong> - Forces the game to display in full screen.
              </p>

              <p>
                <strong>Hide Footer</strong> - hides the footer text that contains copyright information and links to policies.
              </p>

              <p>
                <strong>Layout</strong> - Allows the caller to choose how the board and other elements on the screen are laid out.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Theme Options --------------- */}
            <div>
              <h4 id="theme-options">Theme Options</h4>

              <p>
                <strong>Theme</strong> - controls the color theme of the site.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Troubleshooting --------------- */}
            <div>
              <h2 id="troubleshooting">Troubleshooting</h2>
              <p>Having issues? Before sending a message please go through these steps that will normally resolve any issues.</p>

              <h4 className="no-margin">Clear your cache.</h4>
              <p className="margin-top-none">
                This simple step can usually solve most issues. If you are unsure of how to clear your cache here is a{" "}
                <a href="https://kinsta.com/knowledgebase/how-to-clear-browser-cache/" target="_blank" rel="noreferrer">
                  very detailed article
                </a>{" "}
                that should help you.
              </p>

              <h4 className="no-margin">Check your game settings</h4>
              <p className="margin-top-none">
                Double check your settings to ensure that you're not inadvertently disabling functionality. <em>For example</em>, if you enable manual calling mode, all buttons will be disabled. Or if you have skip unused numbers enabled and have a blank pattern then call a number it'll call all numbers.
              </p>

              <h4 className="no-margin">Try a different browser</h4>
              <p className="margin-top-none">
                To rule out issues with the site itself, try a different browser and see if the issues persist. If they do, please use the <Link to="/contact">contact form</Link> to get in touch with me.
              </p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Reporting Issues --------------- */}
            <div>
              <h2 id="reporting-issues">Reporting Issues</h2>
              <p>
                If you come across issues with the game, please use the <Link to="/contact">contact form</Link> to send a detailed message of what you are experiencing and what you were expecting the behavior to be.
              </p>
              <p>Please follow the steps below when creating your report. With this information I will be able to address the issue much more quickly.</p>
              <ul>
                <li>What issue you're experiencing and steps to replicate it</li>
                <li>What kind of device (PC, Mac, Tablet, Phone, etc) you're using</li>
                <li>Which browser (Chrome, Safari, Edge, Firefox, etc) you're using</li>
                <li>What steps you've taken to troubleshoot the issue</li>
                <li>Photos or videos of the issue you're experiencing if you can</li>
              </ul>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* --------------- Known Issues --------------- */}

            <div>
              <h2 id="known-issues">Known Issues</h2>
              <h4 className="no-margin">Issues with Audible Caller in the Safari browser</h4>
              <p>If you have having trouble with the audible caller on an iOS/Apple device, ensure that you have Siri enabled on your device and have the soft mute turned off (the slider on the side of your device that turns off the ringer). The Web Speech Synthesis API requires Siri to be enabled and the audio on in order to work. Also, the latest Safari is known to have some issues with the API, including not loading any voice options, so it is recommended to use Chrome.</p>
              <h4 className="no-margin">MP3 caller and automatic calling on mobile devices</h4>
              <p>There's a limitation with mobile devices that disables audible calling on mobile devices when using the MP3 based caller. This is a security feature built into some devices to prevent audio files from being played automatically when visiting a website. There is no workaround for this issue and you must manually do the next audible call if you're using a browser that blocks audio from being automatically played.</p>
              <h4 className="no-margin">Issues with Audible Caller on Android</h4>
              <p>
                The selection of a caller for these devices requires you to choose your locale and voice in your device's settings. Let's Play Bingo has no control over these settings and no visibility into them. To do this open your settings app on your device, then go to Controls, Language and Input, Text-to-speech options. Select the gear icon next to Google Text-to-speech Engine and under language you can update the locale you wish to use. If you choose "Install Voice Data" you can select
                from a sample of different voices for some locales. Once you've made your selection restart your device for the changes to take effect.
              </p>

              <h4 className="no-margin">Other issues with Audible Caller</h4>
              <p>
                If you are having other mysterious issues with the audible caller try to clear your application cache and your browser cache. Check out the following links for more info.{" "}
                <a href="https://www.pcmag.com/how-to/how-to-clear-your-cache-on-any-browser" target="_blank" rel="noreferrer">
                  Clear browser cache
                </a>
                ,{" "}
                <a href="https://www.avg.com/en/signal/how-to-clear-cache-on-android-phones" target="_blank" rel="noreferrer">
                  clear android cache
                </a>{" "}
                and{" "}
                <a href="https://www.91mobiles.com/hub/clear-iphone-cache-how-to/" target="_blank" rel="noreferrer">
                  clear apple cache
                </a>
                .
              </p>
              <p>There are a few other intermittent issues that are specific to certain browser/device combinations. If you are having issues and have access to a different browser and device combination please give that a try. I cannot troubleshoot individual audible caller issues since that feature relies so heavily on the browser and device combination. I am working on finding workarounds and alternatives to the Web Speech Synthesis API.</p>
            </div>

            <section className="margin-top-xxlg padding-top-md"></section>

            {/* ----------- Questions, Suggestions, and Comments ----------- */}

            <div>
              <h2 id="contact">Questions, Suggestions, and Comments</h2>
              <p>
                Please feel free to reach out via the contact page with any questions, suggestions, comments, etc. I love hearing from players! Please remember that I'm just one developer, but I do my best to respond to everyone who messages me and help them however I can! You can find the contact form on the <Link to="/contact">contact page</Link>.
              </p>
            </div>
          </div>

          <div className="col colspan4 padding-top-lg">
            {/* --------------- Table of Contents --------------- */}
            <h3>Table of Contents</h3>
            <ol>
              <li>
                <a href="#what-is-lpb">
                  What is <span className="notranslate margin-left-sm">Let's Play Bingo</span>?
                </a>
              </li>
              <li>
                <a href="#gameplay">How do I play?</a>
                <ul className="no-margin padding-left-xlg">
                  <li>
                    <a href="#game-setup">Game Setup</a>
                  </li>
                  <li>
                    <a href="#pattern-selection">Pattern Selection</a>
                  </li>
                  <li>
                    <a href="#game-controls">Game Controls</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#offline-mode">Downloading The App / Offline Mode</a>
              </li>
              <li>
                <a href="#settings-panel">Settings Panel</a>
                <ul className="no-margin padding-left-xlg">
                  <li>
                    <a href="#board-caller">Board/Caller Settings</a>
                  </li>
                  <li>
                    <a href="#hot-ball">Hot Ball</a>
                  </li>
                  <li>
                    <a href="#audio-settings">Audio Settings</a>
                  </li>
                  <li>
                    <a href="#game-control-settings">Game Control Settings</a>
                  </li>
                  <li>
                    <a href="#call-tracking">Call Tracking Settings</a>
                  </li>
                  <li>
                    <a href="#layout-options">Layout Options</a>
                  </li>
                  <li>
                    <a href="#theme-options">Theme Options</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#troubleshooting">Troubleshooting</a>
              </li>
              <li>
                <a href="#reporting-issues">Reporting Issues</a>
              </li>
              <li>
                <a href="#known-issues">Known Issues</a>
              </li>
              <li>
                <a href="#contact">Questions, Suggestions, Comments</a>
              </li>
            </ol>
          </div>
        </div>
      </section>
    );
  }
}
export default Help;
