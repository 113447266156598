export const qPatterns = [
  /* --------------------- Q -------------------- */
  {
    value: "Q",
    label: "Q",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [false, true, false, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["B"],
    credit: null,
  },
];
