export const wPatterns = [
  /* --------------------- W -------------------- */
  {
    value: "Warm Up",
    label: "Warm Up",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "Waterfall",
    label: "Waterfall",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, true, true, true, false],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Wave",
    label: "Wave",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, true, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, true, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Wave (Alternate)",
    label: "Wave (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, true],
      N: [false, false, true, false, true],
      G: [false, true, false, false, true],
      O: [false, true, true, true, true],
    },
    unusedLetters: ["B"],
    credit: null,
  },
  {
    value: "Winners Choice (4 Corners Count, 2 Clusters Cannot Interlock)",
    label: "Winners Choice (4 Corners Count, 2 Clusters Cannot Interlock)",
    otherNames: [],
    pattern: { B: [true, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, true] },
    unusedLetters: ["N"],
    credit: "Michelle Hahn",
    winningPatterns: [
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, false, false], I: [true, true, false, false, false], N: [true, true, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [true, true, true, false, false], G: [true, true, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, false, false], G: [true, true, false, true, false], O: [true, true, false, false, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, false, false], G: [false, true, true, true, false], O: [false, true, true, false, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, false, false], G: [false, false, true, true, false], O: [false, false, true, true, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, true, true, true], G: [false, false, false, true, true], O: [false, false, false, false, true] },
      { B: [true, false, false, false, false], I: [false, true, false, true, true], N: [false, false, true, true, true], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, true, true], I: [false, true, false, true, true], N: [false, false, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, true, true, false], I: [false, true, true, true, false], N: [false, false, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, true, true, false, false], I: [false, true, true, false, false], N: [false, false, true, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
      { B: [true, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, true] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [false, false, true, true, true], I: [false, false, true, true, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [false, true, true, false, true], I: [false, true, true, true, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [true, true, false, false, true], I: [true, true, false, true, false], N: [false, false, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [false, false, false, false, true], I: [true, true, false, true, false], N: [true, true, true, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [true, true, true, false, false], G: [true, true, false, false, false], O: [true, false, false, false, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, true, true, false, false], O: [true, true, true, false, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, true, true, true, false], O: [true, false, true, true, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, false, false], G: [false, true, false, true, true], O: [true, false, false, true, true] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, true, true, true], G: [false, true, false, true, true], O: [true, false, false, false, false] },
      { B: [false, false, false, false, true], I: [false, false, false, true, true], N: [false, false, true, true, true], G: [false, true, false, false, false], O: [true, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [true, false, false, false, false], G: [true, false, false, false, false], O: [true, false, false, false, false] },
      { B: [true, false, false, false, false], I: [true, true, false, false, false], N: [true, true, false, false, false], G: [true, false, false, false, false], O: [true, false, false, false, false] },
      { B: [true, false, false, false, false], I: [true, false, false, false, false], N: [true, true, false, false, false], G: [true, true, false, false, false], O: [true, false, false, false, false] },
      { B: [true, false, false, false, false], I: [true, false, false, false, false], N: [true, false, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, true, false, false, false], G: [false, true, false, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [true, true, false, false, false], N: [true, true, false, false, false], G: [false, true, false, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [false, true, false, false, false], N: [true, true, false, false, false], G: [true, true, false, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [false, true, false, false, false], N: [false, true, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, true, true, false, false], I: [false, true, true, false, false], N: [false, true, false, false, false], G: [false, true, false, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [false, true, true, false, false], N: [false, true, true, false, false], G: [false, true, false, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [false, true, false, false, false], N: [false, true, true, false, false], G: [false, true, true, false, false], O: [false, true, false, false, false] },
      { B: [false, true, false, false, false], I: [false, true, false, false, false], N: [false, true, false, false, false], G: [false, true, true, false, false], O: [false, true, true, false, false] },
      { B: [false, true, true, false, false], I: [false, true, true, false, false], N: [false, false, true, false, false], G: [false, false, true, false, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, true, true, false, false], N: [false, true, true, false, false], G: [false, false, true, false, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, false, true, false, false], N: [false, true, true, false, false], G: [false, true, true, false, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, false, true, false, false], N: [false, false, true, false, false], G: [false, true, true, false, false], O: [false, true, true, false, false] },
      { B: [false, false, true, true, false], I: [false, false, true, true, false], N: [false, false, true, false, false], G: [false, false, true, false, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, true, false, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, false, true, false, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, true, false, false] },
      { B: [false, false, true, false, false], I: [false, false, true, false, false], N: [false, false, true, false, false], G: [false, false, true, true, false], O: [false, false, true, true, false] },
      { B: [true, true, true, true, true], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, true, true, true], I: [false, true, true, false, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, true, true, true], I: [false, false, true, true, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, true, true, true], I: [false, false, false, true, true], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, true, true, true], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, true, true, true], N: [true, true, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, true, true, true], N: [false, true, true, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, true, true, true], N: [false, false, true, true, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, true, true, true], N: [false, false, false, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, false, false, false], N: [true, true, true, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, true, true, true], G: [true, true, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, true, true, false, false], N: [true, true, true, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, true, true, true], G: [false, true, true, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, true, true, false], N: [true, true, true, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, true, true, true], G: [false, false, true, true, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, true, true], N: [true, true, true, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, true, true, true], G: [false, false, false, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, false, false, false], G: [true, true, true, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, true], O: [true, true, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, true, true, false, false], G: [true, true, true, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, true], O: [false, true, true, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, true, true, false], G: [true, true, true, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, true], O: [false, false, true, true, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, true, true], G: [true, true, true, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, false, false, false], O: [true, true, true, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [false, true, true, false, false], O: [true, true, true, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [false, false, true, true, false], O: [true, true, true, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [false, false, false, true, true], O: [true, true, true, true, true] },
      { B: [false, false, true, true, false], I: [false, false, true, true, false], N: [false, false, false, true, false], G: [false, false, false, true, false], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, false, true, false], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, false, true, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, false, true, false], N: [false, false, false, true, false], G: [false, false, true, true, false], O: [false, false, true, true, false] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, false, true, false], G: [false, false, false, true, false], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, false, true, true], N: [false, false, false, true, true], G: [false, false, false, true, false], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, false, true, false], N: [false, false, false, true, true], G: [false, false, false, true, true], O: [false, false, false, true, false] },
      { B: [false, false, false, true, false], I: [false, false, false, true, false], N: [false, false, false, true, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, false, false, true], G: [false, false, false, false, true], O: [false, false, false, false, true] },
      { B: [false, false, false, false, true], I: [false, false, false, true, true], N: [false, false, false, true, true], G: [false, false, false, false, true], O: [false, false, false, false, true] },
      { B: [false, false, false, false, true], I: [false, false, false, false, true], N: [false, false, false, true, true], G: [false, false, false, true, true], O: [false, false, false, false, true] },
      { B: [false, false, false, false, true], I: [false, false, false, false, true], N: [false, false, false, false, true], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [true, true, true, true, false], I: [true, true, true, true, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, true, true], I: [true, true, false, true, true], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, true, true, false], N: [false, false, true, true, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, true, true], N: [false, false, false, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [true, true, false, false, false], G: [true, true, false, false, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, false, true, true, false], O: [false, false, true, true, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, true, true, false, false], O: [false, true, true, false, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, false, true, true, false], O: [false, false, true, true, false] },
      { B: [true, true, false, false, false], I: [true, true, false, false, false], N: [false, false, false, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, false], O: [true, true, true, true, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, false, true, true], O: [true, true, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, true, true], G: [true, true, false, true, true], O: [true, true, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, true, true], N: [false, false, false, true, true], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, true, true, false], I: [false, false, true, true, false], N: [false, false, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, true, true, false, false], I: [false, true, true, false, false], N: [false, false, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, false, false, false], N: [true, true, false, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, false, false, false], G: [true, true, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, true, true, false, false], N: [false, true, true, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [false, false, false, true, true], N: [false, false, false, true, true], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, false, false, false], G: [false, false, false, true, true], O: [false, false, false, true, true] },
      { B: [false, false, false, false, false], I: [true, true, false, false, false], N: [true, true, false, false, false], G: [true, true, false, false, false], O: [true, true, false, false, false] },
      { B: [false, false, false, false, false], I: [false, true, true, false, false], N: [false, true, true, false, false], G: [false, true, true, false, false], O: [false, true, true, false, false] },
      { B: [false, true, true, false, false], I: [false, true, true, false, false], N: [false, true, true, false, false], G: [false, true, true, false, false], O: [false, false, false, false, false] },
      { B: [false, false, true, true, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, true, true, false] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, false, false, true, true], G: [false, false, false, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, true, true], I: [false, false, false, true, true], N: [false, true, true, false, false], G: [false, true, true, false, false], O: [false, false, false, false, false] },
    ],
  },
  {
    value: "Witch Hat",
    label: "Witch Hat",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, true, true, true],
      N: [true, true, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
