import React from "react";
import Settings from "../components/Settings";
// Helper Functions
import { dispatchCustomEvent, getAudibleCallers, getLanguageLabel, generateBingoBoard, getDefaultSettings, getRandomNumberInRange, reconcileSettings, toggleFullScreen, getScreenSize } from "../helpers/Utilities";
import { getCache, updateCache, updateCacheValue } from "../helpers/CacheManagement";
import { getPatternInfo } from "../helpers/PresetPatterns";
// Layouts
import ClassicReversed from "../layouts/ClassicReversed";
import Classic from "../layouts/Classic";
import Stacked from "../layouts/Stacked";
import Vertical from "../layouts/Vertical";
// Shuffle sound
import shuffleSound from "../assets/shuffle.mp3";

class Caller extends React.Component {
  // Constructor ----------------------------------------
  constructor(props) {
    super(props);
    this.mainCallerList = [];
    this.cache = getCache();
    if (this.cache) {
      if (this.cache.settings.fullscreen) {
        this.cache.settings.fullscreen = false;
        updateCacheValue("settings", this.cache.settings);
      }
      this.cache.settings = reconcileSettings(this.cache.settings);
      this.state = this.cache;
    } else {
      this.state = {
        board: generateBingoBoard(),
        called: [],
        currentCall: null,
        interval: null,
        layout: "classic",
        previousCall: null,
        settings: getDefaultSettings(),
        shuffleOn: false,
      };
      updateCache(this.state); // Set the cache for the initial time
    }
    this.state.screenSize = getScreenSize();
    this.mp3Callers = getAudibleCallers();
  }

  // Lifecycle Hooks ----------------------------------------

  componentDidUpdate() {
    // anytime the component updates, lets save the cache
    updateCache(this.state);
  }

  componentDidMount() {
    document.title = "Let's Play Bingo! | The #1 free bingo caller application";
    this.initializeSpeech();
    window.addEventListener("resize", () => {
      this.setState({ screenSize: getScreenSize() });
    });
    document.addEventListener("game-control", this.handleGameControl);
    document.addEventListener("mark-number", this.handleManualCall);
    document.addEventListener("pause-game", this.handleGameControl);
    document.addEventListener("settingssaved", this.handleSettingsSaved);
    document.addEventListener("test-speech", this.handleSpeech);
    document.addEventListener("custompattern", this.handleCustomPattern);
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.setState({ screenSize: getScreenSize() });
    });
    document.removeEventListener("game-control", this.handleGameControl);
    document.removeEventListener("mark-number", this.handleManualCall);
    document.removeEventListener("pause-game", this.handleGameControl);
    document.removeEventListener("settingssaved", this.handleSettingsSaved);
    document.removeEventListener("custompattern", this.handleCustomPattern);
    document.removeEventListener("test-speech", this.handleSpeech);
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
  }

  // Speech Synthesis ----------------------------------------

  initializeSpeech = () => {
    const this2 = this;
    let speechSettings = {
      callers: [],
    };
    speechSettings.supported = Object.prototype.hasOwnProperty.call(window, "speechSynthesis");

    let synth = window.speechSynthesis;
    if (speechSettings.supported) {
      synth.onvoiceschanged = handleLoadVoices;
      speechSettings.callers = synth.getVoices();
    }

    function handleLoadVoices() {
      let voices = synth.getVoices();
      window.setTimeout(() => {
        this2.mainCallerList = [...voices];
        let callerOptions = [];
        voices.forEach((voice) => {
          let voiceObj = {};
          voiceObj.label = voice.name + " / " + getLanguageLabel(voice.lang);
          voiceObj.value = voice.name;
          voiceObj.voice = voice;
          callerOptions.push(voiceObj);
        });
        speechSettings.callers = callerOptions;
        dispatchCustomEvent("loadcallers", callerOptions);
      });
    }
    this.setState(speechSettings);
  };

  handleSpeech = (event) => {
    window.speechSynthesis.cancel();
    if (!Object.prototype.hasOwnProperty.call(event.detail, "voice")) {
      event.detail.voice = this.state.settings.caller;
    }
    this.speak(event.detail.message, event.detail.voice);
  };

  speak = (message, voice = this.state.settings.caller) => {
    const synth = window.speechSynthesis;
    // new utterance
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = message;
    utterance.volume = 1;
    utterance.rate = 0.9;
    // set voice
    if (typeof voice === "string") {
      this.mainCallerList.forEach((caller) => {
        if (caller.name === voice) {
          utterance.voice = caller;
        }
      });
    } else {
      utterance.voice = voice;
    }
    synth.speak(utterance);
  };

  // Handlers --------------------------------------------------------

  handleAudibleCall = (call) => {
    const numString = call.number.toString();
    const letter = call.letter.toLowerCase();
    window.speechSynthesis.cancel();
    let messageString = `${letter}, , ${numString}`;
    // call the new ball, first call it all together, then call each character individually
    if (this.state.settings.doubleCall === true) {
      this.speak(messageString);
      if (numString.length === 2) {
        if (this.state.settings.chattyCaller) {
          messageString = "under the " + letter + ", " + numString.charAt(0) + ", " + numString.charAt(1);
        } else {
          messageString = letter + ", " + numString.charAt(0) + ", " + numString.charAt(1);
        }
      } else {
        if (this.state.settings.chattyCaller) {
          messageString = "By itself:, number " + call.number;
        } else {
          messageString = letter + ", " + call.number;
        }
      }
      if (this.state.settings.hotBall && call.number === this.state.settings.hotBallNumber) {
        messageString += " - hot ball.";
      }
      this.speak(messageString);
    } else {
      if (this.state.settings.hotBall && call.number === this.state.settings.hotBallNumber) {
        messageString += " - hot ball.";
      }
      this.speak(messageString);
    }
  };

  clips = [];
  handleMp3Call = (call) => {
    if (this.clips.length > 0) {
      this.clips.forEach((clip) => clip.pause());
      this.clips = [];
    }

    const numString = call.number.toString();
    const letter = call.letter.toLowerCase();
    const callerList = this.mp3Callers.filter((a) => a.value === this.state.settings.callermp3);

    if (callerList.length > 0) {
      const audio = callerList[0].audio;

      // Create an array to hold all of the audio clips that will need to be played
      this.clips.push(new Audio(audio[letter]), new Audio(audio[numString]));
      if (this.state.settings.doubleCall === true) {
        if (this.state.settings.chattyCaller) {
          this.clips.push(numString.length === 2 ? new Audio(audio.underthe) : new Audio(audio.byitself));
        }
        this.clips.push(new Audio(audio[letter]));
        this.clips.push(new Audio(audio[numString.charAt(0)]));
        if (numString.length === 2) {
          this.clips.push(new Audio(audio[numString.charAt(1)]));
        }
      }
      if (this.state.settings.hotBall && call.number === this.state.settings.hotBallNumber) {
        this.clips.push(new Audio(audio.hotball));
      }
      // Loop through and call each of the audios
      this.clips.forEach((clip, index) => {
        if (index + 1 < this.clips.length) {
          const queuedClip = this.clips[index + 1];
          clip.addEventListener("ended", () => {
            setTimeout(() => {
              queuedClip.play();
            }, 300);
          });
        }
        if (index === 0) {
          clip.play();
        }
      });
    }
  };

  /**
   * Handles calling a new random number
   */
  handleCall = () => {
    const pattern = this.state.settings.pattern;
    const hideUnusedBalls = this.state.settings.skipUnusedNumbers && this.state.settings.hideUnusedNumbers;
    const unusedLetters = this.state.settings.pattern.unusedLetters;
    const called = this.state.called;
    const usedCalled = hideUnusedBalls ? [] : this.state.called;
    if (hideUnusedBalls) {
      this.state.called.forEach((ball) => {
        if (!unusedLetters.includes(ball.letter)) {
          usedCalled.push(ball);
        }
      });
    }

    if (usedCalled.length < 75 /* this.totalPotentialCalls */) {
      try {
        // Get the new call.
        const randomBall = getRandomNumberInRange(1, 75, called);
        let board = { ...this.state.board };
        let newCall = this.state.currentCall ? this.state.currentCall : {};
        let previousCall = this.state.previousCall ? this.state.previousCall : {};
        // Traverse through the board object to find the selected number
        Object.keys(board).forEach((row) => {
          Object.values(board[row]).forEach((ball, index) => {
            // Normal Bingo Start ------------------
            if (hideUnusedBalls && unusedLetters.includes(ball.letter)) {
              ball.hide = true;
            }
            if (ball.number === randomBall) {
              ball.called = true;
              ball.active = ball.hide === false;
              if (!ball.hide) {
                previousCall = newCall;
                newCall = ball;
              }
              called.push(ball);
              if (this.state.settings.skipUnusedNumbers === true && pattern.unusedLetters.length > 0 && pattern.unusedLetters.includes(ball.letter)) {
                // Call again
                window.setTimeout(() => {
                  this.handleCall();
                }, 0);
              } else {
                // since we don't have to call again, handle any audibles
                // If audibleChime AND audibleCaller are enabled,
                // use a timeout to delay the audible call until after the chime plays.
                if (this.state.settings.audibleChime) {
                  let chime = new Audio(this.state.settings.chime);
                  chime.play();
                  if (this.state.settings.audibleCaller) {
                    window.setTimeout(() => {
                      this.handleAudibleCall(ball);
                    }, 700);
                  } else if (this.state.settings.mp3Caller) {
                    window.setTimeout(() => {
                      this.handleMp3Call(ball);
                    }, 700);
                  }
                } else {
                  if (this.state.settings.audibleCaller) {
                    this.handleAudibleCall(ball);
                  } else if (this.state.settings.mp3Caller) {
                    this.handleMp3Call(ball);
                  }
                }
              }
            } else {
              if (ball.active) {
                ball.active = false;
              }
            }
          });
        });

        // Update the state with the new info
        this.setState({
          board: board,
          called: called,
          previousCall: this.state.currentCall,
          currentCall: newCall,
        });
      } catch (error) {
        if (error.message === "max has been reached") {
          if (this.state.settings.chattyCaller && this.state.settings.audibleCaller) {
            this.handleSpeech({
              detail: {
                message: "Bingo!",
              },
            });
          }
        }
      }
    } else {
      // clear the interval that's running.
      clearInterval(this.state.interval);
    }
  };

  handleFullscreenChange = (event) => {
    if (document.fullscreenElement === null) {
      let settings = { ...this.state.settings };
      settings.fullscreen = false;
      this.setState({ settings: settings });
    }
  };

  handleGameControl = (event) => {
    const type = event.detail;
    switch (type) {
      case "new-game":
        this.handleStartGame();
        break;
      case "reset-game":
        this.handleResetGame();
        break;
      case "call-number":
        this.handleCall();
        break;
      case "pause-game":
        clearInterval(this.state.interval);
        this.setState({ interval: null });
        break;
      case "resume-game":
        if (this.state.settings.automaticCalling === true && this.state.interval === null) {
          this.handleCall();
          this.handleSetInterval(this.state.settings.delay);
        }
        break;
      case "shuffle":
        this.shuffleBalls();
        break;
      default:
        break;
    }
  };

  handleManualCall = (event) => {
    let board = { ...this.state.board };
    const called = this.state.called;
    const manualCall = event.detail;
    let currentCall = this.state.currentCall;
    let previousCall = this.state.previousCall;

    const calledIndex = Array.prototype.findIndex.call(called, (x) => x.number === manualCall.number);
    const previouslyCalled = calledIndex > -1;
    if (previouslyCalled) {
      dispatchCustomEvent("stop-manual-countdown", null);
      // The selected call has already been called.
      called.splice(calledIndex, 1);
      currentCall = called[called.length - 1];
      previousCall = called[called.length - 2]; // set previous call to the last one
    } else {
      dispatchCustomEvent("start-manual-countdown", null);
      called.push(manualCall);
      // This is a new call.
      previousCall = currentCall;
      currentCall = manualCall;
    }
    if (previousCall === undefined) {
      previousCall = null;
    }
    if (currentCall === undefined || called.length === 0) {
      currentCall = null;
    }

    // Traverse through the board object to find the selected number
    Object.keys(board).forEach((row) => {
      Object.values(board[row]).forEach((number, index) => {
        if (previouslyCalled && number.number === manualCall.number) {
          board[row][index].called = false;
          board[row][index].active = false;
        } else if (number.number === currentCall?.number) {
          board[row][index].called = true;
          board[row][index].active = true;
        } else if (number.number === previousCall?.number) {
          board[row][index].called = true;
          board[row][index].active = false;
        } else {
          board[row][index].active = false;
        }

        if (!previouslyCalled && number.number === manualCall.number) {
          // If audibleChime AND audibleCaller are enabled,
          // use a timeout to delay the audible call until after the chime plays.
          if (this.state.settings.audibleChime) {
            let chime = new Audio(this.state.settings.chime);
            chime.play();
            if (this.state.settings.audibleCaller) {
              window.setTimeout(() => {
                this.handleAudibleCall(currentCall);
              }, 700);
            } else if (this.state.settings.mp3Caller) {
              window.setTimeout(() => {
                this.handleMp3Call(currentCall);
              }, 700);
            }
          } else {
            if (this.state.settings.audibleCaller) {
              this.handleAudibleCall(currentCall);
            } else if (this.state.settings.mp3Caller) {
              this.handleMp3Call(currentCall);
            }
          }
        }
      });
    });
    this.setState({
      board: board,
      called: called,
      previousCall: previousCall,
      currentCall: currentCall,
    });
  };

  handleResetGame = () => {
    if (this.state.settings.manual) {
      dispatchCustomEvent("stop-manual-countdown", null);
    }
    if (this.state.interval !== null) {
      clearInterval(this.state.interval);
    }
    this.setState({
      board: { ...generateBingoBoard() },
      called: [],
      currentCall: null,
      interval: null,
      previousCall: null,
      shuffleOn: false,
    });
  };

  handleSetInterval = (delay = 30) => {
    window.clearInterval(this.state.interval);
    let interval = window.setInterval(() => {
      this.handleCall();
    }, delay * 1000);
    this.setState({ interval: interval });
  };

  handleSettingsSaved = (event) => {
    const settings = { ...event.detail };
    // Set up new state
    let newState = { settings: settings };
    // determine if automatic calling was toggled
    // if so, we need to clear out the interval
    if (settings.automaticCalling !== this.state.settings.automaticCalling) {
      if (settings.automaticCalling === false) {
        window.clearInterval(this.state.interval);
        newState.interval = null;
      }
    }
    // if layout has changed, add it to the top level changes
    if (settings.layout !== this.state.layout) {
      newState.layout = settings.layout;
    }
    // if pattern changed
    if (typeof settings.pattern === "string") {
      newState.settings.pattern = getPatternInfo(settings.pattern);
    }
    // if fullscreen changed
    if (newState.fullscreen !== this.state.settings.fullscreen) {
      toggleFullScreen(settings.fullscreen);
    }
    // Set new state
    this.setState(newState);
  };

  handleCustomPattern = (event) => {
    const settings = { ...this.state.settings };
    settings.pattern = event.detail;
    this.setState({ settings: settings });
  };

  handleStartGame = () => {
    if (this.chattyCallerSelected) {
      if (this.state.settings.caller) {
        this.handleSpeech({
          detail: {
            message: this.state.settings.wildBingo ? "Let's Play Wild Bingo!" : "Let's Play Bingo!",
          },
        });
      } else if (this.state.settings.callerMp3) {
        const caller = this.mp3Callers.filter((a) => a.value === this.state.settings.callermp3)[0].audio;
        const phrase = new Audio(caller.letsplaybingo);
        phrase.play();
      }
      window.setTimeout(() => {
        this.state.settings.wildBingo ? this.handleWildBingoSetup() : this.handleCall();
        this.handleSetInterval(this.state.settings.delay);
      }, 2000);
    } else {
      if (this.state.settings.wildBingo) {
        this.handleWildBingoSetup();
      } else {
        this.handleCall();
        if (this.state.settings.automaticCalling) {
          this.handleSetInterval(this.state.settings.delay);
        }
      }
    }
  };

  handleWildBingoSetup() {
    // set up wild bingo
    const called = this.state.called;
    let board = { ...this.state.board };
    let lastWildCall;
    let wildBalls = [];
    const hideUnusedBalls = this.state.settings.skipUnusedNumbers && this.state.settings.hideUnusedNumbers;
    const unusedLetters = this.state.settings.pattern.unusedLetters;

    const isEven = getRandomNumberInRange(1, 75) % 2 !== 1;
    if (this.state.settings.wildBingoEvens || (this.state.settings.wildBingoEvensAndOdds && isEven)) {
      Object.keys(board).forEach((letter, boardIndex) => {
        Object.values(board[letter]).forEach((ball, letterIndex) => {
          if (hideUnusedBalls && unusedLetters.includes(ball.letter)) {
            ball.hide = true;
          }
          if (boardIndex === Object.keys(board).length - 1 && board[letter].length - 2 === letterIndex) {
            ball.active = ball.hide === false;
            lastWildCall = ball;
            wildBalls.push(ball);
          }
          if (ball.number % 2 !== 1) {
            ball.called = true;
            called.push(ball);
          }
        });
      });
    } else if (this.state.settings.wildBingoOdds || (this.state.settings.wildBingoEvensAndOdds && !isEven)) {
      Object.keys(board).forEach((letter, boardIndex) => {
        Object.values(board[letter]).forEach((ball, letterIndex) => {
          if (hideUnusedBalls && unusedLetters.includes(ball.letter)) {
            ball.hide = true;
          }
          if (boardIndex === Object.keys(board).length - 1 && board[letter].length - 1 === letterIndex) {
            ball.active = ball.hide === false;
            lastWildCall = ball;
            wildBalls.push(ball);
          }
          if (ball.number % 2 === 1) {
            ball.called = true;
            called.push(ball);
          }
        });
      });
    } else {
      const randomBall = this.state.settings.wildBingoCustom === true && this.state.settings.wildBingoCustomValue !== null ? this.state.settings.wildBingoCustomValue : getRandomNumberInRange(1, 75, called);
      const wildNumber = randomBall.toString().slice(-1);
      Object.keys(board).forEach((letter) => {
        Object.values(board[letter]).forEach((ball, index) => {
          if (hideUnusedBalls && unusedLetters.includes(ball.letter)) {
            ball.hide = true;
          }
          if (ball.number.toString().endsWith(wildNumber)) {
            ball.called = true;
            called.push(ball);
            lastWildCall = ball;
          }
          if (ball.number === randomBall) {
            if (!this.state.settings.wildBingoDouble) {
              ball.active = ball.hide === false;
            }
            wildBalls.push(ball);
          }
        });
      });
    }

    // Double Wild - add another wild ball.
    if (this.state.settings.wildBingoDouble) {
      const randomBall2 = getRandomNumberInRange(1, 75, called);
      const wildNumber2 = randomBall2.toString().slice(-1);
      Object.keys(board).forEach((letter) => {
        Object.values(board[letter]).forEach((ball, index) => {
          if (hideUnusedBalls && unusedLetters.includes(ball.letter)) {
            ball.hide = true;
          }
          if (ball.number.toString().endsWith(wildNumber2)) {
            ball.called = true;
            called.push(ball);
            lastWildCall = ball;
          }
          if (ball.number === randomBall2) {
            ball.active = ball.hide === false;
            wildBalls.push(ball);
          }
        });
      });
    }

    // Loop once more to mark the
    this.setState({
      board,
      called,
      previousCall: lastWildCall,
      currentCall: wildBalls[0],
      wildBalls: wildBalls,
    });
  }

  shuffleBalls = () => {
    this.handleResetGame();
    let shuffleAudio = new Audio(shuffleSound);
    let balls = generateBingoBoard();
    let count = 0;
    shuffleAudio.play();

    const shuffleInterval = setInterval(() => {
      flashRandomBall();
      flashRandomBall();
      flashRandomBall();
      count++;
      this.setState({ board: balls, shuffleOn: true });
      if (count === 50) {
        clearInterval(shuffleInterval);
        shuffleAudio.pause();
        this.handleResetGame();
      }
    }, 100);

    function flashRandomBall() {
      let randomNumber = getRandomNumberInRange(1, 75);
      Object.keys(balls).forEach((letter) => {
        Object.values(balls[letter]).forEach((ball, index) => {
          if (ball.number === randomNumber) {
            let currentBall = balls[letter][index];
            currentBall.active = !currentBall.active;
          }
        });
      });
    }
  };

  // Getters ---------------------------------------------

  get calledBallCount() {
    if (this.state.shuffleOn) {
      return 0;
    }
    let unusedLetters = [];
    if (this.state.settings.pattern?.unusedLetters) {
      unusedLetters = this.state.settings.pattern.unusedLetters;
    }
    const skipUnusedNumbers = this.state.settings.skipUnusedNumbers;
    let count = 0;
    Object.keys(this.state.board).forEach((letter) => {
      if (!skipUnusedNumbers || (skipUnusedNumbers && !unusedLetters.includes(letter))) {
        this.state.board[letter].forEach((ball) => {
          if (ball.called) {
            count++;
          }
        });
      }
    });
    return count;
  }

  get chattyCallerSelected() {
    return (this.state.settings.audibleCaller || this.state.settings.mp3Caller) && this.state.settings.chattyCaller;
  }

  get isGameRunning() {
    return this.state.interval !== null;
  }

  get totalPotentialCalls() {
    let returnValue = 75;
    if (this.state.settings.skipUnusedNumbers) {
      let unusedNumbers = this.state.settings.pattern.unusedLetters.length * 15;
      returnValue = returnValue - unusedNumbers;
    }
    return returnValue;
  }

  getLayout = () => {
    switch (this.state.layout) {
      case "vertical":
        return <Vertical board={this.state.board} called={this.state.called} currentCall={this.state.currentCall} layout={this.state.settings.layout} previousCall={this.state.previousCall} rotatePatterns={this.state.settings.showMainScreenRotatingPatterns} running={this.isGameRunning} screenSize={this.state.screenSize} settings={this.state.settings} totalCalls={this.calledBallCount} />;
      case "stacked":
        return <Stacked board={this.state.board} called={this.state.called} currentCall={this.state.currentCall} layout={this.state.settings.layout} previousCall={this.state.previousCall} rotatePatterns={this.state.settings.showMainScreenRotatingPatterns} running={this.isGameRunning} screenSize={this.state.screenSize} settings={this.state.settings} totalCalls={this.calledBallCount} />;
      case "classic-reverse":
        return <ClassicReversed board={this.state.board} called={this.state.called} currentCall={this.state.currentCall} layout={this.state.settings.layout} previousCall={this.state.previousCall} rotatePatterns={this.state.settings.showMainScreenRotatingPatterns} running={this.isGameRunning} screenSize={this.state.screenSize} settings={this.state.settings} totalCalls={this.calledBallCount} />;
      case "classic":
      default:
        return <Classic board={this.state.board} called={this.state.called} currentCall={this.state.currentCall} layout={this.state.settings.layout} previousCall={this.state.previousCall} rotatePatterns={this.state.settings.showMainScreenRotatingPatterns} running={this.isGameRunning} screenSize={this.state.screenSize} settings={this.state.settings} totalCalls={this.calledBallCount} />;
    }
  };

  // Renderer --------------------------------------------

  render() {
    return (
      <div className="caller-block">
        <Settings totalCalls={this.calledBallCount} settings={this.state.settings} />
        {this.getLayout()}
      </div>
    );
  }
}

export default Caller;
