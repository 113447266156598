import React from "react";
import Pattern from "../components/Pattern";
import { NavLink } from "react-router-dom";
import { getPresetPatterns } from "../helpers/PresetPatterns";
import { getCache, checkCacheForSetting, updateCacheValue } from "../helpers/CacheManagement";
import Toggle from "../components/Toggle";
import "../sass/patternlist.scss";

const presets = getPresetPatterns();

class Patterns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPattern: checkCacheForSetting("pattern"),
      searchTerm: "",
      showRotation: true,
    };
    this.settings = getCache().settings;
    this.presetList = presets.slice(1);
  }

  componentDidMount() {
    document.title = "Let's Play Bingo! | Patterns";
    document.body.addEventListener("scroll", () => {
      if (document.body.scrollTop > 80) {
        document.getElementById("title-bar").classList.add("freeze");
      } else {
        document.getElementById("title-bar").classList.remove("freeze");
      }
    });
    document.addEventListener("togglerotation", (event) => {
      const property = event.detail.property;
      const value = event.detail.value;
      this.setState({ [property]: value });
    });
  }

  componentWillUnmount() {
    document.body.removeEventListener("scroll", () => {
      if (document.body.scrollTop > 80) {
        document.getElementById("title-bar").classList.add("freeze");
      } else {
        document.getElementById("title-bar").classList.remove("freeze");
      }
    });
    document.removeEventListener("togglerotation", (event) => {
      const property = event.detail.property;
      const value = event.detail.value;
      this.setState({ [property]: value });
    });
  }

  updateSelectedPattern = (pattern) => {
    this.setState({ selectedPattern: pattern });
    this.settings.pattern = pattern;
    updateCacheValue("settings", this.settings);
  };

  handleSearch = (event) => {
    this.setState({ searchTerm: event.currentTarget.value });
  };

  get visiblePatterns() {
    const searchTerm = this.state.searchTerm.toLowerCase();
    if (searchTerm === "") {
      return this.presetList;
    } else {
      let patternsToReturn = [];
      this.presetList.forEach((preset) => {
        let values = preset.value + "; " + preset.label;
        if (preset.credit) {
          values += "; " + preset.credit;
        }
        if (Object.prototype.hasOwnProperty.call(preset, "otherNames")) {
          values += preset.otherNames.join(";");
        }
        if (values.toLowerCase().includes(searchTerm)) {
          patternsToReturn.push(preset);
        }
      });
      return patternsToReturn;
    }
  }

  render() {
    return (
      <section className="pattern-list">
        <div className="row container gutters-sm">
          <div className="col full-width no-print">
            <div id="title-bar" className="row align-center">
              <div className="col shrink">
                <h1>Patterns</h1>
              </div>
              <div className="col grow">
                <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                  <button aria-label="Back to Game" className="primary-button">
                    Back to Game
                  </button>
                </NavLink>
                <button
                  aria-label="Print Patterns"
                  className="secondary-button"
                  onClick={() => {
                    window.print();
                    return false;
                  }}>
                  Print Patterns
                </button>
              </div>
            </div>
            <div className="row align-center justify-spread no-print margin-bottom-xlg">
              <div className="col grow padding-horizontal-xxlg">
                <p>Below you will find the full list of preset patterns available for play! Below each pattern it will have the pattern name along with credit information if the pattern was submitted by a player, and if your game uses the Skip Unused Numbers setting it will display which letter columns will be skipped. Clicking on a pattern will set that as the pattern in your current game.</p>
                <p>
                  New patterns were added on <strong>5/8/2024</strong>. <NavLink to="/submitpattern">Submit yours today!</NavLink>
                </p>
              </div>
              <div className="col shrink total-patterns">
                <div className="pattern-count">{presets.length}</div>
                <div className="heading">Total Patterns</div>
              </div>
            </div>
            <div className="row align-end justify-spread no-print margin-bottom-xlg">
              <div className="col grow">
                <label for="searchTerm">Search Patterns:</label>
                <input name="searchTerm" value={this.state.searchTerm} placeholder="Enter pattern name or person credited..." onChange={this.handleSearch} />
              </div>
              <div className="col">
                <Toggle disabled={false} eventName="togglerotation" label="Show winning pattern rotations" name="showRotation" value={this.state.showRotation} />
              </div>
            </div>
          </div>
          {this.visiblePatterns.map((preset) => {
            return (
              <div key={preset.value} className="pattern-col col padding-bottom-md">
                <Pattern rotate={this.state.showRotation} editable={false} pattern={preset} selected={this.state.selectedPattern.value === preset.value} showTitle={true} titlePosition="bottom" showCredit={true} showUnusedLetters={true} selectionHandler={this.updateSelectedPattern} />
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

export default Patterns;
