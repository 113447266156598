import matthew0 from "./matthew/0.mp3";
import matthew1 from "./matthew/1.mp3";
import matthew2 from "./matthew/2.mp3";
import matthew3 from "./matthew/3.mp3";
import matthew4 from "./matthew/4.mp3";
import matthew5 from "./matthew/5.mp3";
import matthew6 from "./matthew/6.mp3";
import matthew7 from "./matthew/7.mp3";
import matthew8 from "./matthew/8.mp3";
import matthew9 from "./matthew/9.mp3";
import matthew10 from "./matthew/10.mp3";
import matthew11 from "./matthew/11.mp3";
import matthew12 from "./matthew/12.mp3";
import matthew13 from "./matthew/13.mp3";
import matthew14 from "./matthew/14.mp3";
import matthew15 from "./matthew/15.mp3";
import matthew16 from "./matthew/16.mp3";
import matthew17 from "./matthew/17.mp3";
import matthew18 from "./matthew/18.mp3";
import matthew19 from "./matthew/19.mp3";
import matthew20 from "./matthew/20.mp3";
import matthew21 from "./matthew/21.mp3";
import matthew22 from "./matthew/22.mp3";
import matthew23 from "./matthew/23.mp3";
import matthew24 from "./matthew/24.mp3";
import matthew25 from "./matthew/25.mp3";
import matthew26 from "./matthew/26.mp3";
import matthew27 from "./matthew/27.mp3";
import matthew28 from "./matthew/28.mp3";
import matthew29 from "./matthew/29.mp3";
import matthew30 from "./matthew/30.mp3";
import matthew31 from "./matthew/31.mp3";
import matthew32 from "./matthew/32.mp3";
import matthew33 from "./matthew/33.mp3";
import matthew34 from "./matthew/34.mp3";
import matthew35 from "./matthew/35.mp3";
import matthew36 from "./matthew/36.mp3";
import matthew37 from "./matthew/37.mp3";
import matthew38 from "./matthew/38.mp3";
import matthew39 from "./matthew/39.mp3";
import matthew40 from "./matthew/40.mp3";
import matthew41 from "./matthew/41.mp3";
import matthew42 from "./matthew/42.mp3";
import matthew43 from "./matthew/43.mp3";
import matthew44 from "./matthew/44.mp3";
import matthew45 from "./matthew/45.mp3";
import matthew46 from "./matthew/46.mp3";
import matthew47 from "./matthew/47.mp3";
import matthew48 from "./matthew/48.mp3";
import matthew49 from "./matthew/49.mp3";
import matthew50 from "./matthew/50.mp3";
import matthew51 from "./matthew/51.mp3";
import matthew52 from "./matthew/52.mp3";
import matthew53 from "./matthew/53.mp3";
import matthew54 from "./matthew/54.mp3";
import matthew55 from "./matthew/55.mp3";
import matthew56 from "./matthew/56.mp3";
import matthew57 from "./matthew/57.mp3";
import matthew58 from "./matthew/58.mp3";
import matthew59 from "./matthew/59.mp3";
import matthew60 from "./matthew/60.mp3";
import matthew61 from "./matthew/61.mp3";
import matthew62 from "./matthew/62.mp3";
import matthew63 from "./matthew/63.mp3";
import matthew64 from "./matthew/64.mp3";
import matthew65 from "./matthew/65.mp3";
import matthew66 from "./matthew/66.mp3";
import matthew67 from "./matthew/67.mp3";
import matthew68 from "./matthew/68.mp3";
import matthew69 from "./matthew/69.mp3";
import matthew70 from "./matthew/70.mp3";
import matthew71 from "./matthew/71.mp3";
import matthew72 from "./matthew/72.mp3";
import matthew73 from "./matthew/73.mp3";
import matthew74 from "./matthew/74.mp3";
import matthew75 from "./matthew/75.mp3";
import matthewhotball from "./matthew/hotball.mp3";
import matthewbyitself from "./matthew/byitself.mp3";
import matthewletsplaybingo from "./matthew/letsplaybingo.mp3";
import matthewb from "./matthew/b.mp3";
import matthewi from "./matthew/i.mp3";
import matthewn from "./matthew/n.mp3";
import matthewg from "./matthew/g.mp3";
import matthewo from "./matthew/o.mp3";
import matthewunderthe from "./matthew/underthe.mp3";

const matthew = {
  byitself: matthewbyitself,
  hotball: matthewhotball,
  letsplaybingo: matthewletsplaybingo,
  underthe: matthewunderthe,
  b: matthewb,
  i: matthewi,
  n: matthewn,
  g: matthewg,
  o: matthewo,
  0: matthew0,
  1: matthew1,
  2: matthew2,
  3: matthew3,
  4: matthew4,
  5: matthew5,
  6: matthew6,
  7: matthew7,
  8: matthew8,
  9: matthew9,
  10: matthew10,
  11: matthew11,
  12: matthew12,
  13: matthew13,
  14: matthew14,
  15: matthew15,
  16: matthew16,
  17: matthew17,
  18: matthew18,
  19: matthew19,
  20: matthew20,
  21: matthew21,
  22: matthew22,
  23: matthew23,
  24: matthew24,
  25: matthew25,
  26: matthew26,
  27: matthew27,
  28: matthew28,
  29: matthew29,
  30: matthew30,
  31: matthew31,
  32: matthew32,
  33: matthew33,
  34: matthew34,
  35: matthew35,
  36: matthew36,
  37: matthew37,
  38: matthew38,
  39: matthew39,
  40: matthew40,
  41: matthew41,
  42: matthew42,
  43: matthew43,
  44: matthew44,
  45: matthew45,
  46: matthew46,
  47: matthew47,
  48: matthew48,
  49: matthew49,
  50: matthew50,
  51: matthew51,
  52: matthew52,
  53: matthew53,
  54: matthew54,
  55: matthew55,
  56: matthew56,
  57: matthew57,
  58: matthew58,
  59: matthew59,
  60: matthew60,
  61: matthew61,
  62: matthew62,
  63: matthew63,
  64: matthew64,
  65: matthew65,
  66: matthew66,
  67: matthew67,
  68: matthew68,
  69: matthew69,
  70: matthew70,
  71: matthew71,
  72: matthew72,
  73: matthew73,
  74: matthew74,
  75: matthew75,
};

import salli0 from "./salli/0.mp3";
import salli1 from "./salli/1.mp3";
import salli2 from "./salli/2.mp3";
import salli3 from "./salli/3.mp3";
import salli4 from "./salli/4.mp3";
import salli5 from "./salli/5.mp3";
import salli6 from "./salli/6.mp3";
import salli7 from "./salli/7.mp3";
import salli8 from "./salli/8.mp3";
import salli9 from "./salli/9.mp3";
import salli10 from "./salli/10.mp3";
import salli11 from "./salli/11.mp3";
import salli12 from "./salli/12.mp3";
import salli13 from "./salli/13.mp3";
import salli14 from "./salli/14.mp3";
import salli15 from "./salli/15.mp3";
import salli16 from "./salli/16.mp3";
import salli17 from "./salli/17.mp3";
import salli18 from "./salli/18.mp3";
import salli19 from "./salli/19.mp3";
import salli20 from "./salli/20.mp3";
import salli21 from "./salli/21.mp3";
import salli22 from "./salli/22.mp3";
import salli23 from "./salli/23.mp3";
import salli24 from "./salli/24.mp3";
import salli25 from "./salli/25.mp3";
import salli26 from "./salli/26.mp3";
import salli27 from "./salli/27.mp3";
import salli28 from "./salli/28.mp3";
import salli29 from "./salli/29.mp3";
import salli30 from "./salli/30.mp3";
import salli31 from "./salli/31.mp3";
import salli32 from "./salli/32.mp3";
import salli33 from "./salli/33.mp3";
import salli34 from "./salli/34.mp3";
import salli35 from "./salli/35.mp3";
import salli36 from "./salli/36.mp3";
import salli37 from "./salli/37.mp3";
import salli38 from "./salli/38.mp3";
import salli39 from "./salli/39.mp3";
import salli40 from "./salli/40.mp3";
import salli41 from "./salli/41.mp3";
import salli42 from "./salli/42.mp3";
import salli43 from "./salli/43.mp3";
import salli44 from "./salli/44.mp3";
import salli45 from "./salli/45.mp3";
import salli46 from "./salli/46.mp3";
import salli47 from "./salli/47.mp3";
import salli48 from "./salli/48.mp3";
import salli49 from "./salli/49.mp3";
import salli50 from "./salli/50.mp3";
import salli51 from "./salli/51.mp3";
import salli52 from "./salli/52.mp3";
import salli53 from "./salli/53.mp3";
import salli54 from "./salli/54.mp3";
import salli55 from "./salli/55.mp3";
import salli56 from "./salli/56.mp3";
import salli57 from "./salli/57.mp3";
import salli58 from "./salli/58.mp3";
import salli59 from "./salli/59.mp3";
import salli60 from "./salli/60.mp3";
import salli61 from "./salli/61.mp3";
import salli62 from "./salli/62.mp3";
import salli63 from "./salli/63.mp3";
import salli64 from "./salli/64.mp3";
import salli65 from "./salli/65.mp3";
import salli66 from "./salli/66.mp3";
import salli67 from "./salli/67.mp3";
import salli68 from "./salli/68.mp3";
import salli69 from "./salli/69.mp3";
import salli70 from "./salli/70.mp3";
import salli71 from "./salli/71.mp3";
import salli72 from "./salli/72.mp3";
import salli73 from "./salli/73.mp3";
import salli74 from "./salli/74.mp3";
import salli75 from "./salli/75.mp3";
import sallihotball from "./salli/hotball.mp3";
import sallibyitself from "./salli/byitself.mp3";
import salliletsplaybingo from "./salli/letsplaybingo.mp3";
import sallib from "./salli/b.mp3";
import sallii from "./salli/i.mp3";
import sallin from "./salli/n.mp3";
import sallig from "./salli/g.mp3";
import sallio from "./salli/o.mp3";
import salliunderthe from "./salli/underthe.mp3";

const salli = {
  byitself: sallibyitself,
  hotball: sallihotball,
  letsplaybingo: salliletsplaybingo,
  underthe: salliunderthe,
  b: sallib,
  i: sallii,
  n: sallin,
  g: sallig,
  o: sallio,
  0: salli0,
  1: salli1,
  2: salli2,
  3: salli3,
  4: salli4,
  5: salli5,
  6: salli6,
  7: salli7,
  8: salli8,
  9: salli9,
  10: salli10,
  11: salli11,
  12: salli12,
  13: salli13,
  14: salli14,
  15: salli15,
  16: salli16,
  17: salli17,
  18: salli18,
  19: salli19,
  20: salli20,
  21: salli21,
  22: salli22,
  23: salli23,
  24: salli24,
  25: salli25,
  26: salli26,
  27: salli27,
  28: salli28,
  29: salli29,
  30: salli30,
  31: salli31,
  32: salli32,
  33: salli33,
  34: salli34,
  35: salli35,
  36: salli36,
  37: salli37,
  38: salli38,
  39: salli39,
  40: salli40,
  41: salli41,
  42: salli42,
  43: salli43,
  44: salli44,
  45: salli45,
  46: salli46,
  47: salli47,
  48: salli48,
  49: salli49,
  50: salli50,
  51: salli51,
  52: salli52,
  53: salli53,
  54: salli54,
  55: salli55,
  56: salli56,
  57: salli57,
  58: salli58,
  59: salli59,
  60: salli60,
  61: salli61,
  62: salli62,
  63: salli63,
  64: salli64,
  65: salli65,
  66: salli66,
  67: salli67,
  68: salli68,
  69: salli69,
  70: salli70,
  71: salli71,
  72: salli72,
  73: salli73,
  74: salli74,
  75: salli75,
};

import mathieu0 from "./mathieu/0.mp3";
import mathieu1 from "./mathieu/1.mp3";
import mathieu2 from "./mathieu/2.mp3";
import mathieu3 from "./mathieu/3.mp3";
import mathieu4 from "./mathieu/4.mp3";
import mathieu5 from "./mathieu/5.mp3";
import mathieu6 from "./mathieu/6.mp3";
import mathieu7 from "./mathieu/7.mp3";
import mathieu8 from "./mathieu/8.mp3";
import mathieu9 from "./mathieu/9.mp3";
import mathieu10 from "./mathieu/10.mp3";
import mathieu11 from "./mathieu/11.mp3";
import mathieu12 from "./mathieu/12.mp3";
import mathieu13 from "./mathieu/13.mp3";
import mathieu14 from "./mathieu/14.mp3";
import mathieu15 from "./mathieu/15.mp3";
import mathieu16 from "./mathieu/16.mp3";
import mathieu17 from "./mathieu/17.mp3";
import mathieu18 from "./mathieu/18.mp3";
import mathieu19 from "./mathieu/19.mp3";
import mathieu20 from "./mathieu/20.mp3";
import mathieu21 from "./mathieu/21.mp3";
import mathieu22 from "./mathieu/22.mp3";
import mathieu23 from "./mathieu/23.mp3";
import mathieu24 from "./mathieu/24.mp3";
import mathieu25 from "./mathieu/25.mp3";
import mathieu26 from "./mathieu/26.mp3";
import mathieu27 from "./mathieu/27.mp3";
import mathieu28 from "./mathieu/28.mp3";
import mathieu29 from "./mathieu/29.mp3";
import mathieu30 from "./mathieu/30.mp3";
import mathieu31 from "./mathieu/31.mp3";
import mathieu32 from "./mathieu/32.mp3";
import mathieu33 from "./mathieu/33.mp3";
import mathieu34 from "./mathieu/34.mp3";
import mathieu35 from "./mathieu/35.mp3";
import mathieu36 from "./mathieu/36.mp3";
import mathieu37 from "./mathieu/37.mp3";
import mathieu38 from "./mathieu/38.mp3";
import mathieu39 from "./mathieu/39.mp3";
import mathieu40 from "./mathieu/40.mp3";
import mathieu41 from "./mathieu/41.mp3";
import mathieu42 from "./mathieu/42.mp3";
import mathieu43 from "./mathieu/43.mp3";
import mathieu44 from "./mathieu/44.mp3";
import mathieu45 from "./mathieu/45.mp3";
import mathieu46 from "./mathieu/46.mp3";
import mathieu47 from "./mathieu/47.mp3";
import mathieu48 from "./mathieu/48.mp3";
import mathieu49 from "./mathieu/49.mp3";
import mathieu50 from "./mathieu/50.mp3";
import mathieu51 from "./mathieu/51.mp3";
import mathieu52 from "./mathieu/52.mp3";
import mathieu53 from "./mathieu/53.mp3";
import mathieu54 from "./mathieu/54.mp3";
import mathieu55 from "./mathieu/55.mp3";
import mathieu56 from "./mathieu/56.mp3";
import mathieu57 from "./mathieu/57.mp3";
import mathieu58 from "./mathieu/58.mp3";
import mathieu59 from "./mathieu/59.mp3";
import mathieu60 from "./mathieu/60.mp3";
import mathieu61 from "./mathieu/61.mp3";
import mathieu62 from "./mathieu/62.mp3";
import mathieu63 from "./mathieu/63.mp3";
import mathieu64 from "./mathieu/64.mp3";
import mathieu65 from "./mathieu/65.mp3";
import mathieu66 from "./mathieu/66.mp3";
import mathieu67 from "./mathieu/67.mp3";
import mathieu68 from "./mathieu/68.mp3";
import mathieu69 from "./mathieu/69.mp3";
import mathieu70 from "./mathieu/70.mp3";
import mathieu71 from "./mathieu/71.mp3";
import mathieu72 from "./mathieu/72.mp3";
import mathieu73 from "./mathieu/73.mp3";
import mathieu74 from "./mathieu/74.mp3";
import mathieu75 from "./mathieu/75.mp3";
import mathieuhotball from "./mathieu/hotball.mp3";
import mathieubyitself from "./mathieu/byitself.mp3";
import mathieuletsplaybingo from "./mathieu/letsplaybingo.mp3";
import mathieub from "./mathieu/b.mp3";
import mathieui from "./mathieu/i.mp3";
import mathieun from "./mathieu/n.mp3";
import mathieug from "./mathieu/g.mp3";
import mathieuo from "./mathieu/o.mp3";
import mathieuunderthe from "./mathieu/underthe.mp3";

const mathieu = {
  byitself: mathieubyitself,
  hotball: mathieuhotball,
  letsplaybingo: mathieuletsplaybingo,
  underthe: mathieuunderthe,
  b: mathieub,
  i: mathieui,
  n: mathieun,
  g: mathieug,
  o: mathieuo,
  0: mathieu0,
  1: mathieu1,
  2: mathieu2,
  3: mathieu3,
  4: mathieu4,
  5: mathieu5,
  6: mathieu6,
  7: mathieu7,
  8: mathieu8,
  9: mathieu9,
  10: mathieu10,
  11: mathieu11,
  12: mathieu12,
  13: mathieu13,
  14: mathieu14,
  15: mathieu15,
  16: mathieu16,
  17: mathieu17,
  18: mathieu18,
  19: mathieu19,
  20: mathieu20,
  21: mathieu21,
  22: mathieu22,
  23: mathieu23,
  24: mathieu24,
  25: mathieu25,
  26: mathieu26,
  27: mathieu27,
  28: mathieu28,
  29: mathieu29,
  30: mathieu30,
  31: mathieu31,
  32: mathieu32,
  33: mathieu33,
  34: mathieu34,
  35: mathieu35,
  36: mathieu36,
  37: mathieu37,
  38: mathieu38,
  39: mathieu39,
  40: mathieu40,
  41: mathieu41,
  42: mathieu42,
  43: mathieu43,
  44: mathieu44,
  45: mathieu45,
  46: mathieu46,
  47: mathieu47,
  48: mathieu48,
  49: mathieu49,
  50: mathieu50,
  51: mathieu51,
  52: mathieu52,
  53: mathieu53,
  54: mathieu54,
  55: mathieu55,
  56: mathieu56,
  57: mathieu57,
  58: mathieu58,
  59: mathieu59,
  60: mathieu60,
  61: mathieu61,
  62: mathieu62,
  63: mathieu63,
  64: mathieu64,
  65: mathieu65,
  66: mathieu66,
  67: mathieu67,
  68: mathieu68,
  69: mathieu69,
  70: mathieu70,
  71: mathieu71,
  72: mathieu72,
  73: mathieu73,
  74: mathieu74,
  75: mathieu75,
};

import lea0 from "./lea/0.mp3";
import lea1 from "./lea/1.mp3";
import lea2 from "./lea/2.mp3";
import lea3 from "./lea/3.mp3";
import lea4 from "./lea/4.mp3";
import lea5 from "./lea/5.mp3";
import lea6 from "./lea/6.mp3";
import lea7 from "./lea/7.mp3";
import lea8 from "./lea/8.mp3";
import lea9 from "./lea/9.mp3";
import lea10 from "./lea/10.mp3";
import lea11 from "./lea/11.mp3";
import lea12 from "./lea/12.mp3";
import lea13 from "./lea/13.mp3";
import lea14 from "./lea/14.mp3";
import lea15 from "./lea/15.mp3";
import lea16 from "./lea/16.mp3";
import lea17 from "./lea/17.mp3";
import lea18 from "./lea/18.mp3";
import lea19 from "./lea/19.mp3";
import lea20 from "./lea/20.mp3";
import lea21 from "./lea/21.mp3";
import lea22 from "./lea/22.mp3";
import lea23 from "./lea/23.mp3";
import lea24 from "./lea/24.mp3";
import lea25 from "./lea/25.mp3";
import lea26 from "./lea/26.mp3";
import lea27 from "./lea/27.mp3";
import lea28 from "./lea/28.mp3";
import lea29 from "./lea/29.mp3";
import lea30 from "./lea/30.mp3";
import lea31 from "./lea/31.mp3";
import lea32 from "./lea/32.mp3";
import lea33 from "./lea/33.mp3";
import lea34 from "./lea/34.mp3";
import lea35 from "./lea/35.mp3";
import lea36 from "./lea/36.mp3";
import lea37 from "./lea/37.mp3";
import lea38 from "./lea/38.mp3";
import lea39 from "./lea/39.mp3";
import lea40 from "./lea/40.mp3";
import lea41 from "./lea/41.mp3";
import lea42 from "./lea/42.mp3";
import lea43 from "./lea/43.mp3";
import lea44 from "./lea/44.mp3";
import lea45 from "./lea/45.mp3";
import lea46 from "./lea/46.mp3";
import lea47 from "./lea/47.mp3";
import lea48 from "./lea/48.mp3";
import lea49 from "./lea/49.mp3";
import lea50 from "./lea/50.mp3";
import lea51 from "./lea/51.mp3";
import lea52 from "./lea/52.mp3";
import lea53 from "./lea/53.mp3";
import lea54 from "./lea/54.mp3";
import lea55 from "./lea/55.mp3";
import lea56 from "./lea/56.mp3";
import lea57 from "./lea/57.mp3";
import lea58 from "./lea/58.mp3";
import lea59 from "./lea/59.mp3";
import lea60 from "./lea/60.mp3";
import lea61 from "./lea/61.mp3";
import lea62 from "./lea/62.mp3";
import lea63 from "./lea/63.mp3";
import lea64 from "./lea/64.mp3";
import lea65 from "./lea/65.mp3";
import lea66 from "./lea/66.mp3";
import lea67 from "./lea/67.mp3";
import lea68 from "./lea/68.mp3";
import lea69 from "./lea/69.mp3";
import lea70 from "./lea/70.mp3";
import lea71 from "./lea/71.mp3";
import lea72 from "./lea/72.mp3";
import lea73 from "./lea/73.mp3";
import lea74 from "./lea/74.mp3";
import lea75 from "./lea/75.mp3";
import leahotball from "./lea/hotball.mp3";
import leabyitself from "./lea/byitself.mp3";
import lealetsplaybingo from "./lea/letsplaybingo.mp3";
import leab from "./lea/b.mp3";
import leai from "./lea/i.mp3";
import lean from "./lea/n.mp3";
import leag from "./lea/g.mp3";
import leao from "./lea/o.mp3";
import leaunderthe from "./lea/underthe.mp3";

const lea = {
  byitself: leabyitself,
  hotball: leahotball,
  letsplaybingo: lealetsplaybingo,
  underthe: leaunderthe,
  b: leab,
  i: leai,
  n: lean,
  g: leag,
  o: leao,
  0: lea0,
  1: lea1,
  2: lea2,
  3: lea3,
  4: lea4,
  5: lea5,
  6: lea6,
  7: lea7,
  8: lea8,
  9: lea9,
  10: lea10,
  11: lea11,
  12: lea12,
  13: lea13,
  14: lea14,
  15: lea15,
  16: lea16,
  17: lea17,
  18: lea18,
  19: lea19,
  20: lea20,
  21: lea21,
  22: lea22,
  23: lea23,
  24: lea24,
  25: lea25,
  26: lea26,
  27: lea27,
  28: lea28,
  29: lea29,
  30: lea30,
  31: lea31,
  32: lea32,
  33: lea33,
  34: lea34,
  35: lea35,
  36: lea36,
  37: lea37,
  38: lea38,
  39: lea39,
  40: lea40,
  41: lea41,
  42: lea42,
  43: lea43,
  44: lea44,
  45: lea45,
  46: lea46,
  47: lea47,
  48: lea48,
  49: lea49,
  50: lea50,
  51: lea51,
  52: lea52,
  53: lea53,
  54: lea54,
  55: lea55,
  56: lea56,
  57: lea57,
  58: lea58,
  59: lea59,
  60: lea60,
  61: lea61,
  62: lea62,
  63: lea63,
  64: lea64,
  65: lea65,
  66: lea66,
  67: lea67,
  68: lea68,
  69: lea69,
  70: lea70,
  71: lea71,
  72: lea72,
  73: lea73,
  74: lea74,
  75: lea75,
};

import miguel0 from "./miguel/0.mp3";
import miguel1 from "./miguel/1.mp3";
import miguel2 from "./miguel/2.mp3";
import miguel3 from "./miguel/3.mp3";
import miguel4 from "./miguel/4.mp3";
import miguel5 from "./miguel/5.mp3";
import miguel6 from "./miguel/6.mp3";
import miguel7 from "./miguel/7.mp3";
import miguel8 from "./miguel/8.mp3";
import miguel9 from "./miguel/9.mp3";
import miguel10 from "./miguel/10.mp3";
import miguel11 from "./miguel/11.mp3";
import miguel12 from "./miguel/12.mp3";
import miguel13 from "./miguel/13.mp3";
import miguel14 from "./miguel/14.mp3";
import miguel15 from "./miguel/15.mp3";
import miguel16 from "./miguel/16.mp3";
import miguel17 from "./miguel/17.mp3";
import miguel18 from "./miguel/18.mp3";
import miguel19 from "./miguel/19.mp3";
import miguel20 from "./miguel/20.mp3";
import miguel21 from "./miguel/21.mp3";
import miguel22 from "./miguel/22.mp3";
import miguel23 from "./miguel/23.mp3";
import miguel24 from "./miguel/24.mp3";
import miguel25 from "./miguel/25.mp3";
import miguel26 from "./miguel/26.mp3";
import miguel27 from "./miguel/27.mp3";
import miguel28 from "./miguel/28.mp3";
import miguel29 from "./miguel/29.mp3";
import miguel30 from "./miguel/30.mp3";
import miguel31 from "./miguel/31.mp3";
import miguel32 from "./miguel/32.mp3";
import miguel33 from "./miguel/33.mp3";
import miguel34 from "./miguel/34.mp3";
import miguel35 from "./miguel/35.mp3";
import miguel36 from "./miguel/36.mp3";
import miguel37 from "./miguel/37.mp3";
import miguel38 from "./miguel/38.mp3";
import miguel39 from "./miguel/39.mp3";
import miguel40 from "./miguel/40.mp3";
import miguel41 from "./miguel/41.mp3";
import miguel42 from "./miguel/42.mp3";
import miguel43 from "./miguel/43.mp3";
import miguel44 from "./miguel/44.mp3";
import miguel45 from "./miguel/45.mp3";
import miguel46 from "./miguel/46.mp3";
import miguel47 from "./miguel/47.mp3";
import miguel48 from "./miguel/48.mp3";
import miguel49 from "./miguel/49.mp3";
import miguel50 from "./miguel/50.mp3";
import miguel51 from "./miguel/51.mp3";
import miguel52 from "./miguel/52.mp3";
import miguel53 from "./miguel/53.mp3";
import miguel54 from "./miguel/54.mp3";
import miguel55 from "./miguel/55.mp3";
import miguel56 from "./miguel/56.mp3";
import miguel57 from "./miguel/57.mp3";
import miguel58 from "./miguel/58.mp3";
import miguel59 from "./miguel/59.mp3";
import miguel60 from "./miguel/60.mp3";
import miguel61 from "./miguel/61.mp3";
import miguel62 from "./miguel/62.mp3";
import miguel63 from "./miguel/63.mp3";
import miguel64 from "./miguel/64.mp3";
import miguel65 from "./miguel/65.mp3";
import miguel66 from "./miguel/66.mp3";
import miguel67 from "./miguel/67.mp3";
import miguel68 from "./miguel/68.mp3";
import miguel69 from "./miguel/69.mp3";
import miguel70 from "./miguel/70.mp3";
import miguel71 from "./miguel/71.mp3";
import miguel72 from "./miguel/72.mp3";
import miguel73 from "./miguel/73.mp3";
import miguel74 from "./miguel/74.mp3";
import miguel75 from "./miguel/75.mp3";
import miguelhotball from "./miguel/hotball.mp3";
import miguelbyitself from "./miguel/byitself.mp3";
import miguelletsplaybingo from "./miguel/letsplaybingo.mp3";
import miguelb from "./miguel/b.mp3";
import migueli from "./miguel/i.mp3";
import migueln from "./miguel/n.mp3";
import miguelg from "./miguel/g.mp3";
import miguelo from "./miguel/o.mp3";
import miguelunderthe from "./miguel/underthe.mp3";

const miguel = {
  byitself: miguelbyitself,
  hotball: miguelhotball,
  letsplaybingo: miguelletsplaybingo,
  underthe: miguelunderthe,
  b: miguelb,
  i: migueli,
  n: migueln,
  g: miguelg,
  o: miguelo,
  0: miguel0,
  1: miguel1,
  2: miguel2,
  3: miguel3,
  4: miguel4,
  5: miguel5,
  6: miguel6,
  7: miguel7,
  8: miguel8,
  9: miguel9,
  10: miguel10,
  11: miguel11,
  12: miguel12,
  13: miguel13,
  14: miguel14,
  15: miguel15,
  16: miguel16,
  17: miguel17,
  18: miguel18,
  19: miguel19,
  20: miguel20,
  21: miguel21,
  22: miguel22,
  23: miguel23,
  24: miguel24,
  25: miguel25,
  26: miguel26,
  27: miguel27,
  28: miguel28,
  29: miguel29,
  30: miguel30,
  31: miguel31,
  32: miguel32,
  33: miguel33,
  34: miguel34,
  35: miguel35,
  36: miguel36,
  37: miguel37,
  38: miguel38,
  39: miguel39,
  40: miguel40,
  41: miguel41,
  42: miguel42,
  43: miguel43,
  44: miguel44,
  45: miguel45,
  46: miguel46,
  47: miguel47,
  48: miguel48,
  49: miguel49,
  50: miguel50,
  51: miguel51,
  52: miguel52,
  53: miguel53,
  54: miguel54,
  55: miguel55,
  56: miguel56,
  57: miguel57,
  58: miguel58,
  59: miguel59,
  60: miguel60,
  61: miguel61,
  62: miguel62,
  63: miguel63,
  64: miguel64,
  65: miguel65,
  66: miguel66,
  67: miguel67,
  68: miguel68,
  69: miguel69,
  70: miguel70,
  71: miguel71,
  72: miguel72,
  73: miguel73,
  74: miguel74,
  75: miguel75,
};

import penelope0 from "./penelope/0.mp3";
import penelope1 from "./penelope/1.mp3";
import penelope2 from "./penelope/2.mp3";
import penelope3 from "./penelope/3.mp3";
import penelope4 from "./penelope/4.mp3";
import penelope5 from "./penelope/5.mp3";
import penelope6 from "./penelope/6.mp3";
import penelope7 from "./penelope/7.mp3";
import penelope8 from "./penelope/8.mp3";
import penelope9 from "./penelope/9.mp3";
import penelope10 from "./penelope/10.mp3";
import penelope11 from "./penelope/11.mp3";
import penelope12 from "./penelope/12.mp3";
import penelope13 from "./penelope/13.mp3";
import penelope14 from "./penelope/14.mp3";
import penelope15 from "./penelope/15.mp3";
import penelope16 from "./penelope/16.mp3";
import penelope17 from "./penelope/17.mp3";
import penelope18 from "./penelope/18.mp3";
import penelope19 from "./penelope/19.mp3";
import penelope20 from "./penelope/20.mp3";
import penelope21 from "./penelope/21.mp3";
import penelope22 from "./penelope/22.mp3";
import penelope23 from "./penelope/23.mp3";
import penelope24 from "./penelope/24.mp3";
import penelope25 from "./penelope/25.mp3";
import penelope26 from "./penelope/26.mp3";
import penelope27 from "./penelope/27.mp3";
import penelope28 from "./penelope/28.mp3";
import penelope29 from "./penelope/29.mp3";
import penelope30 from "./penelope/30.mp3";
import penelope31 from "./penelope/31.mp3";
import penelope32 from "./penelope/32.mp3";
import penelope33 from "./penelope/33.mp3";
import penelope34 from "./penelope/34.mp3";
import penelope35 from "./penelope/35.mp3";
import penelope36 from "./penelope/36.mp3";
import penelope37 from "./penelope/37.mp3";
import penelope38 from "./penelope/38.mp3";
import penelope39 from "./penelope/39.mp3";
import penelope40 from "./penelope/40.mp3";
import penelope41 from "./penelope/41.mp3";
import penelope42 from "./penelope/42.mp3";
import penelope43 from "./penelope/43.mp3";
import penelope44 from "./penelope/44.mp3";
import penelope45 from "./penelope/45.mp3";
import penelope46 from "./penelope/46.mp3";
import penelope47 from "./penelope/47.mp3";
import penelope48 from "./penelope/48.mp3";
import penelope49 from "./penelope/49.mp3";
import penelope50 from "./penelope/50.mp3";
import penelope51 from "./penelope/51.mp3";
import penelope52 from "./penelope/52.mp3";
import penelope53 from "./penelope/53.mp3";
import penelope54 from "./penelope/54.mp3";
import penelope55 from "./penelope/55.mp3";
import penelope56 from "./penelope/56.mp3";
import penelope57 from "./penelope/57.mp3";
import penelope58 from "./penelope/58.mp3";
import penelope59 from "./penelope/59.mp3";
import penelope60 from "./penelope/60.mp3";
import penelope61 from "./penelope/61.mp3";
import penelope62 from "./penelope/62.mp3";
import penelope63 from "./penelope/63.mp3";
import penelope64 from "./penelope/64.mp3";
import penelope65 from "./penelope/65.mp3";
import penelope66 from "./penelope/66.mp3";
import penelope67 from "./penelope/67.mp3";
import penelope68 from "./penelope/68.mp3";
import penelope69 from "./penelope/69.mp3";
import penelope70 from "./penelope/70.mp3";
import penelope71 from "./penelope/71.mp3";
import penelope72 from "./penelope/72.mp3";
import penelope73 from "./penelope/73.mp3";
import penelope74 from "./penelope/74.mp3";
import penelope75 from "./penelope/75.mp3";
import penelopehotball from "./penelope/hotball.mp3";
import penelopebyitself from "./penelope/byitself.mp3";
import penelopeletsplaybingo from "./penelope/letsplaybingo.mp3";
import penelopeb from "./penelope/b.mp3";
import penelopei from "./penelope/i.mp3";
import penelopen from "./penelope/n.mp3";
import penelopeg from "./penelope/g.mp3";
import penelopeo from "./penelope/o.mp3";
import penelopeunderthe from "./penelope/underthe.mp3";

const penelope = {
  byitself: penelopebyitself,
  hotball: penelopehotball,
  letsplaybingo: penelopeletsplaybingo,
  underthe: penelopeunderthe,
  b: penelopeb,
  i: penelopei,
  n: penelopen,
  g: penelopeg,
  o: penelopeo,
  0: penelope0,
  1: penelope1,
  2: penelope2,
  3: penelope3,
  4: penelope4,
  5: penelope5,
  6: penelope6,
  7: penelope7,
  8: penelope8,
  9: penelope9,
  10: penelope10,
  11: penelope11,
  12: penelope12,
  13: penelope13,
  14: penelope14,
  15: penelope15,
  16: penelope16,
  17: penelope17,
  18: penelope18,
  19: penelope19,
  20: penelope20,
  21: penelope21,
  22: penelope22,
  23: penelope23,
  24: penelope24,
  25: penelope25,
  26: penelope26,
  27: penelope27,
  28: penelope28,
  29: penelope29,
  30: penelope30,
  31: penelope31,
  32: penelope32,
  33: penelope33,
  34: penelope34,
  35: penelope35,
  36: penelope36,
  37: penelope37,
  38: penelope38,
  39: penelope39,
  40: penelope40,
  41: penelope41,
  42: penelope42,
  43: penelope43,
  44: penelope44,
  45: penelope45,
  46: penelope46,
  47: penelope47,
  48: penelope48,
  49: penelope49,
  50: penelope50,
  51: penelope51,
  52: penelope52,
  53: penelope53,
  54: penelope54,
  55: penelope55,
  56: penelope56,
  57: penelope57,
  58: penelope58,
  59: penelope59,
  60: penelope60,
  61: penelope61,
  62: penelope62,
  63: penelope63,
  64: penelope64,
  65: penelope65,
  66: penelope66,
  67: penelope67,
  68: penelope68,
  69: penelope69,
  70: penelope70,
  71: penelope71,
  72: penelope72,
  73: penelope73,
  74: penelope74,
  75: penelope75,
};

export { matthew, salli, mathieu, lea, miguel, penelope };
