export const bPatterns = [
  /* --------------------- B -------------------- */
  {
    value: "B and I",
    label: "B and I",
    otherNames: ["B & I"],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "G", "O"],
    credit: null,
  },
  {
    value: "B and O",
    label: "B and O",
    otherNames: ["B & O"],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "B and O (Alternate)",
    label: "B and O (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "B N O",
    label: "B N O",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "B Straight",
    label: "B Straight",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["I", "N", "G", "O"],
    credit: null,
  },
  {
    value: "Baby Turtle",
    label: "Baby Turtle",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, true, false],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Bad Bingo  (Last Person With No Marks Is The Winner)",
    label: "Bad Bingo  (Last Person With No Marks Is The Winner)",
    otherNames: ["Last Person Standing", "Last Man Standing", "Last Woman Standing"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bacon and Eggs",
    label: "Bacon and Eggs",
    otherNames: [],
    pattern: {
      B: [true, true, false, true, true],
      I: [true, true, false, true, true],
      N: [true, true, false, false, false],
      G: [true, true, false, true, true],
      O: [true, true, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Baseball",
    label: "Baseball",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, true],
      I: [false, false, false, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N", "G"],
    credit: null,
  },
  {
    value: "Basket",
    label: "Basket",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, false, true, true],
      N: [true, false, false, true, true],
      G: [false, true, false, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Beach Umbrella",
    label: "Beach Umbrella",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bell",
    label: "Bell",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bench",
    label: "Bench",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, true, true, true],
      N: [false, false, true, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "BIG",
    label: "BIG",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "O"],
    credit: null,
  },
  {
    value: "Big Dipper",
    label: "Big Dipper",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, true, true],
      G: [false, false, false, false, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Big G",
    label: "Big G",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Big I",
    label: "Big I",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Binary Count",
    label: "Binary Count",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, true, true],
      G: [false, true, true, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["B", "I"],
    credit: "Bob Versheck",
  },
  {
    value: "Bingo",
    label: "Bingo",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, true, false, true, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [true, false, true, false, true],
        N: [true, true, false, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, true, false, true],
        G: [true, false, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [true, false, false, false, true],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Birthday",
    label: "Birthday",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, true, true],
      N: [true, true, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Blackout",
    label: "Blackout",
    unusedLetters: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    otherNames: ["Coverall", "Full Card", "Block Out", "Black Out"],
    credit: null,
  },
  {
    value: "Block Of Six + Block Of Four",
    label: "Block Of Six + Block Of Four",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, true, true, false],
      G: [false, false, true, true, false],
      O: [false, false, true, true, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, true],
        N: [true, true, false, true, true],
        G: [true, true, false, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, true, true, true, false],
        O: [false, true, true, true, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "B,N,O Bingo",
    label: "B,N,O Bingo",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Bon-I",
    label: "Bon-I",
    pattern: {
      B: [false, false, false, true, true],
      I: [false, true, false, false, true],
      N: [true, true, true, true, true],
      G: [false, true, false, false, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bottom Half",
    label: "Bottom Half",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, false, true, true, true],
      N: [false, false, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bow and Arrow",
    label: "Bow and Arrow",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, true, true, true, true],
      N: [true, false, true, false, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bow Tie",
    label: "Bow Tie",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, true, true, false],
      N: [false, false, true, false, false],
      G: [false, true, true, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Bow Tie (Alternate)",
    label: "Bow Tie (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Bow Tie (Alternate 2)",
    label: "Bow Tie (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: ["N"],
    credit: "Thalia ",
  },
  {
    value: "Brackets",
    label: "Brackets",
    otherNames: [],
    pattern: {
      B: [true, true, false, true, true],
      I: [true, false, false, false, true],
      N: [false, false, false, false, false],
      G: [true, false, false, false, true],
      O: [true, true, false, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Bridge And A Boat",
    label: "Bridge And A Boat",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, false, true, false, false],
      N: [false, false, true, false, true],
      G: [false, false, true, false, false],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Broken Frame",
    label: "Broken Frame",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, false, false, false, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Broom",
    label: "Broom",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, true],
      N: [true, true, true, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Buckle",
    label: "Buckle",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, true, true],
      N: [true, true, true, false, true],
      G: [true, false, false, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: "Khristy ",
  },
  {
    value: "Bug",
    label: "Bug",
    otherNames: [],
    pattern: {
      B: [true, true, false, true, true],
      I: [false, true, false, true, false],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [true, true, false, true, true],
    },
    unusedLetters: [],
    credit: "Marianne Nissen",
  },
  {
    value: "Bullseye",
    label: "Bullseye",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Bunny",
    label: "Bunny",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Lacey Sistaz",
  },
  {
    value: "Bunny (Alternate)",
    label: "Bunny (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [true, true, true, false, true],
      N: [false, false, true, false, true],
      G: [true, true, true, false, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Lacey Sistaz",
  },
  {
    value: "Bunny Ears",
    label: "Bunny Ears",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [true, false, false, false, false],
      N: [false, true, true, true, true],
      G: [true, false, false, false, false],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Burst",
    label: "Burst",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Butterfly",
    label: "Butterfly",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, true, true, false],
      N: [false, true, false, true, false],
      G: [false, true, true, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
