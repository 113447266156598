import React from "react";
import { getLogo } from "../helpers/Utilities";
import { Link } from "react-router-dom";
// Components -----------------------------------------------
import Countdown from "../components/Countdown";
import BingoBoard from "../components/BingoBoard";
import CallInfo from "../components/CallInfo";
import Controls from "../components/Controls";
import CurrentCall from "../components/CurrentCall";
import CurrentPattern from "../components/CurrentPattern";
import PreviousCalls from "../components/PreviousCalls";

const Stacked = (props) => {
  return props.screenSize === "x-small" || props.screenSize === "small" ? (
    <main className={props.screenSize + " " + props.layout}>
      <div className="row gutters-md">
        <div className="col grow">
          <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
        </div>
      </div>
      <div className="margin-lg"></div>
      <div className="row align-center justify-start gutters-md">
        <div className="col cmp-current-call">
          <CurrentCall ball={props.currentCall} showTitle={true} />
        </div>
        <div className="col">
          <CurrentPattern
            disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
            eventName="patterndropdown"
            pattern={props.settings.pattern}
            rotatePatterns={props.rotatePatterns}
            showSelect={props.settings.showMainScreenPatternSelect}
            showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
            showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
          />
        </div>
        <div className="col shrink cmp-call-info">
          <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
          <Link to="/">{getLogo()}</Link>
        </div>
        <div className="col full-width cmp-controls">
          <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
        </div>
        <div className={props.settings.showCountdown ? "col shrink cmp-countdown" : "hide"}>
          <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
        </div>
        <div className={props.settings.showPreviousCalls ? "col grow cmp-previous-calls" : "hide"}>
          <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="horizontal" settings={props.settings} />
        </div>
      </div>
    </main>
  ) : (
    <main className={props.screenSize + " " + props.layout}>
      <div className="row gutters-md">
        <div className="col grow">
          <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
        </div>
      </div>
      <div className="margin-lg"></div>
      <div className="row align-stretch justify-start gutters-md">
        <div className="col shrink cmp-call-info">
          <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
        </div>
        <div className="col">
          <CurrentPattern
            disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
            eventName="patterndropdown"
            pattern={props.settings.pattern}
            rotatePatterns={props.rotatePatterns}
            showSelect={props.settings.showMainScreenPatternSelect}
            showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
            showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
          />
        </div>
        <div className="col shrink padding-horizontal-md cmp-controls home-link">
          <Link to="/">{getLogo()}</Link>
          <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
        </div>
        <div className="col cmp-current-call">
          <CurrentCall ball={props.currentCall} showTitle={true} />
        </div>
        <div className="col grow cmp-previous-calls">
          <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="horizontal" settings={props.settings} />
        </div>
        <div className={props.settings.showCountdown ? "col shrink cmp-countdown" : "hide"}>
          <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
        </div>
      </div>
    </main>
  );
};
export default Stacked;
