import React from "react";
import BingoCard from "../components/BingoCard";
import Select from "../components/Select";
import Input from "../components/Input";
import Toggle from "../components/Toggle";
import Switch from "../components/Switch";
import Pattern from "../components/Pattern";
import { getRandomNumberInRange } from "../helpers/Utilities";
import { NavLink } from "react-router-dom";
import { getPresetPatterns } from "../helpers/PresetPatterns";
const localStorageId = "lpb-card-options";

const colorOptions = [
  { value: "amber", label: "Amber" },
  { value: "amber-screen", label: "Amber Screen" },
  { value: "blue", label: "Blue" },
  { value: "blue-screen", label: "Blue Screen" },
  { value: "brown", label: "Brown" },
  { value: "brown-screen", label: "Brown Screen" },
  { value: "chartreuse", label: "Chartreuse" },
  { value: "chartreuse-screen", label: "Chartreuse Screen" },
  { value: "green", label: "Green" },
  { value: "green-screen", label: "Green Screen" },
  { value: "magenta", label: "Magenta" },
  { value: "magenta-screen", label: "Magenta Screen" },
  { value: "orange", label: "Orange" },
  { value: "orange-screen", label: "Orange Screen" },
  { value: "pink", label: "Pink" },
  { value: "pink-screen", label: "Pink Screen" },
  { value: "purple", label: "Purple" },
  { value: "purple-screen", label: "Purple Screen" },
  { value: "rainbow", label: "Rainbow" },
  { value: "rainbow-screen", label: "Rainbow Screen" },
  { value: "red", label: "Red" },
  { value: "red-screen", label: "Red Screen" },
  { value: "teal", label: "Teal" },
  { value: "teal-screen", label: "Teal Screen" },
  { value: "vermillion", label: "Vermillion" },
  { value: "vermillion-screen", label: "Vermillion Screen" },
  { value: "violet", label: "Violet" },
  { value: "violet-screen", label: "Violet Screen" },
  { value: "yellow", label: "Yellow" },
  { value: "yellow-screen", label: "Yellow Screen" },
];

class Generator extends React.Component {
  constructor(props) {
    super(props);
    this.presets = getPresetPatterns();
    let cachedSettings = localStorage.getItem(localStorageId);
    if (cachedSettings) {
      this.state = JSON.parse(cachedSettings);
      this.state.generatedCards = [];
    } else {
      this.state = {
        blackWhite: false,
        boldText: false,
        cardSpacing: "None",
        color: "blue",
        generatedCards: [],
        hideUnusedNumbers: false,
        numberOfCards: "6",
        orientation: "portrait",
        perPage: "6",
        printPattern: false,
        reverseShade: false,
        selectedPattern: null,
      };
    }
    window.addEventListener("beforeprint", this.printCards);
  }

  componentDidMount() {
    document.title = "Let's Play Bingo! | Bingo Card Generator";
    document.addEventListener("cardoptions", this.handleCardOptionUpdates);
    document.addEventListener("custompattern", this.handleCustomPatternUpdate);

    // Create a print sheet that we will use to update styles
    let style = document.createElement("style");
    style.id = "print-sheet";
    document.head.appendChild(style);
  }

  componentWillUnmount() {
    document.removeEventListener("cardoptions", this.handleCardOptionUpdates);
    document.removeEventListener("custompattern", this.handleCustomPatternUpdate);
  }

  handleCardOptionUpdates = (event) => {
    const property = event.detail.property;
    const value = event.detail.value;
    const stateObj = {};
    if (property === "numberOfCards") {
      stateObj[property] = parseInt(value);
    } else {
      stateObj[property] = value;
    }
    // If it's the printPattern setting and it's been turned off, we want to
    // reset hideUnusedNumbers to ensure the printing doesn't get ruined
    if (property === "printPattern" && value === false) {
      stateObj.hideUnusedNumbers = false;
    }

    this.setState(stateObj);

    // Prep state for going into localStorage
    let currentState = { ...this.state };
    Object.keys(stateObj).forEach((key) => {
      currentState[key] = stateObj[key];
    });
    delete currentState.generatedCards;
    localStorage.setItem(localStorageId, JSON.stringify(currentState));

    // If this is the perPage property, set breaks
    if (property === "perPage") {
      this.handleBreaks();
    }
  };

  handleButton = () => {
    let cards = [];
    for (let i = 1; i <= this.state.numberOfCards; i++) {
      cards.push(this.generateCard());
    }
    this.setState({ generatedCards: cards });
    this.handleBreaks();
  };

  handleBreaks = () => {
    this.removeBreaks();
    window.setTimeout(() => {
      const elements = document.querySelectorAll(".endOfRow");
      if (elements.length > 0) {
        elements.forEach((element) => {
          const breakDiv = document.createElement("div");
          breakDiv.classList.add("card-break");
          element.after(breakDiv);
        });
      }
    }, 100);
  };

  removeBreaks = () => {
    const endOfRows = document.querySelectorAll(".endOfRow");
    if (endOfRows.length > 0) {
      endOfRows.forEach((element) => {
        element.classList.remove("endOfRow");
      });
    }
    const breaks = document.querySelectorAll(".card-break");
    if (breaks.length > 0) {
      breaks.forEach((element) => {
        element.remove();
      });
    }
  };

  generateCard = () => {
    const columns = ["B", "I", "N", "G", "O"];
    let card = {};
    columns.map((letter, index) => {
      let chosenNumbers = [];
      let min = index === 0 ? 1 : index * 5 * 3 + 1;
      let max = (index + 1) * 3 * 5;
      for (let i = 0; i < 5; i++) {
        chosenNumbers.push(getRandomNumberInRange(min, max, chosenNumbers));
      }
      card[letter] = chosenNumbers;
      return letter;
    });
    return card;
  };

  handlePatternSelection = (patternName) => {
    this.presets.forEach((preset) => {
      if (preset.label === patternName) {
        this.setState({ selectedPattern: preset });
      }
    });
  };

  handleCustomPatternUpdate = (event) => {
    this.setState({ selectedPattern: event.detail });
  };

  printCards = () => {
    const printSheet = document.getElementById("print-sheet");
    printSheet.innerHTML = "";
    printSheet.appendChild(document.createTextNode(`@page { size: A4 ${this.state.orientation} !important; margin: ${this.margins} !important; }`));
  };

  get margins() {
    let vertical = "0cm";
    let horizontal = "0cm";
    // Portrait orientation
    if (this.state.orientation === "portrait") {
      switch (this.state.perPage) {
        case "2":
          vertical = this.state.cardSpacing === "Large" ? "0.5cm" : "1cm";
          horizontal = "1cm";
          break;
        case "4":
          vertical = this.state.cardSpacing === "Large" ? "2cm" : "3cm";
          horizontal = this.state.cardSpacing === "Large" ? "0cm" : "0.25cm";
          break;
        case "6":
          vertical = this.state.cardSpacing === "Large" ? "0cm" : "0.75cm";
          horizontal = this.state.cardSpacing === "Large" ? "0.5cm" : "0.5cm";
          break;
        default:
          vertical = "4cm";
          horizontal = "2cm";
          break;
      }
    } else {
      // Landscape orientation
      switch (this.state.perPage) {
        case "2":
          vertical = this.state.cardSpacing === "Large" ? "2cm" : "2.5cm";
          horizontal = this.state.cardSpacing === "Large" ? "0cm" : "0.5cm";
          break;
        case "4":
          if (this.state.cardSpacing === "None" || this.state.cardSpacing === "Small") {
            vertical = "1.25cm";
            horizontal = "3cm";
          } else if (this.state.cardSpacing === "Medium") {
            vertical = "1cm";
            horizontal = "3cm";
          } else {
            vertical = "0cm";
            horizontal = "2cm";
          }
          break;
        case "6":
          if (this.state.cardSpacing === "None") {
            vertical = "1.5cm";
            horizontal = "1cm";
          } else if (this.state.cardSpacing === "Small") {
            vertical = "1.25cm";
            horizontal = "1cm";
          } else if (this.state.cardSpacing === "Medium") {
            vertical = "0.75cm";
            horizontal = "1cm";
          } else {
            vertical = "0.5cm";
            horizontal = "0.5cm";
          }
          break;
        default:
          vertical = "1cm";
          horizontal = "3cm";
          break;
      }
    }
    return `${vertical} ${horizontal}`;
  }

  get sectionClasses() {
    let classes = "card-generator " + this.state.orientation;
    classes += this.state.blackWhite === "true" ? " print-bw " : " print-color ";
    if (this.state.perPage !== null) {
      switch (this.state.perPage.toString()) {
        case "1":
          classes += "print-one ";
          break;
        case "2":
          classes += "print-two ";
          break;
        case "6":
          classes += "print-six ";
          break;
        case "4":
        default:
          classes += "print-four ";
          break;
      }
    }
    return classes;
  }

  get generateButtonDisabled() {
    return this.state.numberOfCards === null || this.state.color === null;
  }

  render() {
    return (
      <section className={this.sectionClasses}>
        <div className="row container no-print">
          <div className="col full-width">
            <h1>
              Card Generator
              <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                <button aria-label="Back to Game" className="primary-button">
                  Back to Game
                </button>
              </NavLink>
            </h1>
          </div>
          <div className="col colspan8">
            <p className="margn-bottom-lg">Generate your own cards to print for playing at home! Use the customizations below to create your perfect game sheet! Every card is randomized down to each individual number, it is a near mathematical impossibility for any 2 cards generated to be alike.</p>
            <p className="small-text margn-bottom-xxlg">
              You can now print a total of up to 500 cards at a time and can manually type in how many cards you want. <em>Please note that this limit is in place due to performance issues. The more cards you choose to print at one time the longer the print preview will take to load.</em>
            </p>
          </div>
        </div>
        <div className="row container">
          {/* Generation Config */}
          <div className="col config shrink no-print">
            <Input eventName="cardoptions" label="Number of Cards" name="numberOfCards" value={this.state.numberOfCards} />

            <Select show={true} eventName="cardoptions" id="color" label="Card Colors" name="color" options={colorOptions} value={this.state.color} />

            <Switch show={true} defaultValue="6" eventName="cardoptions" id="perPage" label="Cards Per Page" name="perPage" options={["1", "2", "4", "6"]} value={this.state.perPage} />

            <Switch show={true} defaultValue="6" eventName="cardoptions" id="orientation" label="Page Orientation" name="orientation" options={["portrait", "landscape"]} value={this.state.orientation} />

            <Switch show={true} defaultValue="None" eventName="cardoptions" id="cardSpacing" label="Card Spacing" name="cardSpacing" options={["None", "Small", "Medium", "Large"]} value={this.state.cardSpacing} />

            <h4>Additional Options:</h4>

            <Toggle eventName="cardoptions" label="Bold text" name="boldText" value={this.state.boldText} />

            <Toggle eventName="cardoptions" label="Print in black & white" name="blackWhite" value={this.state.blackWhite} />

            <Toggle eventName="cardoptions" label="Show pattern on card" name="printPattern" value={this.state.printPattern} />

            <Toggle eventName="cardoptions" label="Reverse pattern shading" name="reverseShade" show={this.state.printPattern} info="When enabled, the spaces used in the pattern will be white and the unused numbers will be shaded." value={this.state.reverseShade} />

            <Toggle eventName="cardoptions" label="Hide unused numbers on card" name="hideUnusedNumbers" value={this.state.hideUnusedNumbers} disabled={!this.state.printPattern} />

            <div className="setting-inner">
              <Pattern rotate={false} editable={true} presets={this.presets} pattern={this.state.selectedPattern ? this.state.selectedPattern : this.presets[0]} showTitle={false} titlePosition="bottom" />
            </div>

            <Select show={true} id="patternSelect" label="Choose a Pattern" defaultValue="blank" name="pattern" options={this.presets} value={this.state.selectedPattern ? this.state.selectedPattern.value : this.presets[0].value} handler={this.handlePatternSelection} />

            <button className="primary-button" onClick={this.handleButton.bind(this)} disabled={this.generateButtonDisabled}>
              Generate Cards
            </button>
            <span className="margin-left-sm"></span>
            <button
              aria-label="Print Preview"
              className="secondary-button"
              disabled={this.state.generatedCards.length < 1}
              onClick={() => {
                window.print();
                return false;
              }}>
              Print Preview
            </button>
          </div>
          <div className="col grow">
            <div data-card-spacing={this.state.cardSpacing} className="row card-block justify-center margin-vertical-lg">
              <div className="col text-center">
                {this.state.generatedCards.map((card, index) => {
                  return (
                    <div data-bold={this.state.boldText} data-card-spacing={this.state.cardSpacing} data-reversed={this.state.printPattern ? this.state.reverseShade : false} data-color={this.state.blackWhite ? "dk-gray" : this.state.color} className={this.state.perPage === "1" || (this.state.perPage === "2" && (index - 1) % 2 === 0) || (this.state.perPage === "4" && (index + 1) % 2 === 0) ? "card endOfRow" : "card"} key={"a" + index}>
                      <BingoCard pattern={this.state.printPattern ? this.state.selectedPattern : null} hideUnusedNumbers={this.state.hideUnusedNumbers} card={card} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Generator;
