import React from "react";
import { NavLink } from "react-router-dom";
import { dispatchCustomEvent, getColorTextLogo } from "../helpers/Utilities";
import Menu from "./Menu";

/**
 * Header class
 * Renders the header of the site
 * Listens for the settings panel to be toggled and uses a state to determine if it is.
 */
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsPanelOpen: false,
      colorScheme: props.colorScheme,
    };
  }

  toggleSettingsPanel = () => {
    dispatchCustomEvent("togglesettingspanel", !this.state.settingsPanelOpen);
    this.setState({ settingsPanelOpen: !this.state.settingsPanelOpen });
  };

  toggleColorScheme = () => {
    dispatchCustomEvent("togglecolorscheme", this.state.colorScheme === "dark" ? "light" : "dark");
    this.setState({ colorScheme: this.state.colorScheme === "dark" ? "light" : "dark" });
  };

  render() {
    return (
      <header>
        <div className="row gutters-sm justify-spread no-wrap align-center">
          <div className="col no-shrink text-left">
            <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "show home-link")}>
              {getColorTextLogo()}
            </NavLink>
          </div>
          <div className="col shrink header-icon">
            <button aria-label="Light/Dark Mode" className="icon-button" title="Light/Dark Mode" onClick={this.toggleColorScheme}>
              <i class={this.state.colorScheme === "dark" ? "fa fa-solid fa-moon" : "fa fa-solid fa-sun"}></i>
            </button>
          </div>
          <div className="col shrink header-icon">
            <NavLink to="/" className={({ isActive }) => (isActive ? "show" : "hide")}>
              <button aria-label="Settings" className="icon-button" title="Settings" onClick={this.toggleSettingsPanel}>
                <i class="fa fa-solid fa-gears"></i>
              </button>
            </NavLink>
          </div>
          <div className="col shrink header-icon">
            <div className="translate-button primary-button icon-button">
              <i class="fa fa-solid fa-location-dot"></i>
              <div id="google_translate_element" title="Translation"></div>
            </div>
          </div>
          <div className="col shrink header-icon">
            <Menu />
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
