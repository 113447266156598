import React from "react";
import { getLogo } from "../helpers/Utilities";
import { Link } from "react-router-dom";
// Components -----------------------------------------------
import Countdown from "../components/Countdown";
import BingoBoard from "../components/BingoBoard";
import CallInfo from "../components/CallInfo";
import Controls from "../components/Controls";
import CurrentCall from "../components/CurrentCall";
import CurrentPattern from "../components/CurrentPattern";
import PreviousCalls from "../components/PreviousCalls";
import Footer from "../components/Footer";

const Classic = (props) => {
  if (props.screenSize === "small" || props.screenSize === "x-small") {
    return (
      <main className={props.screenSize + " " + props.layout}>
        <div className="row no-wrap align-start gutters-md">
          <div className="col shrink">
            <div className="home-link text-center margin-bottom-xlg">
              <Link to="/">{getLogo()}</Link>
            </div>
            <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
            <CurrentPattern
              disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
              eventName="patterndropdown"
              pattern={props.settings.pattern}
              rotatePatterns={props.rotatePatterns}
              showSelect={props.settings.showMainScreenPatternSelect}
              showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
              showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
            />
            <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
          </div>
          <div className="col grow">
            <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
            <div className="margin-lg"></div>
            <div className="row no-wrap justify-space-between gutters-sm">
              <div className={props.settings.showCurrentCall ? "col cmp-current-call" : "hide"}>
                <CurrentCall ball={props.currentCall} showTitle={true} />
              </div>
              <div className={props.settings.showCountdown ? "col cmp-countdown" : "hide"}>
                <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
              </div>
            </div>
          </div>
        </div>
        <div className="row margin-top-md">
          <div className={props.settings.showPreviousCalls ? "col full-width cmp-previous-calls" : "hide"}>
            <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="horizontal" settings={props.settings} />
          </div>
          <div className="col full-width">
            <Footer hideFooter={props.settings.hideFooter} />
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <main className={props.screenSize + " " + props.layout}>
        <div className="row no-wrap align-start gutters-md">
          <div className="col shrink">
            <div className="home-link text-center margin-bottom-xlg">
              <Link to="/">{getLogo()}</Link>
            </div>
            <CallInfo totalCalls={props.totalCalls} previousCall={props.previousCall} />
            <CurrentPattern
              disabled={props.settings.disableMainScreenPattern ? true : props.settings.disableMainScreenPatternWhileInPlay ? props.totalCalls > 0 : false}
              eventName="patterndropdown"
              pattern={props.settings.pattern}
              rotatePatterns={props.rotatePatterns}
              showSelect={props.settings.showMainScreenPatternSelect}
              showTitle={props.settings.pattern !== "blank" && props.settings.pattern.value !== "blank"}
              showSkipIndicator={props.settings.skipUnusedIndicator === true && props.settings.skipUnusedNumbers === true}
            />
            <Controls automatic={props.settings.automaticCalling} popout={props.settings.popOutControls} previousCall={props.previousCall} running={props.running} settings={props.settings} totalCalls={props.totalCalls} />
          </div>
          <div className="col grow">
            <BingoBoard board={props.board} manual={props.settings.manual} settings={props.settings} />
            <div className="margin-lg"></div>
            <div className="row gutters-md justify-space-between">
              <div className={props.settings.showCurrentCall ? "col cmp-current-call colspan2" : "hide"}>
                <CurrentCall ball={props.currentCall} showTitle={true} />
              </div>
              <div className={props.settings.showPreviousCalls ? "col cmp-previous-calls grow" : "hide"}>
                <PreviousCalls balls={props.called} numToShow={props.settings.previousCallsToShow} orientation="horizontal" settings={props.settings} />
              </div>
              <div className={props.settings.showCountdown ? "col cmp-countdown colspan2" : "hide"}>
                <Countdown call={props.currentCall?.number} delay={props.settings.delay} running={props.running} totalCalls={props.totalCalls} />
              </div>
            </div>
            <Footer hideFooter={props.settings.hideFooter} />
          </div>
        </div>
      </main>
    );
  }
};

export default Classic;
