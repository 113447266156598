export const zPatterns = [
  /* --------------------- Z -------------------- */
  {
    value: "Z and Ns are Free",
    label: "Z and Ns are Free",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, true],
      N: [false, false, false, false, false],
      G: [true, true, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Zero",
    label: "Zero",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Zig Zag Either Way",
    label: "Zig Zag Either Way",
    otherNames: [],
    pattern: { B: [true, true, true, false, false], I: [false, false, true, false, false], N: [false, false, true, false, false], G: [false, false, true, false, false], O: [false, false, true, true, true] },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      { B: [true, true, true, false, false], I: [false, false, true, false, false], N: [false, false, true, false, false], G: [false, false, true, false, false], O: [false, false, true, true, true] },
      { B: [false, false, true, true, true], I: [false, false, true, false, false], N: [false, false, true, false, false], G: [false, false, true, false, false], O: [true, true, true, false, false] },
    ],
  },
];
