import React from "react";
import { dispatchCustomEvent } from "../helpers/Utilities";
import HelperText from "./HelperText";

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : props.defaultValue,
    };
  }

  select = (event) => {
    this.setState({ value: event.target.value });
    let settingInfo = {};
    settingInfo.property = this.props.name;
    settingInfo.value = event.target.value;
    dispatchCustomEvent(this.props.eventName, settingInfo);
  };

  getHelperText = () => {
    return this.props.info ? (
      <HelperText
        text={this.props.info}
        position={props.infoPosition}
      />
    ) : null;
  };

  render() {
    return (
      <div className={this.props?.show ? "setting-inner" : "hide"}>
        <label>
          <span className="label-text no-text-wrap">{this.props.label}</span>
          {this.getHelperText()}
        </label>
        <div className="button-group no-text-wrap">
          {this.props.options.map((option) => {
            return (
              <button
                aria-label={option}
                key={option}
                className={option === this.state.value ? "selected" : ""}
                value={option}
                onClick={this.select}
                disabled={this.props.disabled}>
                {option}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Switch;
