export const sPatterns = [
  /* --------------------- S -------------------- */
  {
    value: "Sailboat",
    label: "Sailboat",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, false, true, true],
      N: [true, true, true, true, true],
      G: [false, true, false, true, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Sandwich",
    label: "Sandwich",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Santa Hat",
    label: "Santa Hat",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, true, false, true],
      N: [true, true, false, false, true],
      G: [true, true, true, true, true],
      O: [true, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Flo",
  },
  {
    value: "Satellite (Alternate)",
    label: "Satellite (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, false],
      I: [false, false, true, false, true],
      N: [false, true, false, true, false],
      G: [true, false, true, false, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Satellite",
    label: "Satellite",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [true, true, false, true, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Scale",
    label: "Scale",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [true, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Scarecrow",
    label: "Scarecrow",
    otherNames: ["Bonhomme"],
    pattern: {
      B: [false, true, false, false, true],
      I: [false, true, false, true, false],
      N: [true, true, true, false, false],
      G: [false, true, false, true, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: "Micghelle Langer",
  },
  {
    value: "Seat Down!",
    label: "Seat Down!",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, true, false],
      N: [false, true, false, true, false],
      G: [true, false, false, true, false],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Bob Versheck",
  },
  {
    value: "Seventeen",
    label: "Seventeen",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [false, false, false, false, false],
      N: [false, true, false, false, false],
      G: [false, true, false, false, false],
      O: [false, true, true, true, true],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Side By Side Anywhere",
    label: "Side By Side Anywhere",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Sides",
    label: "Sides",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: "Dhemie",
  },
  {
    value: "Skis",
    label: "Skis",
    otherNames: [],
    pattern: {
      B: [true, true, false, true, true],
      I: [false, true, false, false, true],
      N: [false, true, false, false, true],
      G: [false, true, false, false, true],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Slide",
    label: "Slide",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Sling Shot",
    label: "Sling Shot",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [false, true, false, false, false],
      G: [true, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Small Frame",
    label: "Small Frame",
    otherNames: ["Inside Border", "Inside Frame", "Around The Free", "Small Box"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [false, true, false, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Small Square (Anywhere)",
    label: "Small Square (Anywhere)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, true, false],
      G: [false, false, true, true, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Small Square (As Shown)",
    label: "Small Square (As Shown)",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "G", "O"],
    credit: null,
  },

  {
    value: "Small V",
    label: "Small V",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, true, false],
      N: [false, false, false, false, true],
      G: [false, false, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Smile",
    label: "Smile",
    otherNames: ["Happy Face"],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, true, false, false, true],
      N: [false, false, true, false, true],
      G: [false, true, false, false, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Smile (Alternate)",
    label: "Smile (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, true, false, false, true],
      N: [false, false, true, false, true],
      G: [false, true, false, false, true],
      O: [false, false, true, true, false],
    },
    unusedLetters: [],
    credit: "Lindsey J",
  },
  {
    value: "Snowflake",
    label: "Snowflake",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: "Jenny P",
  },
  {
    value: "Sombrero",
    label: "Sombrero",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, true, true, true, false],
      N: [false, true, false, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Mimi",
  },
  {
    value: "Spaceship",
    label: "Spaceship",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [false, true, true, true, false],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Spider",
    label: "Spider",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, true, true, false],
      N: [false, false, true, false, false],
      G: [false, true, true, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Spider Web",
    label: "Spider Web",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Spin The Top",
    label: "Spin The Top",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, false, true, true, false],
      N: [true, true, true, true, true],
      G: [true, false, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Spiral",
    label: "Spiral",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, true, true, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Bob Versheck",
  },
  {
    value: "Spring Basket",
    label: "Spring Basket",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, false, true, true],
      N: [false, true, false, true, true],
      G: [false, true, false, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Sputnik",
    label: "Sputnik",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, true, true, false],
      N: [false, true, true, true, false],
      G: [false, true, true, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Staircase",
    label: "Staircase",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, true],
      N: [false, false, true, true, true],
      G: [false, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Stamp And 1 Line",
    label: "Stamp And 1 Line",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["N", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [true, true, false, true, false],
        O: [true, true, false, true, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, true],
        O: [true, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, false, true, false],
        I: [true, true, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
    ],
  },
  {
    value: "Stamp and 4 Corners",
    label: "Stamp and 4 Corners",
    otherNames: ["Stamp and Four Corners"],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, true],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [true, false, false, true, true],
      },
      {
        B: [true, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Stamp and Line",
    label: "Stamp and Line",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, true],
        I: [true, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [true, true, false, true, false],
        O: [true, true, false, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, true, false, true, true],
        I: [false, true, false, true, true],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Star",
    label: "Star",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [false, true, true, true, false],
      N: [true, true, true, false, false],
      G: [false, true, true, true, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Starburst",
    label: "Starburst",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Station House 81",
    label: "Station House 81",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["G"],
    credit: "Colton Poling",
  },
  {
    value: "Steering Wheel",
    label: "Steering Wheel",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, true, false, true],
      N: [true, false, false, true, true],
      G: [true, false, true, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Straight",
    label: "Straight",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
    ],
  },
  {
    value: "Straight Angle",
    label: "Straight Angle",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["B"],
    credit: null,
  },
  {
    value: "Striiiiike",
    label: "Striiiiike",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["B", "N"],
    credit: null,
  },
  {
    value: "Stroller",
    label: "Stroller",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, false, true, true, true],
      N: [false, false, true, true, false],
      G: [true, true, true, true, true],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "SuperHuman",
    label: "SuperHuman",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Support Our Troops",
    label: "Support Our Troops",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, true, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Swatter and Fly",
    label: "Swatter and Fly",
    otherNames: ["Fly Swatter"],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, true, true],
      N: [true, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["G"],
    credit: null,
  },
];
