import React from "react";
import { dispatchCustomEvent } from "../helpers/Utilities";
import Fire from "./Fire";

/**
 * BingoBall
 * Represents a bingo ball on the bingo board
 *
 * @param   {Object}  props  Includes two properties
 *                    props.manual - if enabled you can click on the ball element
 *                    props.ball is a representation of a bingo ball, with .active, .called, and .number properties
 *
 * @return  {JSX}    Returns a visual representation of a bingo ball used
 *                   in the BingoBoardRow component
 */
export default function BingoBall(props) {
  let ballClasses = `bingo-board-ball notranslate`;
  if (props.ball.active) {
    ballClasses += " active";
  }
  if (props.ball.called && !props.ball.hide) {
    ballClasses += " called";
  }
  if (props.hotBall === true) {
    ballClasses += " hotball";
  }
  if (props.manual) {
    return (
      <div className={ballClasses}>
        <button
          aria-label={props.ball.number}
          className="bingo-board-ball-button"
          onClick={() => dispatchCustomEvent("mark-number", props.ball)}>
          <span>{props.ball.number}</span>
          {props.hotBall ? <Fire /> : null}
        </button>
      </div>
    );
  } else {
    return (
      <div className={ballClasses}>
        <span
          aria-label={props.ball.number}
          aria-disabled="true">
          {props.ball.number}
        </span>
        {props.hotBall ? <Fire /> : null}
      </div>
    );
  }
}
