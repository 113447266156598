export const iPatterns = [
  /* --------------------- I -------------------- */
  {
    value: "ING Game",
    label: "ING Game",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "In Or Out",
    label: "In Or Out",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
];
